import React, { useState } from 'react'
import { IonCard, IonIcon, IonItem, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react'
import GlobalHeaderStyles from '../../common/components/GlobalHeader/GlobalHeader.module.scss'
import GlobalHeader from '../../common/components/GlobalHeader'
import WeaverIonContent from '../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonHeader from '../../common/components/WeaverIonWrappers/WeaverIonHeader'

import WeaverIonPage from '../../common/components/WeaverIonWrappers/WeaverIonPage'
import Styles from "./ShowLeadPage.module.scss"
import { checkmarkCircle } from 'ionicons/icons'
import GoogleMapFromWeaverAddress from '../../common/components/GoogleMapFromWeaverAddress.tsx/GoogleMapFromWeaverAddress'
import { LeadErrorModal } from './LeadErrorModal'
import { useParamsFromPageConfig } from '../../routesProvider'
import { useAnalyticsEvent } from '../../api/providers/SegmentProvider/hooks'
import { useWeaverFlags } from '../../api/thirdParty/launchDarkly/useWeaverFlags'

type LeadErrorPageProps = {
  name : string,
}

const fakeAddress = {
  addressLine1: "Oxford St",
  addressLine2 : null,
  country:"United Kingdom",
  county: null,
  poBox: null,
  postCode: "W1D 1BS",
  postTown: "London",
}

export const LeadErrorPage: React.FC<LeadErrorPageProps> = ({ name }) => {
  const [ isErrorModalOpen, setIsErrorModalOpen ] = useState<boolean>(false)
  const { id } = useParamsFromPageConfig<{ id: string }>()
  const triggerLeadPlacesViewed = useAnalyticsEvent("Lead_Places_Full_Viewed")
  const { "MW-2604-copy-change-lead-to-tender": leadToTenderFlag } = useWeaverFlags()

  useIonViewWillEnter(async () => {
    setIsErrorModalOpen(true)
    await triggerLeadPlacesViewed({ projectId: id })
  })

  useIonViewWillLeave(() => {
    setIsErrorModalOpen(false)
  })

  return (
    <WeaverIonPage id={`ErrorBlockPage.${name}`} disableDirectChildStructureChecks={true}>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader pageTitle={`${leadToTenderFlag ? 'Tender' : 'Lead'} - Project`} />
      </WeaverIonHeader>
      <WeaverIonContent>
        <IonCard className={Styles.card}>
          <GoogleMapFromWeaverAddress address={fakeAddress} />
          <div className={`${Styles.projectFull} ion-text-center`}>
            PROJECT FULL
          </div>
          <IonItem className={`ion-margin-top`}>
            <div className={Styles.row}>
              <div>Project Type</div>
            </div>
            <div className={`${Styles.row} ${Styles.blurred}`}>
              Basement Conversion, Conservatory
            </div>
          </IonItem>

          <IonItem>
            <div className={Styles.row}>
              <div>Budget</div>
            </div>
            <div className={`${Styles.row} ${Styles.blurred}`}>
              £170K-200K
            </div>
          </IonItem>

          <IonItem>
            <div className={Styles.row}>
              <div>Work Start Estimate</div>
            </div>
            <div className={`${Styles.row} ${Styles.blurred}`}>
              January - February
            </div>
          </IonItem>

          <IonItem>
            <div className={Styles.row}>
              <div>Tender Return Date</div>
            </div>
            <div className={`${Styles.row} ${Styles.blurred}`}>
              19 March 2023
            </div>
          </IonItem>

          <IonItem>
            <div className={Styles.row}>
              <div>Project Led By</div>
            </div>
            <div className={`${Styles.row} ${Styles.blurred}`}>
              Architect, Homeowner
            </div>
          </IonItem>

          <IonItem>
            <div>Further Description</div>
          </IonItem>
          <div className={`${Styles.descriptionText}  ${Styles.blurred} ion-padding-start`}>
            Description
          </div>

          <IonItem>
            <div className={Styles.tenderReadinessTitle}>Tender Readiness</div>
          </IonItem>
          <IonItem className='ion-margin-bottom'>
            <div className={Styles.tenderReadinessText}>
              <div><IonIcon color="success" icon={checkmarkCircle} slot="start" /> Local authority permissions</div>
              <div><IonIcon color="success" icon={checkmarkCircle} slot="start" /> Detailed existing & proposed drawings</div>
              <div><IonIcon color="success" icon={checkmarkCircle} slot="start" /> Weaver tenderers limited to 3</div>
            </div>
          </IonItem>

        </IonCard>
        <LeadErrorModal projectId={id} isOpen={isErrorModalOpen} setIsOpen={setIsErrorModalOpen} />
      </WeaverIonContent>
    </WeaverIonPage>
  )
}
