import React from 'react'
import { IonItem, IonLabel, IonRefresher, IonRefresherContent, RefresherEventDetail } from '@ionic/react'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'
import GlobalHeader from '../../../common/components/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import { useListProjectTasksQuery, useShowProjectQuery } from '../../../graphql/generated'
import { useParamsFromPageConfig } from '../../../routesProvider'
import Styles from './Task.module.scss'
import Task from './Task'
import { filterMyProjectTasks } from '../../../common/utils/tasks'
import { pageConfig_TaskActioner, useRouteTo } from '../../../routes'
import { Duration } from 'luxon'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'

export const MyProjectTasksPage: React.FC = () => {
  const { projectId } = useParamsFromPageConfig<{projectId: string}>()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const listProjectTasksQuery = useListProjectTasksQuery(gqlDataSource, { id: projectId }, { refetchOnWindowFocus: false  })
  const showProject = useShowProjectQuery(gqlDataSource, { id: projectId }, { refetchOnWindowFocus: false, staleTime: Duration.fromObject({ seconds: 30 }).toMillis(), select: ({ getProject }) => {
    if (!getProject) throw new Error("Project not found")
    return { getProject }
  },
  })
  const myTeam = useMyIndividualActiveTeam()
  const { ["MW-2422-tasks-qol1-part3"]: tasksQol1Part3 } = useWeaverFlags()

  if (!myTeam) throw new Error("MyProjectTasksPage requires an active team")

  const routeToTaskActioner = useRouteTo(pageConfig_TaskActioner.path)

  const handleRefetchData = async () => {
    await showProject.refetch()
    await listProjectTasksQuery.refetch()
  }

  function handlePullRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      handleRefetchData()
      event.detail.complete()
    }, 2000)
  }

  if (listProjectTasksQuery.isLoading || showProject.isLoading) {
    return <LoadingSpinnerPage name="MyProjectTasksPage" />
  }

  if (listProjectTasksQuery.error || !listProjectTasksQuery.data || showProject.error || !showProject.data) {
    return <ErrorBlockPage name='MyTasksPage' onRefresh={handleRefetchData} />
  }

  const projectTasks = listProjectTasksQuery.data.listProjectTasks
  const projectPostCode = showProject.data.getProject.address?.postCode
  const projectTitle = showProject.data.getProject.title

  const projectSubtitle = tasksQol1Part3 ? projectTitle : projectPostCode

  const { pendingTasks, completedTasks } = filterMyProjectTasks(projectTasks, myTeam)

  return (
    <WeaverIonPage id='MyTasksPage'>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader pageTitle={`My Tasks`} pageSubtitle={projectSubtitle}/>
      </WeaverIonHeader>
      <WeaverIonContent>
        <IonRefresher className={Styles.ionRefresher} slot="fixed" onIonRefresh={handlePullRefresh}>
          <IonRefresherContent refreshingSpinner={'crescent'}></IonRefresherContent>
        </IonRefresher>
        {pendingTasks.length > 0 &&
          <section className='ion-margin-top'>
            {pendingTasks.map((task, ix) => (
              // pending tasks are already ordered, so we can assume that the first one in the list is the next task
              <div key={task.id}>
                <Task task={task} isNextTask={ix === 0} onAction={(actioned) => routeToTaskActioner({ taskId: actioned.id })()}  />
              </div>
            ))}
          </section>
        }

        {completedTasks.length > 0 &&
          <section>
            <IonItem className={`ion-no-padding ion-margin-top ${Styles.rowIonItem}`} color='none' lines='none' onClick={handleRefetchData}>
              <IonLabel className={Styles.ionLabel}>Completed</IonLabel>
            </IonItem>
            {completedTasks.map((task) => (
              <div key={task.id}>
                <Task task={task}
                  onAction={(actioned) => routeToTaskActioner({ taskId: actioned.id })()}
                  ionItemProps={{
                    lines: 'none',
                    color: 'none',
                  }} />
              </div>
            ))}
          </section>
        }

      </WeaverIonContent>
    </WeaverIonPage>
  )
}
