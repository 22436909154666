import { useEnvironmentContext } from "../../api/providers/EnvironmentProvider"
import { useMyIndividualActiveTeam } from "../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { alwaysArray, ensureTrailingSlash } from "../../common/utils"
import { flexibleBudgetTextToSignificantFigures } from "../../common/utils/budgetTransformation"
import { makeMoneyFlexible, MoneyRange, moneyToText } from "../../common/utils/currency"
import { getEnumValueFromString } from "../../common/utils/enum"
import { BudgetCategory, GetContractorProfileQuery, Money, ProjectMemberRole, ReferralType, ShowLeadQuery, ShowProjectQuery, TeamType, UploadedFileStatus } from "../../graphql/generated"
import { getBudgetRangesByMoneyRange } from "../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges"

/** returns a function for generating the right invite url, dependent on the environment context */
export const useGetInviteUrl = () => {
  const env = useEnvironmentContext()
  return (inviteId: string) => `https://${ensureTrailingSlash(env.app.domain)}?claimInviteId=${encodeURIComponent(inviteId)}`
}

/** We'll take either anything that represents the `budgetValue` / `budgetCategory` of the underlying `Project` */
type ShowProjectOrLead = Pick<NonNullable<ShowLeadQuery['getLead']> | NonNullable<ShowProjectQuery['getProject']>, 'budgetValue' | 'budgetCategory' >
type MyTeam = ReturnType<typeof useMyIndividualActiveTeam>

// Get the enum value for the BudgetCategory
const flexPercentages: Record<BudgetCategory, number> = {
  Exact: 0,       // 0%
  RoughIdea: 10,  // 10%
  NoIdea: 20,     // 20%
}

export const flexPercentages_v1: Record<BudgetCategory, number> = {
  Exact: 15,       // 15%
  RoughIdea: 30,  // 30%
  NoIdea: 50,     // 50%
}

export const getFlexibleProjectBudgetAsMoneyRange = (projectOrLead: ShowProjectOrLead, expandProjectCreationFlowFlag: boolean, adjustFlexBudgetFlag = false): MoneyRange => {
  const budgetCategory = getEnumValueFromString(BudgetCategory, projectOrLead.budgetCategory)
  if (!budgetCategory) throw new Error(`Unknown BudgetCategory: ${projectOrLead.budgetCategory}`)

  const flexPercentage = expandProjectCreationFlowFlag ? flexPercentages_v1[budgetCategory] : flexPercentages[budgetCategory]

  // Show the appropriate level of accuracy
  return makeMoneyFlexible(projectOrLead.budgetValue, flexPercentage, adjustFlexBudgetFlag)
}

export const getFlexibleProjectBudgetAsMoneyOrMoneyRange = (projectOrLead: ShowProjectOrLead, team: MyTeam, expandProjectCreationFlowFlag: boolean, adjustFlexBudgetFlag = false): Money | MoneyRange => {
  // These teams always see the exact budget
  if (team !== undefined && [ TeamType.Architect, TeamType.Homeowner, TeamType.Weaver ].includes(team.type)) {
    return projectOrLead.budgetValue
  }

  return getFlexibleProjectBudgetAsMoneyRange(projectOrLead, expandProjectCreationFlowFlag, adjustFlexBudgetFlag)
}

export const getFlexibleProjectBudgetAsText = (projectOrLead: ShowProjectOrLead, team: MyTeam, expandProjectCreationFlowFlag: boolean, adjustFlexBudgetFlag = false): string | null | undefined => {
  const moneyOrMoneyRange = getFlexibleProjectBudgetAsMoneyOrMoneyRange(projectOrLead, team, expandProjectCreationFlowFlag, adjustFlexBudgetFlag)
  if ('currency' in moneyOrMoneyRange) {
    return moneyToText(moneyOrMoneyRange)
  } else {
    const { rangeBottom, rangeTop } = moneyOrMoneyRange
    if (rangeBottom === rangeTop) {
      return `up to ${moneyToText(rangeTop)}`
    } else {
      return `${moneyToText(rangeBottom)} - ${moneyToText(rangeTop)}`
    }
  }
}

/** Specifically when viewing a project, rather than a lead, we might need to display metadata on budget types
 *
 * e.g. uncomfirmed or cost estimates
 * This can't be used for leads as they dont provide the right meta (yet)
 */
export const getProjectBudgetDescription = (project: Pick<NonNullable<ShowProjectQuery['getProject']>, "isBudgetCostEstimate" | "isBudgetConfirmed" | 'budgetValue' | 'budgetCategory' >, team: MyTeam, variant: "summary" | undefined = undefined, expandProjectCreationFlowFlag: boolean, adjustFlexBudgetFlag = false) => {
  if (project.isBudgetCostEstimate) return "Awaiting Cost Estimate"
  // HACK: if the budget is less than £10, we can assume it's a magic value. This is a hack to avoid showing Exact for these projects
  if (Number(project.budgetValue.amountInPence) < 1000) return "Budget Not Confirmed"
  const budgetText = variant === "summary"
    ? flexibleBudgetTextToSignificantFigures(getFlexibleProjectBudgetAsMoneyOrMoneyRange(project, team, expandProjectCreationFlowFlag, adjustFlexBudgetFlag))
    : getFlexibleProjectBudgetAsText(project, team, expandProjectCreationFlowFlag, adjustFlexBudgetFlag)
  if (project.isBudgetConfirmed === false) return `${budgetText} (Not Confirmed)`
  return budgetText
}

type ContractorProfileSubscriptions = Pick<NonNullable<GetContractorProfileQuery['getContractorProfile']>, 'budgetRangeSubscriptions'>
type PayableLead = Pick<NonNullable<ShowLeadQuery['getLead']>, 'referral' | 'budgetValue' | 'budgetCategory'>

/**
 * Should a contractor pay for this lead?
 *
 * Taking into account the flexibility of the lead budget, determine if any amount within that range
 * falls within any of the budget ranges for the contractor's subscriptions.
 *
 * **This considers both ends of the subscription budget range as inclusive.**
 * (i.e. 30-100k budget range will match an 100k exact lead)
 *
 * The maths cover the flexible lead budget overlapping multiple subscriptions,
 * as well as a subscription falling completely within the flexible lead budget extremities.
 *
 * The latter is not currently possible with a maximum 20% variance, but is possible if that was to become more than 70% in future.
 *
 * @param payableLead The lead which tells us has the contractor been invited
 * @param contractorProfile The profile which tells us what the contractor has subscribed to
 * @returns boolean true if the contractor should pay for the lead (or subscribe)
 */
export const shouldPayForLead = (payableLead: PayableLead, contractorProfile: ContractorProfileSubscriptions, expandProjectCreationFlowFlag = false, adjustFlexBudgetFlag = false) => {
  // Is the contractor invited?
  const isProjectOwnerReferral = payableLead.referral?.type === ReferralType.ProjectOwner
  if (isProjectOwnerReferral) return false

  // Fetch the MoneyRange and workout the overlapping BudgetRanges
  const leadMoneyRange = getFlexibleProjectBudgetAsMoneyRange(payableLead, expandProjectCreationFlowFlag, adjustFlexBudgetFlag)
  const leadMoneyRangeBudgetRanges = getBudgetRangesByMoneyRange(leadMoneyRange)

  // Grab the BudgetRanges the contractor is subscribed to
  const subscribedBudgetRanges = alwaysArray(contractorProfile.budgetRangeSubscriptions).map(({ budgetRange }) => budgetRange)

  // Do any of the leadMoneyRangeBudgetRanges match the subscribedBudgetRanges
  const hasMatchingSubscription = alwaysArray(leadMoneyRangeBudgetRanges).some(budgetRange => subscribedBudgetRanges.includes(budgetRange))
  return !hasMatchingSubscription
}

type Project = NonNullable<ShowProjectQuery["getProject"]>
type ProjectMember = NonNullable<Project["members"]>[number]
/**
 * From myTeam Id and a list of members on a project return whether I am the owner of the project
 *
 * @param myTeam
 * @param members
 * @returns boolean
 */
export const isCurrentUserProjectOwner = (myTeam: MyTeam, members: ProjectMember[] | undefined) => {
  if (!myTeam || !members) return false
  return members?.find(member => member.team.id === myTeam?.id)?.projectRole === ProjectMemberRole.Owner
}

export const getDocumentAndDocumentPreviewData = (lead: NonNullable<ShowLeadQuery['getLead']>) => {
  const documentPreviewCount = lead?.documentPreviews?.filter((doc) => doc && doc.status !== UploadedFileStatus.Archived).length ?? 0
  const activeDocuments = lead?.documents?.filter(d => d.status !== UploadedFileStatus.Archived) ?? []
  const allDocumentTags = activeDocuments?.filter(d => d.kind !== null).map(d => d.kind).flat() ?? []

  return {
    documentPreviewCount,
    activeDocuments,
    allDocumentTags,
  }
}
