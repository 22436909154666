import { zodResolver } from "@hookform/resolvers/zod"
import { IonContent, IonNote } from "@ionic/react"
import React from "react"
import { useForm, Controller } from "react-hook-form"
import { z } from "zod"
import { requireSomething } from "../../../../common/utils"
import { ScreenStartMatchingContext } from "./common"
import SingleClickButton from "../../../../common/components/SingleClickButton"
import { Finish } from "../../../../graphql/generated"
import GenericSelectorList from "../../../../common/components/GenericSelectorList"
import { getGenericSelectorOptionsForEnum } from "../../../../common/components/GenericSelectorList/helpers"
import { finishDescriptions, finishLabels } from "../../../projects/projectTaskLabels.i18n"

import Styles from '../TaskActionerPage.module.scss'

const FormSchema = z.object({
  finish: z.nativeEnum(Finish),
})

type FormData = z.infer<typeof FormSchema>

const EditFinishModal: React.FC = () => {
  const { project, updateProject, cancel } = requireSomething( React.useContext(ScreenStartMatchingContext) )
  const { handleSubmit, control } = useForm<FormData>({ resolver: zodResolver(FormSchema), defaultValues: { finish: project.finish ?? undefined } })

  const onSubmit = async (data: FormData) => {
    await updateProject.mutateAsync({ input: {
      id: project.id,
      finish: data.finish,
    },
    })
  }

  return <>
    <IonContent className="ion-padding">
      <p>Finishes in construction are crucial for determining the cost as they significantly impact the overall aesthetics, quality, and functionality of a project.</p>
      <Controller
        control={control}
        name="finish"
        render={({
          field: { onChange, value },
          fieldState: { error },
        }) => (<div>
          <GenericSelectorList
            options={getGenericSelectorOptionsForEnum(Finish, finishLabels)}
            selected={value}
            onSelect={(value) => onChange(value)}
            showItemDetail={false}
            colSize="12"
            render={({ value }) => <React.Fragment key={value}>
              <h3 className={Styles.finishesItemHeader} id={value}>{finishLabels[value]}</h3>
              <p  className={Styles.finishesItemDescription}>{finishDescriptions[value]}</p>
            </React.Fragment>}
          />
          {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
        </div>
        )} />
      <SingleClickButton className={Styles.descriptionEditModalButton} expand="block" onClick={handleSubmit(onSubmit)}>Confirm</SingleClickButton>
    </IonContent>
  </>
}

export default EditFinishModal
