import React from "react"
import LoadingSpinnerContent from "../../../common/components/LoadingSpinner/LoadingSpinnerContent"
import WeaverIonContent from "../../../common/components/WeaverIonWrappers/WeaverIonContent"
import { ScreenComponentProps } from "./TaskActionerPage"
import { IonButton, IonFooter, IonItem, IonNote } from "@ionic/react"
import GlobalHeader from "../../../common/components/GlobalHeader"
import WeaverIonHeader from "../../../common/components/WeaverIonWrappers/WeaverIonHeader"
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import Styles from '../TaskActionerPage/TaskActionerPage.module.scss'
import { useMarkTaskCompleteMutation } from "./commonActions"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { Controller, FieldErrors, useForm } from "react-hook-form"
import { zMoney } from "../../../graphql/zod"
import { BudgetCategory, Currency, useUpdatePublishedProjectBudgetMutation } from "../../../graphql/generated"
import { asMoney, moneyAmountAsNumber } from "../../../common/utils/currency"
import { MINIMUM_PROJECT_VALUE_IN_PENCE } from "../../../common/utils"
import GenericSelectorList from "../../../common/components/GenericSelectorList"
import { getGenericSelectorOptionsForEnum } from "../../../common/components/GenericSelectorList/helpers"
import { budgetCategoryLabelsV2 } from "../../projects/CreateProjectPage/budgetCategory.i18n"
import WeaverMoneyIonInput from "../../../common/components/WeaverMoneyIonInput"
import { useGraphQLDataSource } from "../../../api/graphql"

const zFormSchema = z.object({
  budgetCategory: z.nativeEnum(BudgetCategory),
  budgetAmount: zMoney.superRefine((val, ctx) => {
    const budgetAmountAsNumber = moneyAmountAsNumber(val)
    if (budgetAmountAsNumber == null) return // We only error on budget amounts once set
    if (budgetAmountAsNumber < MINIMUM_PROJECT_VALUE_IN_PENCE) {
      ctx.addIssue({
        message: "Our contractors can't at the moment serve projects below £30,000",
        code: z.ZodIssueCode.custom,
      })
    }
  }),
})

type FormData = z.infer<typeof zFormSchema>

const ConfirmProjectBudget: React.FC<ScreenComponentProps> = ({ getActionableTaskQuery }) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const setTaskAsCompleted = useMarkTaskCompleteMutation(getActionableTaskQuery)
  const updateProjectBudgetMutation = useUpdatePublishedProjectBudgetMutation(gqlDataSource, {
    onSuccess: async () => {
      console.debug('[ScreenConfirmProjectBudget]: Successfully updated project budget')
      await setTaskAsCompleted()
    },
  })

  const { handleSubmit, formState: { errors }, control } = useForm<FormData>({ resolver: zodResolver(zFormSchema) })

  if (!getActionableTaskQuery.data) return <LoadingSpinnerContent name="ConfirmProjectBudgetRange" />
  const task = getActionableTaskQuery.data.getTask

  const onSubmit = (data: FormData) => {
    updateProjectBudgetMutation.mutate({
      projectId: task.projectId,
      budgetValue: {
        amountInPence: data.budgetAmount.amountInPence,
        currency: data.budgetAmount.currency,
      },
      budgetCategory: data.budgetCategory,
      markBudgetAsConfirmed: true,
    })
  }

  const onError = (errors: FieldErrors) => {
    console.debug("[ScreenConfirmProjectBudget]:", errors)
  }

  return <>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader pageTitle={task.actionTitle ?? task.title}/>
    </WeaverIonHeader>
    <WeaverIonContent>
      <div className={Styles.budegetRangeContainer}>
        <h2>What&apos;s your budget?</h2>
        <p className="ion-padding-bottom">The budget is used to match you to the right type of contractor, who has experience working on projects like yours.</p>
        <h6>Budget Value (GBP, ex. VAT)</h6>
        <Controller
          control={control}
          name="budgetAmount"
          render={({
            field: { onChange, value },
            fieldState: { error },
          }) => <>
            <IonItem className={Styles.ionItemInput} lines="none">
              <WeaverMoneyIonInput
                value={value}
                setValue={onChange}
                withPennies={false}
                minValue={asMoney(value?.currency ?? Currency.Gbp, MINIMUM_PROJECT_VALUE_IN_PENCE)}
              />
            </IonItem>
            {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
          </>
          }
        />
      </div>
      <h2>How flexible is your budget?</h2>
      <Controller
        control={control}
        name="budgetCategory"
        render={({
          field: { onChange, value },
          fieldState: { error },
        }) => (<>
          <GenericSelectorList
            options={getGenericSelectorOptionsForEnum(BudgetCategory, budgetCategoryLabelsV2)}
            selected={value}
            onSelect={value => onChange(value)}
          />
          {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
        </>
        )}
      />
    </WeaverIonContent>
    <IonFooter className='ion-padding-horizontal ion-no-border'>
      <IonButton className={Styles.confirmBudgetButton} expand="block" onClick={handleSubmit(onSubmit, onError)}>Confirm</IonButton>
    </IonFooter>
  </>
}

export default ConfirmProjectBudget
