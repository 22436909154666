import { ProgressionStatus } from "../../../../graphql/generated"

export const progressionStatusBadgeLabel: Record<ProgressionStatus, string> = {
  [ ProgressionStatus.NotStarted ]: 'To Do',
  [ ProgressionStatus.WaitingOnUser ]: 'Next',
  [ ProgressionStatus.WaitingOnWeaver]: 'Waiting',
  [ ProgressionStatus.Completed ]: 'Done',
}

export const progressionStatusSubtextLabel : Partial<Record<ProgressionStatus, string>> = {
  [ProgressionStatus.WaitingOnWeaver]: "Reference vetting in progress",
  [ProgressionStatus.Completed]: "You will receive leads in this range",
}

export const progressionStatusSubtextLabel_v1 : Partial<Record<ProgressionStatus, string>> = {
  [ProgressionStatus.WaitingOnWeaver]: "Reference vetting in progress",
  [ProgressionStatus.Completed]: "You will receive tenders in this range",
}
