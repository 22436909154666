import { UseMutationResult } from "@tanstack/react-query"
import { ShowProjectQuery, UpdatePublishedProjectBudgetMutation, UpdatePublishedProjectMutationVariables } from "../../../../graphql/generated"
import React from "react"

type ScreenStartMatchingContextValue = {
  project: NonNullable<ShowProjectQuery["getProject"]>,
  updateProject: UseMutationResult<UpdatePublishedProjectBudgetMutation, unknown, UpdatePublishedProjectMutationVariables, unknown>,
  cancel: () => void | unknown,
}

export const ScreenStartMatchingContext = React.createContext<ScreenStartMatchingContextValue|undefined>(undefined)
