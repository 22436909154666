import { zodResolver } from "@hookform/resolvers/zod"
import { IonContent, IonNote } from "@ionic/react"
import React from "react"
import { useForm, Controller } from "react-hook-form"
import { z } from "zod"
import { requireSomething } from "../../../../common/utils"
import { ScreenStartMatchingContext } from "./common"
import SingleClickButton from "../../../../common/components/SingleClickButton"
import { Specialty } from "../../../../graphql/generated"
import GenericSelectorList from "../../../../common/components/GenericSelectorList"
import { getGenericSelectorOptionsForEnum } from "../../../../common/components/GenericSelectorList/helpers"
import { specialtiesLabels } from "../../../projects/projectTaskLabels.i18n"

import Styles from '../TaskActionerPage.module.scss'

const FormSchema = z.object({
  specialties: z.array(z.nativeEnum(Specialty)).min(1),
})

type FormData = z.infer<typeof FormSchema>

const EditSpecialitiesModal: React.FC = () => {
  const { project, updateProject, cancel } = requireSomething( React.useContext(ScreenStartMatchingContext) )
  const { handleSubmit, control } = useForm<FormData>({ resolver: zodResolver(FormSchema), defaultValues: {
    specialties: project.specialties ?? [],
  } })

  const onSubmit = async (data: FormData) => {
    await updateProject.mutateAsync({ input: {
      id: project.id,
      specialties: data.specialties,
    },
    })
  }

  return <>
    <IonContent className="ion-padding">
      <p>Select all that apply. Optional</p>
      <Controller
        control={control}
        name="specialties"
        render={({
          field: { onChange, value },
          fieldState: { error },
        }) => (<div>
          <GenericSelectorList
            options={getGenericSelectorOptionsForEnum(Specialty, specialtiesLabels)}
            selected={value || []}
            onSelect={(value) => onChange(value)}
            showItemDetail={false}
            multiSelect={true}
            colSize="6"
            render={({ value }) => <p><div key={value} id={value}>{specialtiesLabels[value]}</div></p>}
          />
          {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
        </div>
        )} />
      <SingleClickButton className={Styles.descriptionEditModalButton} expand="block" onClick={handleSubmit(onSubmit)}>Confirm</SingleClickButton>
    </IonContent>
  </>
}

export default EditSpecialitiesModal
