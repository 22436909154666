import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonSearchbar,
  IonSpinner,
  IonText,
} from "@ionic/react"
import React, { useCallback, useMemo, useState } from "react"
import { useGraphQLDataSource } from "../../../../api/graphql"
import { CompanySummary, useGetPossibleCompanyNumbersForPartialCompanyNameQuery } from "../../../../graphql/generated"
import { radioButtonOn } from "ionicons/icons"
import classnames from "classnames"
import { useAnalyticsEvent } from "../../../../api/providers/SegmentProvider/hooks"
import { useEnvironmentContext } from "../../../../api/providers/EnvironmentProvider"
import { alwaysArray } from "../../../../common/utils"
import DevAccountOnly from "../../../../common/components/DevAccountOnly"
import Styles from "./AutoSearchCompany.module.scss"
import { getIconBySicCode, getSicCodeFromCompanySummary, getWeaverAddressFromCompanySummary } from "../../../../common/utils/companiesHouse"
import { formatAddressToSingleLine } from "../../../../common/utils/addresses"
import { showIntercom } from "../../../../common/utils/intercom"
import { useWeaverFlags } from "../../../../api/thirdParty/launchDarkly/useWeaverFlags"

type AutoSearchCompanyProps = {
  selectedCompany?: CompanySummary,
  companyNameSelected: (company?: CompanySummary) => void,
  hasError: boolean,
  clearErrors: () => void,
}

const DEBOUNCE_TIME = 350

const AutoSearchCompany: React.FC<AutoSearchCompanyProps> = ({ selectedCompany, companyNameSelected, hasError, clearErrors }) => {
  const env = useEnvironmentContext()
  const [ selected, setSelected ] = useState<CompanySummary | undefined>(() => {
    console.debug('[AutoSearchCompany] Initialising to the selected company: ', { selectedCompany })
    return selectedCompany
  })

  const weaverFlags = useWeaverFlags()

  const [ searchPartial, setSearchPartial ] = React.useState("")
  const searchPartialIsCompanyNumber = /^[0-9]+$/.test(searchPartial)

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const apiRefreshCount = React.useRef(0)
  const triggerEvent = useAnalyticsEvent('Onboarding_Company_Selected')

  const { data, isLoading } = useGetPossibleCompanyNumbersForPartialCompanyNameQuery(
    gqlDataSource,
    { partialCompanyName: searchPartial },
    {
      enabled: searchPartial.length > 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  )

  const companies = alwaysArray(data?.getPossibleCompanyNumbersForPartialCompanyName.results)

  const handleSearchUpdate = useCallback(async ({ value: search }) => {
    console.debug(`[AutoSearchCompany.handleSearchUpdate] Handed search term: `, { search, selected, selectedCompany })
    setSelected(undefined)
    companyNameSelected(undefined)
    clearErrors()
    if (search === undefined || search.length < 4) return
    apiRefreshCount.current++

    console.debug(`[AutoSearchCompany.handleSearchUpdate] Setting search partial: `, { search })
    setSearchPartial(search)
  }, [ setSearchPartial ])

  const handleSelect = (company: CompanySummary | undefined) => {
    console.debug(`[AutoSearchCompany.handleSelect] Setting company to: `, { company })
    setSelected(company)
    companyNameSelected(company)
    clearErrors()
    triggerEvent({
      numberOfResponsesOfApi: apiRefreshCount.current,
    })
  }

  // In the case of the API being unhealthy and us being in a dev account, these companies will be shown
  const generatedCompany: CompanySummary = useMemo(() => ({
    companyNumber: `${Math.floor( 10000000 + (Math.random() * 90000000) )}`, // This can be any 8 digit number, as a string
    companyName: "Default Company",
    regAddress_AddressLine1: "32 OAKLANDS GROVE",
    regAddress_AddressLine2: "",
    regAddress_PostTown: "LONDON",
    regAddress_County: "",
    regAddress_Country: "ENGLAND",
    regAddress_PostCode: "W12 0JA",
  }), [])
  console.debug(`[AutoSearchCompany] Render`, { companies, selected, selectedCompany })

  const showLoadingSpinner = isLoading && searchPartial

  return <>
    <IonLabel className={Styles.searchLabel}>Company Name *</IonLabel>
    <IonSearchbar
      className={Styles.searchbar}
      onIonChange={e => handleSearchUpdate(e.detail ?? "")}
      onIonClear={() => {
        companyNameSelected(undefined)
        setSearchPartial("")
      }}
      debounce={DEBOUNCE_TIME}
    >
    </IonSearchbar>

    {hasError && <p className={Styles.errorMessage}>You must select a company.</p>}
    {showLoadingSpinner && (<div className={Styles.searchSpinnerContainer}>
      <IonSpinner className={Styles.searchSpinner} name="crescent" />
    </div>)}
    <DevAccountOnly>
      <IonButton onClick={() => {
        handleSelect(selected === undefined ? generatedCompany : undefined)
      }}>
        Generate a random company
        {selected === generatedCompany && <> (selected)</>}
      </IonButton>
    </DevAccountOnly>
    {data?.getPossibleCompanyNumbersForPartialCompanyName?.apiHealthy === false
      ? env.account.type === 'dev'
        ? <p>The API is currently unhealthy.</p>
        : <p>We are currently experiencing technical difficulties. Please contact support.</p>
      : companies.length
        ? (
          <div className={Styles.listContainer}>
            {companies.map((company) => (
              <div className={Styles.ionItemContainer} key={company.companyNumber}>
                <IonItem
                  data-testid="AutoSearchCompany.CompanyItem"
                  button detail={false}
                  lines='none'
                  className={classnames({
                    [Styles.childItem]: true,
                    [Styles.selected]: selected?.companyName.includes(company?.companyName),
                    [Styles.inactiveStatus]: company?.companyStatus !== 'Active',
                  })}
                  onClick={() => handleSelect(company)}>
                  <div className={Styles.itemDetails}>
                    <IonLabel>
                      <header className={Styles.companyHeader}>
                        <div className={Styles.companyIconTitle}>
                          <IonIcon icon={getIconBySicCode(getSicCodeFromCompanySummary(company))} />
                          <h4>{company?.companyName}</h4>
                        </div>
                        {company?.companyStatus === 'Active' && <IonIcon className={Styles.activeStatus} icon={radioButtonOn} />}
                      </header>
                    </IonLabel>
                    <IonLabel>
                      <p> Company number: {company?.companyNumber}</p>
                      <p> Address: {formatAddressToSingleLine(getWeaverAddressFromCompanySummary(company))}</p>
                      <p> Incorporated: {company?.incorporationDate}</p>
                    </IonLabel>
                  </div>
                </IonItem>
              </div>
            ))}
          </div>
        )
        : isLoading || searchPartial.length < 3
          ? null // Don't show anything while loading or don't have enough of a search string
          : !searchPartialIsCompanyNumber
            ? ( // We've found no results, so perhaps they should try their company number
              <div className={Styles.noDataFoundContainer}>
                <IonButton onClick={showIntercom}>
                  <IonLabel>
                    <IonText color='danger'>Oops! No results found. Try searching for your company number.</IonText>
                    <a>Need help? Chat to support</a>
                  </IonLabel>
                </IonButton>
              </div>
            )
            : ( // We've found no results, and they're already using a potential company number
              <div className={Styles.noDataFoundContainer}>
                <IonButton onClick={showIntercom}>
                  <IonLabel>
                    <IonText color='danger'>Oops! No results found.</IonText>
                    <a>Need help? Chat to support</a>
                  </IonLabel>
                </IonButton>
              </div>
            )
    }
  </>
}

export default AutoSearchCompany
