import React from 'react'
import PublicProfileHeader from '../PublicProfileHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import PublicArchitectProfile from './PublicArchitectProfile'
import { TeamPublicProfilePageComponentProps } from '../DisplayPublicProfile'
import GlobalHeader from '../../../common/components/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { IonBackButton } from '@ionic/react'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'

import Styles from './PublicArchitectProfile.module.scss'

const PublicArchitectProfilePage: React.FC<TeamPublicProfilePageComponentProps> = ({ teamId, ...ionPageProps }) => {

  const weaverFlags = useWeaverFlags()

  return (<WeaverIonPage id='PublicArchitectProfilePage' {...ionPageProps}>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      {weaverFlags['tab-nav-bar'].enabled
        ? <GlobalHeader
          navElement={<IonBackButton className={GlobalHeaderStyles.backButton} />}
          pageTitle='Public Profile'
        />
        : <PublicProfileHeader />}
    </WeaverIonHeader>
    <WeaverIonContent className={Styles.displayArchitectProfileContainer}>
      <PublicArchitectProfile id={teamId} />
    </WeaverIonContent>
  </WeaverIonPage>)
}

export default PublicArchitectProfilePage
