import { IonButton, IonPopover } from '@ionic/react'
import { Duration } from 'luxon'
import React, { ComponentProps, Dispatch, MouseEventHandler, SetStateAction } from 'react'
import { useGraphQLDataSource } from '../../api/graphql'
import Styles from "./NextTaskButton.module.scss"

import { TaskActionableType, useListProjectTasksQuery } from '../../graphql/generated'
import { pageConfig_TaskActioner, useRouteTo } from '../../routes'
import Task from './checklist/Task'
import { useNextTaskSelector } from '../../common/utils/tasks'
import { useAnalyticsEvent } from '../../api/providers/SegmentProvider/hooks'
import { ToastState } from '../../api/providers/AchievementToastProvider/AchievementToastProvider'

type NextTaskButtonProps = {
  projectId: string,
  /** variant=`detailed` displays additional information, like the icon, in a larger display. `simple` is just an ionButton
   *
   * `detailed` does not use an IonButton and so ionbutton props are ignored
   */
  variant?: "detailed" | "simple",
  isPartOfToast?: boolean,
  setToast?: Dispatch<SetStateAction<ToastState | undefined>>,
  popoverDirection?: ComponentProps<typeof IonPopover>["side"] ,
} & ComponentProps<typeof IonButton>

const defaultIonButtonProps: ComponentProps<typeof IonButton> = {
  expand: "block",
}

const PROJECT_TASKS_STALETIME = Duration.fromObject({ minutes: 5 }).as('milliseconds')

const NextTaskButton: React.FC<NextTaskButtonProps> = ({ projectId, variant = "simple", setToast, isPartOfToast = false, popoverDirection = "bottom" , ...rawButtonProps }) => {
  const buttonProps = {
    ...defaultIonButtonProps,
    ...rawButtonProps,
  }

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const nextStepSelector = useNextTaskSelector()
  const confirmProjectBudgetTaskActionClicked = useAnalyticsEvent('Confirm_Project_Budget_Task_Clicked')

  const nextPendingTaskQuery = useListProjectTasksQuery(gqlDataSource, { id: projectId }, {
    refetchOnWindowFocus: false,
    staleTime: PROJECT_TASKS_STALETIME,
    select: (data) => nextStepSelector(data.listProjectTasks),
  })
  const nextTask = nextPendingTaskQuery.data
  const routeToTaskActioner = useRouteTo(pageConfig_TaskActioner.path)
  if (nextPendingTaskQuery.isLoading || !nextTask) return null

  const handleClick: MouseEventHandler<unknown> = async (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (nextTask.actionableType === TaskActionableType.ConfirmProjectBudget) {
      await confirmProjectBudgetTaskActionClicked({
        projectId: nextTask.projectId,
        taskId: nextTask.id,
        taskTitle: nextTask.title,
        taskStatus: nextTask.status,
        taskActionableType: nextTask.actionableType,
        taskDueAt: nextTask?.dueAt,
        taskActionablePayload: nextTask?.actionablePayload,
      })
    }

    routeToTaskActioner({ taskId: nextTask.id })()
  }
  if (variant === "detailed") {
    return <div className={`${Styles.detailedContainer} ${isPartOfToast ? Styles.removeBorder : undefined}`}>
      <Task task={nextTask} isPartOfToast={isPartOfToast} setToast={setToast} isNextTaskButton={true} popoverDirection={popoverDirection} onAction={({ event }) => handleClick(event)} />
    </div>
  }

  return <IonButton {...buttonProps} onClick={handleClick}>
    <span className={Styles.ellipsisSupport}>{nextTask.title}</span>
  </IonButton>

}

export default NextTaskButton
