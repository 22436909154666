import { zodResolver } from "@hookform/resolvers/zod"
import { IonNote, IonItem, IonContent } from "@ionic/react"
import React from "react"
import { useForm, Controller } from "react-hook-form"
import { z } from "zod"
import GenericSelectorList from "../../../../common/components/GenericSelectorList"
import { getGenericSelectorOptionsForEnum } from "../../../../common/components/GenericSelectorList/helpers"
import { MAXIMUM_PROJECT_VALUE_IN_PENCE, MINIMUM_PROJECT_VALUE_IN_PENCE, requireSomething } from "../../../../common/utils"
import { ScreenStartMatchingContext } from "./common"
import { BudgetCategory, Currency } from "../../../../graphql/generated"
import WeaverMoneyIonInput from "../../../../common/components/WeaverMoneyIonInput"
import { asMoney, moneyAmountAsNumber } from "../../../../common/utils/currency"
import { zMoney } from "../../../../graphql/zod"
import { budgetCategoryLabelsV2 } from "../../../projects/CreateProjectPage/budgetCategory.i18n"
import SingleClickButton from "../../../../common/components/SingleClickButton"

import Styles from '../TaskActionerPage.module.scss'

const zFormSchema = z.object({
  budgetCategory: z.nativeEnum(BudgetCategory),
  budgetAmount: zMoney.superRefine((val, ctx) => {
    const budgetAmountAsNumber = moneyAmountAsNumber(val)
    if (budgetAmountAsNumber == null) return // We only error on budget amounts once set
    if (budgetAmountAsNumber < MINIMUM_PROJECT_VALUE_IN_PENCE) {
      ctx.addIssue({
        message: "Our contractors can't at the moment serve projects below £30,000",
        code: z.ZodIssueCode.custom,
      })
    }
    if (budgetAmountAsNumber > MAXIMUM_PROJECT_VALUE_IN_PENCE) {
      ctx.addIssue({
        message: "Our contractors can't at the moment serve projects above £3,000,000",
        code: z.ZodIssueCode.custom,
      })
    }
  }),
})

type FormData = z.infer<typeof zFormSchema>

const EditBudgetModal: React.FC = () => {
  const { project, updateProject, cancel } = requireSomething( React.useContext(ScreenStartMatchingContext) )

  const { handleSubmit, formState: { errors }, control } = useForm<FormData>({ resolver: zodResolver(zFormSchema), defaultValues: {
    // HACK: all of our BE is using a string, and not the Budget Category enum.
    // Unfortunately, this is the safest route without potentially crashing a bunch of stuff in our different apps.
    budgetCategory: BudgetCategory[project.budgetCategory as keyof typeof BudgetCategory],
    budgetAmount: project.budgetValue,
  },
  })

  const onSubmit = async (data: FormData) => {
    await updateProject.mutateAsync({ input: {
      id: project.id,
      budgetCategory: data.budgetCategory,
      budgetValue: data.budgetAmount,
      isBudgetConfirmed: true,
    } })
  }

  return <>
    <IonContent className="ion-padding">
      <div className={Styles.budegetRangeContainer}>
        <p className="ion-padding-bottom">The budget is used to match you to the right type of contractor, who has experience working on projects like yours.</p>
        <h6>Budget Value (GBP, ex. VAT)</h6>
        <Controller
          control={control}
          name="budgetAmount"
          render={({
            field: { onChange, value },
            fieldState: { error },
          }) => <>
            <IonItem className={Styles.ionItemInput} lines="none">
              <WeaverMoneyIonInput
                value={value}
                setValue={onChange}
                withPennies={false}
                minValue={asMoney(value?.currency ?? Currency.Gbp, MINIMUM_PROJECT_VALUE_IN_PENCE)}
              />
            </IonItem>
            {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
          </>
          }
        />
      </div>
      <h2>How flexible is your budget?</h2>
      <Controller
        control={control}
        name="budgetCategory"
        render={({
          field: { onChange, value },
          fieldState: { error },
        }) => (<>
          <GenericSelectorList
            options={getGenericSelectorOptionsForEnum(BudgetCategory, budgetCategoryLabelsV2)}
            selected={value}
            onSelect={value => onChange(value)}
          />
          {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
        </>
        )}
      />
      <SingleClickButton className={Styles.confirmBudgetButton} expand="block" onClick={handleSubmit(onSubmit)}>Confirm</SingleClickButton>
    </IonContent>
  </>
}

export default EditBudgetModal
