import React from 'react'
import { IonButton, IonIcon, IonText, IonToolbar } from '@ionic/react'
import { arrowBackOutline, arrowForwardOutline } from 'ionicons/icons'
import WeaverIonContent from '../../../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonFooter from '../../../../../common/components/WeaverIonWrappers/WeaverIonFooter'
import WeaverIonPage from '../../../../../common/components/WeaverIonWrappers/WeaverIonPage'
import { alwaysArray } from '../../../../../common/utils'
import { BudgetRange } from '../../../../../graphql/generated'
import { pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange, useRouteTo } from '../../../../../routes'
import { useParamsFromPageConfig } from '../../../../../routesProvider'
import IndicatorBar from '../../../../projects/CreateProjectPage/IndicatorBar'
import RangeBadge from '../../ContractorBudgetReferences/RangeBadge'
import { budgetRangeLabels } from '../budgetRanges.i18n'
import { getWorkHistoryById, useMyContractorProfile } from '../datasource'
import ScreenWorkHistoryMenu from './ScreenWorkHistoryMenu'
import { WorkHistoryMenuItem } from './ScreenWorkHistoryMenu/config'
import { getAllBadgeEligibleHistories } from './ContractorProfileBudgetRangeBadge/hooks'
import { getInprogressBadgeLevel } from './BadgeUtils'
import { useWeaverFlags } from '../../../../../api/thirdParty/launchDarkly/useWeaverFlags'

import Styles from "./UnlockContractorBudgetRange.module.scss"

export const UnlockContractorBudgetRangeMenuPage = () => {

  const { budgetRange, workHistoryId } = useParamsFromPageConfig<{ budgetRange: BudgetRange, workHistoryId: string }>()
  const myContractorProfile = useMyContractorProfile()
  const { "MW-2604-copy-change-lead-to-tender": leadToTenderFlag } = useWeaverFlags()

  const goToUnlockContractorBudgetRange = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange.path)

  const workHistory = getWorkHistoryById(myContractorProfile, workHistoryId)

  const doesWorkHistoryHaveAnyReferences = alwaysArray(workHistory?.references).length > 0
  const doesWorkHistoryHaveAnyPhotos = alwaysArray(workHistory?.photos).length > 0
  const isButtonEnabled = doesWorkHistoryHaveAnyPhotos && doesWorkHistoryHaveAnyReferences

  const currentProgressIndex = [ doesWorkHistoryHaveAnyReferences, doesWorkHistoryHaveAnyPhotos ].filter(Boolean).length
  const totalPages = Object.keys(WorkHistoryMenuItem).length

  const eligibleWorkHistories = getAllBadgeEligibleHistories(myContractorProfile, budgetRange)
  const badgeLevel = getInprogressBadgeLevel(eligibleWorkHistories.length)

  const handleClickBack = () => {
    goToUnlockContractorBudgetRange({ budgetRange }, undefined, 'back')()
  }

  const handleNextClick = () => {
    goToUnlockContractorBudgetRange({ budgetRange })()
  }

  return (
    <WeaverIonPage id='UnlockContractorBudgetRangeMenu'>
      <WeaverIonContent>
        <div className={`${Styles.pastProjectHeaderContainer} ion-text-center}`}>
          { budgetRange && <RangeBadge budgetRange={budgetRange} badgeLevel={badgeLevel} />}
          <h5>Unlock {budgetRangeLabels[budgetRange]} {leadToTenderFlag ? 'tenders' : 'leads'}</h5>
        </div>
        <div className={Styles.indicatorContainer}>
          <IndicatorBar currentPageIndex={currentProgressIndex} totalPages={totalPages} maxAvailableIndex={totalPages} onNavigate={() => null} />
        </div>
        <div className="ion-padding">
          <IonText>To unlock {budgetRangeLabels[budgetRange]} {leadToTenderFlag ? 'tenders' : 'leads'}, you need to have all the tasks marked as done</IonText>
          <ScreenWorkHistoryMenu budgetRange={budgetRange} workHistory={workHistory} />
        </div>
      </WeaverIonContent>
      <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
        <IonToolbar className={Styles.ionToolbar}>
          <IonButton color="secondary" slot="start" onClick={handleClickBack}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
          <IonButton disabled={!isButtonEnabled} color="primary" slot="end" onClick={handleNextClick}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
        </IonToolbar>
      </WeaverIonFooter>
    </WeaverIonPage>
  )
}
