import React, { ComponentProps } from "react"
import { IonBadge, IonIcon } from "@ionic/react"
import { warning } from "ionicons/icons"
import Styles from "./WeaverUnverifiedBadge.module.scss"
import { useWeaverFlags } from "../../../api/thirdParty/launchDarkly/useWeaverFlags"

type WeaverBadgePros = ComponentProps<typeof IonBadge>

const WeaverUnverifiedBadge: React.FC<WeaverBadgePros> = ({ ...ionBadgeProps }) => {
  const { ["MW-2646-remove-unverified-label"]: unverifiedLabel } = useWeaverFlags()
  return unverifiedLabel
    ? null
    : <IonBadge color='danger' {...ionBadgeProps}><IonIcon className={Styles.ionIcon} icon={warning} /> Unverified</IonBadge>
}

export default WeaverUnverifiedBadge
