import { zodResolver } from "@hookform/resolvers/zod"
import { IonContent, IonNote } from "@ionic/react"
import React from "react"
import { useForm, FieldErrors, Controller } from "react-hook-form"
import { z } from "zod"
import { requireSomething } from "../../../../common/utils"
import { ScreenStartMatchingContext } from "./common"
import SingleClickButton from "../../../../common/components/SingleClickButton"
import { PropertyType } from "../../../../graphql/generated"
import GenericSelectorList from "../../../../common/components/GenericSelectorList"
import { getGenericSelectorOptionsForEnum } from "../../../../common/components/GenericSelectorList/helpers"
import { propertyTypeLabels } from "../../../projects/commonProjectLabels.i18n"

import Styles from '../TaskActionerPage.module.scss'

const FormSchema = z.object({
  propertyType: z.nativeEnum(PropertyType),
})

type FormData = z.infer<typeof FormSchema>

const EditPropertyTypeModal: React.FC = () => {
  const { project, updateProject, cancel } = requireSomething( React.useContext(ScreenStartMatchingContext) )
  const { handleSubmit, control } = useForm<FormData>({ resolver: zodResolver(FormSchema), defaultValues: {
    propertyType: project.propertyType ?? undefined,
  } })

  const onSubmit = async (data: FormData) => {
    await updateProject.mutateAsync({ input: {
      id: project.id,
      propertyType: data.propertyType,
    },
    })
  }

  const onError = (errors: FieldErrors) => {
    console.log(errors)
  }

  return <>
    <IonContent className="ion-padding">
      <p>What type of property is this?</p>
      <Controller
        control={control}
        name="propertyType"
        render={({
          field: { onChange, value },
          fieldState: { error },
        }) => (<div className={Styles.propertyList}>
          <GenericSelectorList
            options={getGenericSelectorOptionsForEnum(PropertyType, propertyTypeLabels)}
            selected={value || []}
            multiSelect={false}
            onSelect={(value) => onChange(value)}
            showItemDetail={false}
            colSize={'12'}
            render={({ value }) => <p>{propertyTypeLabels[value]}</p>}
          />

          {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
        </div>
        )}
      />
      <SingleClickButton className={Styles.descriptionEditModalButton} expand="block" onClick={handleSubmit(onSubmit, onError)}>Confirm</SingleClickButton>
    </IonContent>
  </>
}

export default EditPropertyTypeModal
