export const TENDER_AND_PROJECT_PROPERTIES = {
  PROPERTY: 'Property',
  PROJECT_TYPE: 'Project type',
  SPECIALTY: 'Specialty',
  CLIENT_ESTIMATED_BUDGET: 'Client estimated budget',
  FINISHES: 'Finishes',
  WORK_START_ESTIMATE: 'Work start estimate',
  CONTACT_PERSON: 'Contact person',
  FURTHER_DESCRIPTION: 'Further description',
  AVAILABLE_DOCUMENTS: 'Available documents',
}
