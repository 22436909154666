import { IonItem, IonText } from '@ionic/react'
import React from 'react'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useGetHomeownerProfileQuery } from '../../../graphql/generated'
import TeamMembers from '../TeamMembers'
import Styles from '../Profile.module.scss'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import ErrorBlock from '../../../common/components/ErrorBlock'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'

type PublicHomeownerProfileProps = {
  id: string,
}

const PublicHomeownerProfile: React.FC<PublicHomeownerProfileProps> = ({ id }) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data, error, isFetching, refetch } = useGetHomeownerProfileQuery(gqlDataSource, { homeownerTeamId: id })
  console.debug(`[PublicHomeownerProfile] Render: `, { id, data })

  if (isFetching) {
    return <LoadingSpinner name="PublicHomeownerProfile" />
  }

  if (error) {
    console.error(`[PublicHomeownerProfile] Failed to load Homeowner Profile: `, { id, data, error })
    return <ErrorBlock name='PublicHomeownerProfile.failed' onRefresh={refetch} />
  }

  if (data?.getHomeownerProfile == null) {
    console.warn(`[PublicHomeownerProfile] Homeowner Profile is missing: `, { id, data, error })
    return <ErrorBlock name='PublicHomeownerProfile.missing'><p>Unable to find the Homeowner Profile.</p></ErrorBlock>
  }

  const profile = data.getHomeownerProfile

  return <>
    <IonItem className={`${Styles.ionItem} ion-no-padding`} lines='none'>
      <h3>{profile.teamName}</h3>
    </IonItem>
    <IonText className={Styles.lightGrey}>
      <p>Homeowner</p>
    </IonText>
    <TeamMembers teamId={id} />
  </>
}

export default PublicHomeownerProfile
