import React from 'react'
import { IonBackButton, useIonRouter } from '@ionic/react'
import TeamMembers from '../TeamMembers/TeamMembers'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import { PublicContractorProfileScreenProps, PublicContractorScreenNames, RenderAs } from './PublicContractorProfile'
import { ScreenRenderProps } from '../../../common/hooks/useScreens'
import GlobalHeader, { NavElement } from '../../../common/components/GlobalHeader/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { PublicContractorProfileDetailsCard } from './PublicContractorProfileDetailsCard'
import { PublicWorkHistoryTimeline } from './PublicWorkHistoryTimeline'
import { BadgeLevel, ContractorProfileBudgetRangeBadge, Team, TeamType } from '../../../graphql/generated'
import RangeBadge from '../ContractorLeadPreferences/ContractorBudgetReferences/RangeBadge'
import { useOpenBadgeInfoModal } from '../ContractorLeadPreferences/ContractorBudgetReferences/useOpenBadgeInfoModal'
import { alwaysArray } from '../../../common/utils'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { matchPath } from 'react-router'
import { pageConfig_ChatRoom, pageConfig_ChatRoomMembers, pageConfig_ShowProject } from '../../../routes'

import Styles from './PublicContractorProfilePage.module.scss'

const ScreenHome: React.FC<PublicContractorProfileScreenProps & ScreenRenderProps<PublicContractorScreenNames>> = (props) => {
  const weaverFlags = useWeaverFlags()
  const myTeam = useMyIndividualActiveTeam()
  const ionicRouter = useIonRouter()

  const team: Pick<Team, 'id' | 'type'> = {
    id: props.profile.id,
    type: TeamType.Contractor,
  }

  const { openBadgeInfoModal, displayBadgeInfoModal } = useOpenBadgeInfoModal(true, team)

  const shouldApplyStyle = weaverFlags['tab-nav-bar'].enabled

  const publicProfileBadgeViewedEvent = useAnalyticsEvent('Project_PublicProfile_Badge_Viewed')
  const publicProfileWorkHistoryViewedEvent = useAnalyticsEvent('Project_PublicProfile_WorkHistory_Viewed')
  const chatRoomPublicProfileBagdeViewedEvent = useAnalyticsEvent('Chat_Menu_PublicProfile_Badge_Viewed')
  const chatRoompublicProfileWorkHistoryViewedEvent = useAnalyticsEvent('Chat_Menu_PublicProfile_WorkHistory_Viewed')
  const trigger_Chat_Intro_PublicProfile_WorkHistory_Viewed = useAnalyticsEvent('Chat_Intro_PublicProfile_WorkHistory_Viewed')

  const handleBadgeClick = async (budgetRangeBadge: Partial<ContractorProfileBudgetRangeBadge> ) => {
    if (!budgetRangeBadge.budgetRange ) return console.error('[PublicContractorProfile]: budgetRangeBadge.budgetRange is undefined')

    await handlePublicProfileBadgeViewed()
    openBadgeInfoModal(budgetRangeBadge.badgeLevel ?? BadgeLevel.Bronze, budgetRangeBadge.budgetRange, false)
  }

  const getPrevUrlParamsData = () => {
    const prevPath = ionicRouter.routeInfo.lastPathname

    if (!prevPath) return console.debug('[PublicContractorProfile]: prevPath is undefined')

    const lastPageWasProject = matchPath<{id: string}>(prevPath, { path: pageConfig_ShowProject.path } )
    const lastPageWasChatRoom = matchPath<{chatRoomId: string}>(prevPath, { path: pageConfig_ChatRoomMembers.path })
    const lastPageWasChat = matchPath<{chatRoomId: string}>(prevPath, { path: pageConfig_ChatRoom.path } )

    if (lastPageWasChat) {
      return { "referrer": lastPageWasChat.path, "id": lastPageWasChat.params?.chatRoomId }
    }

    if (lastPageWasProject) {
      return { "referrer": lastPageWasProject.path, "id": lastPageWasProject.params?.id }
    }

    if (lastPageWasChatRoom) {
      return { "referrer": lastPageWasChatRoom.path, "id": lastPageWasChatRoom.params?.chatRoomId }
    }

    return undefined
  }

  const handlePublicProfileBadgeViewed = async () => {
    const parseUrl = getPrevUrlParamsData()
    if (!parseUrl) return console.debug('[PublicContractorProfile]: could not parse URL')

    parseUrl.referrer === pageConfig_ShowProject.path && await publicProfileBadgeViewedEvent({
      projectId: parseUrl.id ?? '',
      viewedTeamId: props.profile.id,
      teamName: props.profile.companyRegisteredName,
      teamType: TeamType.Contractor,
      viewedByTeamType: myTeam?.type,
      viewedByTeamId: myTeam?.id,
    })

    parseUrl.referrer === pageConfig_ChatRoomMembers.path  && await chatRoomPublicProfileBagdeViewedEvent({
      chatRoomId: parseUrl.id ?? '',
      viewedTeamId: props.profile.id,
      teamName: props.profile.companyRegisteredName,
      teamType: TeamType.Contractor,
      viewedByTeamType: myTeam?.type,
      viewedByTeamId: myTeam?.id,
    })
  }

  const handlePublicProfileWorkHistoryViewed = async (workHistoryId: string) => {
    const parseUrl = getPrevUrlParamsData()

    if (!parseUrl) return console.debug('[PublicContractorProfile]: could not parse URL')

    parseUrl.referrer === pageConfig_ShowProject.path && await publicProfileWorkHistoryViewedEvent({
      workHistoryId: workHistoryId,
      projectId: parseUrl.id ?? '',
      viewedTeamId: props.profile.id,
      teamName: props.profile.companyRegisteredName,
      teamType: TeamType.Contractor,
      viewedByTeamType: myTeam?.type,
      viewedByTeamId: myTeam?.id,
    })

    parseUrl.referrer === pageConfig_ChatRoom.path && await trigger_Chat_Intro_PublicProfile_WorkHistory_Viewed({
      workHistoryId: workHistoryId,
      viewedTeamId: props.profile.id,
      viewedByTeamType: myTeam?.type,
      viewedByTeamId: myTeam?.id,
      projectId: parseUrl.id ?? '',
      teamType: TeamType.Contractor,
      teamName: props.profile.companyRegisteredName,
      chatRoomId: parseUrl.id,
    })

    parseUrl.referrer === pageConfig_ChatRoomMembers.path && await chatRoompublicProfileWorkHistoryViewedEvent({
      chatRoomId: parseUrl.id ?? '',
      workHistoryId: workHistoryId,
      viewedTeamId: props.profile.id,
      teamName: props.profile.companyRegisteredName,
      teamType: TeamType.Contractor,
      viewedByTeamType: myTeam?.type,
      viewedByTeamId: myTeam?.id,
    })
  }

  const onlyAwardedBadgeLevels = alwaysArray(props?.profile?.budgetRangeBadges).filter((item) => item?.badgeLevel !== null)

  return <>
    <WeaverIonHeader className={shouldApplyStyle ? GlobalHeaderStyles.globalHeader : undefined}>
      <GlobalHeader
        navElement={props.renderProps.as === RenderAs.Page ? <IonBackButton className={Styles.backButton} /> : NavElement.None}
        pageTitle='Public Profile'
      />
    </WeaverIonHeader>
    <WeaverIonContent className={Styles.displayContractorProfileContainer}>

      <>
        <PublicContractorProfileDetailsCard contractorProfile={props.profile} />
        <section className={`${Styles.tieredBadgeRangeContainer} ion-padding-horizontal`}>
          {onlyAwardedBadgeLevels.length > 0 && (
            <>
              <h5 className={Styles.badgeTitle}>Badges</h5>
              {onlyAwardedBadgeLevels?.map((budgetRangeBadge, index) => (
                <RangeBadge key={index} badgeLevel={budgetRangeBadge.badgeLevel ?? BadgeLevel.Bronze} budgetRange={budgetRangeBadge.budgetRange} width="90" height="90"
                  onClick={() => handleBadgeClick(budgetRangeBadge)}  />))}
            </>
          )}
        </section>
      </>

      <section className={Styles.listWorkHistoryContainer}>
        <h3 className={Styles.pastProjectsTitle}>Past projects</h3>
        {!props.profile.workHistory && <p className="ion-padding" >No work history has been added</p>}
        <PublicWorkHistoryTimeline handlePublicProfileWorkHistoryClick={handlePublicProfileWorkHistoryViewed} {...props}  />
      </section>

      <section className={Styles.teamMemberListContainer}>
        <TeamMembers teamId={props.profile.id} />
      </section>

      {displayBadgeInfoModal()}
    </WeaverIonContent>
  </>
}

export default ScreenHome
