import React from 'react'
import { IonInput, IonItem, IonLabel, IonGrid, IonRow } from '@ionic/react'
import { UseFormRegister, FieldErrors } from "react-hook-form"
import { CompanySummary } from '../../../../graphql/generated'
import Styles from "./CompanyDetailsEdit.module.scss"
import { useWeaverFlags } from '../../../../api/thirdParty/launchDarkly/useWeaverFlags'

type FormValues = {
  companyTradingAs: string,
  companyName: string,
}

interface CompanyDetailsProps {
  company?: CompanySummary,
  errors: FieldErrors<FormValues>,
  register: Partial<Record<keyof FormValues, ReturnType<UseFormRegister<FormValues>>>>,
  isSoleTrader?: boolean,
}

const CompanyDetailsEdit: React.FC<CompanyDetailsProps> = ({ company, register, errors, isSoleTrader = false }) => {
  const weaverFlags = useWeaverFlags()

  return (
    <IonGrid className={Styles.contentContainer}>
      <IonRow style={{ marginBottom: '2rem' }}>
        <h3>Add a trading name</h3>
      </IonRow>
      <IonRow className={Styles.item}>
        <div className={Styles.itemContainer}>
          <IonItem lines={isSoleTrader ? 'none' : undefined} className={isSoleTrader ? Styles.ionItemSoleTraderBackground : undefined}>
            <IonLabel className={Styles.label} position="stacked">{isSoleTrader ? 'Registered name' : 'Registered Company Name*'}</IonLabel>
            {weaverFlags['MW-2661-architect-sole-trader-onboarding'] && isSoleTrader
              ? <div className={Styles.soleTraderInputContainer}><IonInput className={Styles.editCompanyInput} disabled={!isSoleTrader} {...register.companyName} /></div>
              : <IonInput disabled value={company?.companyName} />
            }
          </IonItem>
          {!isSoleTrader && <p className={Styles.subtext}>{`Company number: ${company?.companyNumber}`}</p>}
        </div>
      </IonRow>
      <IonRow className={Styles.item}>
        <IonItem lines={isSoleTrader ? 'none' : undefined} className={isSoleTrader ? Styles.ionItemSoleTraderBackground : undefined}>
          <IonLabel className={Styles.label} position="stacked">Trading As*</IonLabel>
          {weaverFlags['MW-2661-architect-sole-trader-onboarding'] && isSoleTrader
            ? <div className={Styles.soleTraderInputContainer}><IonInput className={Styles.editCompanyInput} disabled={!isSoleTrader} {...register.companyTradingAs} /></div>
            : <IonInput  {...register.companyTradingAs} />
          }
          <p className={Styles.errorMessage}>{errors.companyTradingAs?.message}</p>
          {isSoleTrader && <p className={Styles.soleTraderSubtitleTradingAs}>This is the name clients will see on Weaver (You can change this later)</p>}
        </IonItem>
      </IonRow>
    </IonGrid>
  )
}

export default CompanyDetailsEdit
