import { IonAvatar } from "@ionic/react"
import React from "react"
import { getInitials, getSeededColor } from "../../utils"

import Styles from "./MemberAvatar.module.scss"

const MemberAvatar: React.FC<{title: string | undefined | null, slot: string, onClick?: () => void}> = ({ title, slot, onClick }) => {
  const inputTitle = title || ""
  return (
    <IonAvatar slot={slot} className={Styles.memberAvatar} style={{ backgroundColor: getSeededColor(inputTitle) }} onClick={onClick}>
      <div className={Styles.label}><div>{getInitials(inputTitle).slice(0, 2)}</div></div>
    </IonAvatar>
  )
}

export default MemberAvatar
