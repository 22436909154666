import React from 'react'
import { Controller, FieldErrors, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Finish, useUpdatePublishedProjectMutation } from '../../../graphql/generated'
import { z } from 'zod'
import GenericSelectorList from '../../../common/components/GenericSelectorList'
import { getGenericSelectorOptionsForEnum } from '../../../common/components/GenericSelectorList/helpers'
import { IonButton, IonFooter, IonNote } from '@ionic/react'
import SingleClickButton from '../../../common/components/SingleClickButton'
import { finishDescriptions, finishLabels } from '../../projects/projectTaskLabels.i18n'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import GlobalHeader from '../../../common/components/GlobalHeader'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useMarkTaskCompleteMutation, useMarkTaskSkippedMutation } from './commonActions'
import { ScreenComponentProps } from './TaskActionerPage'
import LoadingSpinnerContent from '../../../common/components/LoadingSpinner/LoadingSpinnerContent'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import Styles from "./TaskActionerPage.module.scss"

const FormSchema = z.object({
  finish: z.nativeEnum(Finish),
})

type FormData = z.infer<typeof FormSchema>

const ScreenFinishes: React.FC <ScreenComponentProps> = ({ getActionableTaskQuery }) => {

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const setTaskAsCompleted = useMarkTaskCompleteMutation(getActionableTaskQuery)
  const updatePublishedProject = useUpdatePublishedProjectMutation(gqlDataSource, {
    onSuccess: async () => await setTaskAsCompleted(),
  })

  const { handleSubmit, control } = useForm<FormData>({ resolver: zodResolver(FormSchema) })

  const onSubmit = async(data: FormData) => {
    if (!getActionableTaskQuery.data?.getTask.projectId) return console.error('[ScreenFinishes] No projectId found')

    await updatePublishedProject.mutateAsync({
      input: {
        id: getActionableTaskQuery.data?.getTask.projectId,
        finish: data.finish,
      },
    })
  }

  const onError = (error: FieldErrors) => {
    console.log(error)
  }

  if (!getActionableTaskQuery.data) return <LoadingSpinnerContent name="ProjectFinishesTask" />
  const task = getActionableTaskQuery.data.getTask

  return  <>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader pageTitle={task.actionTitle ?? task.title}/>
    </WeaverIonHeader>
    <WeaverIonContent>
      <h2>What level of finish is expected?</h2>
      <p>Finishes in construction are crucial for determining the cost as they significantly impact the overall aesthetics, quality, and functionality of a project.</p>
      <Controller
        control={control}
        name="finish"
        render={({
          field: { onChange, value },
          fieldState: { error },
        }) => (<div>
          <GenericSelectorList
            options={getGenericSelectorOptionsForEnum(Finish, finishLabels)}
            selected={value}
            onSelect={(value) => onChange(value)}
            showItemDetail={false}
            colSize="12"
            render={({ value }) => <React.Fragment key={value}>
              <h3 className={Styles.finishesItemHeader} id={value}>{finishLabels[value]}</h3>
              <p  className={Styles.finishesItemDescription}>{finishDescriptions[value]}</p>
            </React.Fragment>}
          />
          {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
        </div>
        )} />
    </WeaverIonContent>
    <IonFooter className='ion-padding-horizontal ion-no-border ion-margin-bottom'>
      <SingleClickButton  expand="block" onClick={handleSubmit(onSubmit, onError)}>Confirm</SingleClickButton>
      <IonButton expand="block" fill="clear" onClick={useMarkTaskSkippedMutation(getActionableTaskQuery)}>Skip</IonButton>
    </IonFooter>
  </>
}

export default ScreenFinishes
