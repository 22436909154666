import React from 'react'
import GlobalHeader from '../../../common/components/GlobalHeader'
import LoadingSpinnerContent from '../../../common/components/LoadingSpinner/LoadingSpinnerContent'
import { ScreenComponentProps } from './TaskActionerPage'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import { IonButton, IonCard, IonCardContent, IonFooter, IonImg } from '@ionic/react'
import Styles from '../TaskActionerPage/TaskActionerPage.module.scss'
import { useMarkTaskCompleteMutation, useMarkTaskSkippedMutation } from './commonActions'
import { useEnvironmentContext } from '../../../api/providers/EnvironmentProvider'
import { useQuery } from '@tanstack/react-query'
import { Duration } from 'luxon'

const stripTrailingSlash = (url: string) => {
  return url.endsWith('/')
    ? url.slice(0, -1)
    : url
}

export const ScreenPartnerOpportunity: React.FC<ScreenComponentProps> = ({ getActionableTaskQuery }) => {
  const environment = useEnvironmentContext()
  if (!getActionableTaskQuery.data) return <LoadingSpinnerContent name="ScreenPartnerOpportunity" />
  const task = getActionableTaskQuery.data.getTask

  if (!task.actionablePayload) throw new Error('No actionable payload on this task')

  const query = useQuery([ "PartnerOpportunity", task.actionablePayload ],async () => {
    const response = await fetch(`${partnerBaseUrl}/data.json`)
    return await response.json()
  } , { staleTime: Duration.fromObject({ minutes: 5 }).toMillis() })

  const dataJson = query.data

  const actionableType = task.actionablePayload.trim()

  const partnerBaseUrl = `${stripTrailingSlash(environment.s3.public_assets_url)}/${actionableType}`

  return (
    <>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader pageTitle={task.actionTitle ?? task.title}/>
      </WeaverIonHeader>
      <WeaverIonContent>
        <h2>{task.title}</h2>
        {dataJson?.description && <p>{dataJson?.description}</p> }
        <IonCard className='ion-no-margin'>
          <IonCardContent>
            {dataJson?.headerImage && <IonImg className={Styles.logoUrl} src={`${partnerBaseUrl}/${dataJson?.headerImage}`} /> }
            {dataJson?.subtitle && <h2 className='ion-padding-top'>{dataJson?.subtitle}</h2> }
            {dataJson?.subtitleDescription &&
              <p>
                {dataJson?.subtitleDescription}
              </p>
            }
          </IonCardContent>
          {dataJson?.bodyImage && <IonImg className={Styles.imageUrl} src={`${partnerBaseUrl}/${dataJson?.bodyImage}`} /> }
        </IonCard>
      </WeaverIonContent>
      <IonFooter className={`${Styles.ionFooter} ion-padding-horizontal ion-no-border`}>
        {dataJson?.url && <IonButton expand="block" target='_blank' href={dataJson?.url}>{dataJson?.CTACopy}</IonButton> }
        <IonButton className={Styles.ionOutlineButton} expand="block" fill="outline" onClick={useMarkTaskCompleteMutation(getActionableTaskQuery)}>Mark task as complete</IonButton>
        <IonButton expand="block" fill="clear" onClick={useMarkTaskSkippedMutation(getActionableTaskQuery)}>Skip</IonButton>
      </IonFooter>
    </>
  )
}
