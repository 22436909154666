import React, { useCallback, useEffect, useState } from 'react'
import { IonButton, IonNote, IonTextarea, useIonAlert, IonIcon, useIonViewDidLeave, IonItem } from '@ionic/react'
import Styles from "./CreateProjectPage.module.scss"

import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import '@ionic/react/css/ionic-swiper.css'
import { useSearchState } from '../../../common/hooks/pages'
import { Swiper as SwiperType } from 'swiper/types'

import { Controller, FieldErrors, useForm } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { CreateProjectMutation, ProjectType, BudgetCategory, useCreateProjectMutation, WorkStartEstimate, Currency, TeamType, PropertyType } from '../../../graphql/generated'
import { useGraphQLDataSource } from '../../../api/graphql'
import IndicatorBar from "./IndicatorBar"
import AddContractorsSlide, { ContractorFormSchema } from './AddContractorsSlide'
import { arrowBack } from 'ionicons/icons'
import SuccessSlide from './SuccessSlide'
import GenericSelectorList from '../../../common/components/GenericSelectorList'
import GlobalHeader from '../../../common/components/GlobalHeader/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import PickAddress from '../../../common/components/PickAddress'
import { zMoney, zWeaverAddressInput } from '../../../graphql/zod'
import { projectTypeLabels } from "../../onboarding/team/onboarding.i18n"
import { getGenericSelectorOptionsForEnum } from "../../../common/components/GenericSelectorList/helpers"
import { CREATE_PROJECT_CONTRACTORS, MAXIMUM_PROJECT_VALUE_IN_PENCE, MINIMUM_PROJECT_VALUE_IN_PENCE } from '../../../common/utils'
import { useStoredValue } from '../../../api/providers/StorageProvider'
import { DateTime } from 'luxon'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'
import { useGetInviteUrl } from '../common'
import { getWorkStartEstimateLabels } from '../workEstimate.i18n'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverGuarantee from './WeaverGuarantee/WeaverGuarantee'
import ContractorPrice from './ContractorPrice/ContractorPrice'
import WeaverContractors from './WeaverContractors/WeaverContractors'
import WeaverMoneyIonInput from '../../../common/components/WeaverMoneyIonInput'
import { budgetCategoryLabelsV2 } from './budgetCategory.i18n'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { asMoney, makeMoneyFlexible, moneyAmountAsNumber, moneyToText } from '../../../common/utils/currency'
import SingleClickButton from '../../../common/components/SingleClickButton'
import { CostEstimateSelector } from './CostEstimateSelector'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { propertyTypeLabels } from '../commonProjectLabels.i18n'
import { flexPercentages_v1 } from '../common'
import { flexibleBudgetTextToSignificantFigures } from '../../../common/utils/budgetTransformation'

const zSearchSchema = z.object({
  page: z.coerce.number().optional(),
})

const FormSchema = z.object({
  projectTypes: z.nativeEnum(ProjectType).array().nonempty(),
  propertyType: z.nativeEnum(PropertyType),
  description: z.string().default(''),
  address: zWeaverAddressInput,
  budgetCategory: z.nativeEnum(BudgetCategory),
  budgetAmount: zMoney.superRefine((val, ctx) => {
    const budgetAmountAsNumber = moneyAmountAsNumber(val)
    if (budgetAmountAsNumber == null) return // We only error on budget amounts once set
    if (budgetAmountAsNumber < MINIMUM_PROJECT_VALUE_IN_PENCE) {
      ctx.addIssue({
        message: "Our contractors can't at the moment serve projects below £30,000",
        code: z.ZodIssueCode.custom,
      })
    }
    if (budgetAmountAsNumber > MAXIMUM_PROJECT_VALUE_IN_PENCE) {
      ctx.addIssue({
        message: "Our contractors can't at the moment serve projects above £3,000,000",
        code: z.ZodIssueCode.custom,
      })
    }
  }),
  workStartEstimate: z.nativeEnum(WorkStartEstimate),
  contractors: z.array(ContractorFormSchema).default([]),
  isCostEstimateSelected: z.boolean().optional().default(false),
})

const FormPartial = FormSchema.partial()
type FormPartial = z.infer<typeof FormPartial>

type FormData = z.infer<typeof FormSchema>

const INITIAL_PAGE_INDEX = 0
const COST_ESTIMATE_VALUE_DEFAULT = "1" // 1 penny

type Draft = {
  type: "draft",
  createdAt: string,
  maxUnlockedIndex: number,
  formData: string,
}
const EMPTY_DRAFT_STATE = {
  type: "initial",
  maxUnlockedIndex: 0,
  createdAt: undefined,
  formData: "{}",
}

type DraftState = Draft | typeof EMPTY_DRAFT_STATE

const CreateProject: React.FC = () => {
  const now = DateTime.now()
  const getInviteUrl = useGetInviteUrl()

  const weaverFlags = useWeaverFlags()
  const taskTemplateIds = (weaverFlags['task-templates'].enabled && weaverFlags['task-templates'].createProject) || undefined

  const [ draftState, setDraftState ] = useStoredValue<DraftState>({ key: "createProjectDraft", initialValue: EMPTY_DRAFT_STATE })
  const [ draftInitComplete, setDraftInitComplete ] = useState(false)
  const { register, handleSubmit, trigger, formState: { errors }, control, getValues, reset, setValue, watch } = useForm<FormData>({ resolver: zodResolver(FormSchema) })

  const { searchData, mergeSearchState } = useSearchState({ page: INITIAL_PAGE_INDEX }, { schema: zSearchSchema } )
  const page = searchData.page || INITIAL_PAGE_INDEX
  const setPage = (page: number) => mergeSearchState({ page })

  const [ present ] = useIonAlert()
  const myTeam = useMyIndividualActiveTeam()

  const [ swiper, setSwiper ] = useState<SwiperType | null>(null)
  const [ maxUnlockedIndex, setMaxUnlockedIndex ] = useState<number>(0)

  const [ isSubmitting, setSubmitting ] = useState(false)
  const [ createdProject, setCreatedProject ] = useState<CreateProjectMutation["createProject"] | null>(null)

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const createProject = useCreateProjectMutation(gqlDataSource)
  const triggerProjectInviteCreated = useAnalyticsEvent("Project_Invite_Created")
  const triggerProjectCreated = useAnalyticsEvent("Project_Created")
  const projectCostEstimateSelected = useAnalyticsEvent("Project_Cost_Estimate_Selected")

  const formData = getValues()
  const formJson = JSON.stringify(formData)

  /**
   * We want to reset the cost estimator selector on the budget range page if the user goes back
   *
   * It is important that if they then choose to enter an amount that we run our budget amount validation again
  */
  const handleSlideChangeTransitionEnd = () => {
    const BUDGET_RANGE_SLIDE_INDEX = 1
    if (swiper?.activeIndex === BUDGET_RANGE_SLIDE_INDEX){
      setValue("isCostEstimateSelected", false)
    }
  }

  useIonViewDidLeave(() => {
    reset({})
    setMaxUnlockedIndex(0)
    setDraftState(EMPTY_DRAFT_STATE)

    if (createdProject) {
      setCreatedProject(null)
    }
  })

  // Draft Initialization
  useEffect(() => {
    if (!draftState || draftInitComplete) return

    try {
      const rawDraftData = JSON.parse(draftState.formData)
      const draftData = FormPartial.parse(rawDraftData)

      reset(draftData)
      setMaxUnlockedIndex(draftState.maxUnlockedIndex)
      setCreatedProject(null)
    } catch (e) {
      console.error("[CreateProject] Failed to restore draft, likely corrupt or parsing failure. Clearing draft...", draftState, e)
      reset({})
      setMaxUnlockedIndex(0)
      setDraftState(EMPTY_DRAFT_STATE)
    }
    setDraftInitComplete(true)

  }, [ draftState?.formData ])

  // Draft persistence
  useEffect(() => {
    if (!draftInitComplete) {
      console.debug("[CreateProject] ignoring persist: draft init has not completed yet")
      return
    }
    if (createdProject) {
      console.debug("[CreateProject] ignoring persist: success state is active. draft should be getting cleared!")
      return
    }
    const nextState: Draft = {
      type: "draft",
      createdAt: DateTime.now().toISO(),
      maxUnlockedIndex,
      formData: formJson,
    }
    setDraftState(nextState)
  }, [ formJson, maxUnlockedIndex ])

  /**
   * These errors are all handled at the screen level.
   * If they have made it to onSubmit and pass through here, it is because we have explicitly allowed them to bypass the budget amount validation.
   * We need to handle the situation manually with our own logic as long as we have branches caused by Feature Flags
   */
  const onError = (errors: FieldErrors) => {
    const allErrors = Object.keys(errors)
    const formData = getValues() // up-to-date form data

    //We dont collect property type in legacy flow but it will be required in new flow
    if (!weaverFlags['MW-2600-expand-project-creation-flow'] && allErrors.length === 1 && allErrors[0] === "propertyType") {
      return onSubmit(formData)
    }

    //Could be cost estimate selected in legacy flow, so ignore property type + budget amount below 30k
    if (!weaverFlags['MW-2600-expand-project-creation-flow'] && allErrors.length === 2 && allErrors.includes("propertyType") && allErrors.includes("budgetAmount")) {
      return onSubmit(formData)
    }

    //New flow has removed works start estimate, we will confirm this in a task.
    if (weaverFlags['MW-2600-expand-project-creation-flow'] && allErrors.length === 1 && allErrors[0] === "workStartEstimate") {
      return onSubmit(formData)
    }

    //New flow has removed works start estimate, architect could select cost estimate and ignore budget amount
    if (weaverFlags['MW-2600-expand-project-creation-flow'] && allErrors.length === 2 && allErrors.includes("workStartEstimate") && allErrors.includes("budgetAmount")) {
      return onSubmit(formData)
    }

    return console.debug(errors)
  }

  const onSubmit = async (data: FormData) => {
    const { address, description = '', budgetCategory, budgetAmount, projectTypes, contractors = [], workStartEstimate = WorkStartEstimate.Unknown, propertyType, isCostEstimateSelected } = data
    try {
      setSubmitting(true)

      // transform the contractors in the form to contractor invites (remove id field)
      const contractorInvites = contractors.map(({ companyName, email, familyName, givenName, phone }) => ({
        companyName,
        email,
        familyName,
        givenName,
        phone,
      }))

      const result = await createProject.mutateAsync({
        project: {
          projectTypes,
          propertyType,
          description,
          address,
          budgetCategory,
          budgetValue: budgetAmount,
          totalContractors: CREATE_PROJECT_CONTRACTORS.contractorCount,
          isBudgetCostEstimate: isCostEstimateSelected,
          contractorInvites: contractorInvites ?? [],
          workStartEstimate,
          taskTemplateIds,
        },
      })

      // sending analytics events doesn't affect project creation, so update state/screen immediately
      setCreatedProject(result.createProject)
      reset()

      await Promise.all(contractors.map(async (contractor, ix) => {
        // invites on a project are not dependent on who's being invited, they can be joined to contractors in the frontend
        const invite = result.createProject.memberInvites[ix]

        if (!invite) {
          console.error("[CreateProject] Missing invite for corresponding contractor index", ix)
          return
        }

        try {
          await triggerProjectInviteCreated({
            source: "createProject",
            inviteId: invite.id,
            inviteUrl: getInviteUrl(invite.id),
            inviteEmail: contractor.email,
            inviteCompanyName: contractor.companyName,
            inviteFamilyName: contractor.familyName,
            inviteGivenName: contractor.givenName,
            invitePhone: contractor.phone,
            projectId: result.createProject.id,
            projectBudget: result.createProject.budgetValue,
            projectDescription: result.createProject.description,
            projectName: result.createProject.title,
            projectTypes: result.createProject.projectTypes,
          })
        } catch (e) {
          console.error("[CreateProject] failed to send invite analytics event ", invite.id, e)
        }
      }))

      reset({})
      setMaxUnlockedIndex(0)
      setCreatedProject(result.createProject)
      await triggerProjectCreated({
        projectId: result.createProject.id,
        property: data.propertyType,
        types: data.projectTypes,
        description: data.description,
        budget: data.budgetAmount,
        budgetFlex: data.budgetCategory,
        address: data.address,
        constructionStart: data.workStartEstimate,
        /** @deprecated MW-2386-remove-tender-return-date - MAY STILL BE USED BY C.IO */
        tenderReturn: undefined,
        taskTemplateIds,
      })

      if (data.budgetAmount.amountInPence === COST_ESTIMATE_VALUE_DEFAULT) {
        await projectCostEstimateSelected({
          projectId: result.createProject.id,
        })}

      setDraftState(EMPTY_DRAFT_STATE)
    } catch (e) {
      if (e instanceof Error) {
        present({
          header: "Failed to Create Project",
          message: e.message,
          buttons: [
            {
              text: "Dismiss",
              role: 'cancel',
            },
          ],
        })
      }
    }
    setSubmitting(false)
  }

  const tryUnlockPage = async (fields: (keyof FormData)[], requestedPage: number) => {
    const validationSuccess = await trigger(fields)

    if (validationSuccess) {
      // unlock the requested page if required
      if (requestedPage > maxUnlockedIndex) {
        setMaxUnlockedIndex(requestedPage)
      }
      setPage(requestedPage)
    }
  }

  // sync swiper with query state
  useEffect(() => {
    if (!swiper || swiper.destroyed) return
    if (page > maxUnlockedIndex) {
      setPage(maxUnlockedIndex)
      swiper.slideTo(maxUnlockedIndex)
      return
    }
    swiper.slideTo(page)
  }, [ swiper, page, maxUnlockedIndex ])

  const handleNoContractorsClick = useCallback(async () => {
    await setValue("contractors", [])
    await tryUnlockPage([], 7)

  }, [ setValue, tryUnlockPage ])

  /**
   *  DO NOT VALIDATE BUDGET CATEGORY OR BUDGET AMOUNT AS USER HAS CLICKED COST ESTIMATOR
   *
   *  Default budget amount to 1 penny and budget category to NoIdea:
   *  This is to enables the us to identify users who have clicked the cost estimator
   *
   *  It is better to have a set amout of 1 penny than undefined as we have had cases of
   *  users with undefined budget amounts passing through this flow unintentionally
   *  due to their brower setting not being in English.
   *  */
  const handleCostEstimatorSelect = async () => {
    setValue("budgetAmount", { currency: Currency.Gbp, amountInPence: COST_ESTIMATE_VALUE_DEFAULT })
    setValue("budgetCategory", BudgetCategory.NoIdea)
    setValue("contractors", []) // This has been deprecated, it is not being defaulted to [] in the form schema like it should be. It is causing submit to fail as undefined.
    setValue('isCostEstimateSelected', true),

    weaverFlags['MW-2600-expand-project-creation-flow']
      ? await handleSubmit(onSubmit, onError)()
      : tryUnlockPage([], 2)

  }

  // Listen to change of budgetAmount to update Contractor flex budget
  const watchedInputFieldValue = watch("budgetAmount")

  const getFlexibleBudget = useCallback(() => {
    const adjustFlexBudgetFlag = weaverFlags['MW-2645-adjust-flex-budget-ranges']
    const { rangeBottom, rangeTop } = makeMoneyFlexible(formData.budgetAmount ?? { currency: Currency.Gbp, amountInPence: '0' }, flexPercentages_v1[formData.budgetCategory], adjustFlexBudgetFlag)
    if (rangeBottom.amountInPence === '0') return '£0'

    return flexibleBudgetTextToSignificantFigures({ rangeBottom, rangeTop })
  }, [ watchedInputFieldValue ])

  const checkBudgetAmountBeforeSumbit = async () => {
    const resultOfBudgetAmountValidation = await trigger("budgetAmount")
    if (resultOfBudgetAmountValidation) {
      handleSubmit(onSubmit, onError)()
    }
  }

  return <WeaverIonPage id='CreateProjectPage'>
    <WeaverIonHeader className={`${GlobalHeaderStyles.globalHeader} ${createdProject ? `ion-no-border` : null}`}>
      {!createdProject && <GlobalHeader />}
    </WeaverIonHeader>
    <WeaverIonContent>
      <div className={Styles.container}>
        {isSubmitting && <div className={Styles.submitOverlay}></div>}
        {!draftInitComplete ? <LoadingSpinner name="CreateProject" /> : <>
          {createdProject ? <SuccessSlide createdProject={createdProject} /> : <>

            {weaverFlags['MW-2462-remove-onboarding-pages']
              ? weaverFlags['MW-2600-expand-project-creation-flow']
                ? <IndicatorBar currentPageIndex={page} totalPages={5} maxAvailableIndex={4} onNavigate={(page) => swiper?.slideTo(page)} />
                : <IndicatorBar currentPageIndex={page} totalPages={4} maxAvailableIndex={3} onNavigate={(page) => swiper?.slideTo(page)} />
              : <IndicatorBar currentPageIndex={page} totalPages={swiper?.slides?.length ?? 0} maxAvailableIndex={maxUnlockedIndex} onNavigate={(page) => swiper?.slideTo(page)} />
            }

            <div className={Styles.swiperContainer}>
              <Swiper
                className={Styles.swiper}
                onActiveIndexChange={(x) => setPage(x.activeIndex)}
                onSwiper={(swiper) => setSwiper(swiper)}
                onSlideChangeTransitionEnd={handleSlideChangeTransitionEnd}
                allowSlideNext={page <= maxUnlockedIndex}
                simulateTouch={false}
              >

                {/** Swtitching the order of address and budget value screens */}
                {weaverFlags['MW-2600-expand-project-creation-flow']
                  ? <>
                    <SwiperSlide data-testid="PropertyTypeSelector.Page">
                      <div className={Styles.slideContainer}>
                        <h2>What type of property is this?</h2>
                        <div>
                          <Controller
                            control={control}
                            name="propertyType"
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (<div className={Styles.propertyList}>
                              <GenericSelectorList
                                options={getGenericSelectorOptionsForEnum(PropertyType, propertyTypeLabels)}
                                selected={value || []}
                                multiSelect={false}
                                onSelect={(value) => onChange(value)}
                                showItemDetail={false}
                                colSize={'12'}
                                render={({ value }) => <p>{propertyTypeLabels[value]}</p>}
                              />

                              {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                            </div>
                            )}
                          />
                        </div>

                        {errors.description ? <IonNote color='danger'>{errors.description.message}</IonNote> : null}

                        <div className={Styles.buttonContainer}>
                          <div></div>
                          <IonButton color='primary' onClick={() => tryUnlockPage([ "propertyType" ], 1)}
                            data-testid="ProopertTypeSelector.NextButton"
                          >
                            Next</IonButton>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide data-testid="ProjectTypeSelector.Page">
                      <div className={Styles.slideContainer}>
                        <h2>What type of project is it?</h2>
                        <p>We will match you with contractors who have relevant work experience. Select all that apply.</p>
                        <div>
                          <Controller
                            control={control}
                            name="projectTypes"
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (<div className={Styles.projectList}>
                              <GenericSelectorList
                                options={getGenericSelectorOptionsForEnum(ProjectType, projectTypeLabels)}
                                selected={value || []}
                                multiSelect={true}
                                onSelect={(value) => onChange(value)}
                                showItemDetail={false}
                                confirmPropertySelectionWithAlert={[ ProjectType.NewBuild ]}
                                alertMessage={"'New build' refers to a recently constructed, unused property or structure, built from scratch."}
                                colSize={'6'}
                                render={({ value }) => <p><div key={value} id={value}>{projectTypeLabels[value]}</div></p>}
                              />
                              {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                            </div>
                            )}
                          />
                        </div>

                        {errors.description ? <IonNote color='danger'>{errors.description.message}</IonNote> : null}

                        <div className={Styles.buttonContainer}>
                          <IonButton color='secondary' onClick={() => setPage(0)}><IonIcon icon={arrowBack} />Back</IonButton>
                          <IonButton color='primary' onClick={() => tryUnlockPage([ "projectTypes" ], 2)}
                            data-testid="ProjectTypeSelector.NextButton"
                          >
                            Next</IonButton>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide data-testid="CreateProject.PropertyAddress.Container">
                      <div className={Styles.slideContainer}>
                        <h2>Where is your project?</h2>
                        <Controller
                          control={control}
                          name="address"
                          render={({
                            field: { onChange, value },
                            fieldState: { error: fieldStateEmptyError },
                            formState: { errors },
                          }) => (<>
                            {fieldStateEmptyError ? <p className={Styles.errorMessage}>{fieldStateEmptyError.message}</p> : null}
                            <PickAddress title='' value={value} setValue={onChange} hasError={errors.address} hideFields={[ "careOf", 'poBox', 'county', 'country' ]} />
                          </>
                          )}
                        />
                        <div className={Styles.buttonContainer}>
                          <IonButton color='secondary' onClick={() => setPage(1)}><IonIcon icon={arrowBack} /> Back</IonButton>
                          <IonButton color='primary' onClick={() => tryUnlockPage([ "address" ], 3)}
                            data-testid="CreateProject.PropertyAddress.NextButton"
                          >Next</IonButton>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide data-testid="CreateProject.BudgetFlexibility.page">
                      <div className={Styles.slideContainer}>
                        <h2>How flexible is your budget?</h2>
                        <p className={Styles.slideDescription}>The budget is used to match you to the right type of contractor who has experience working on projects like yours, while keeping the exact amount confidential from the contractors.</p>
                        <Controller
                          control={control}
                          name="budgetCategory"
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (<>
                            <GenericSelectorList
                              options={getGenericSelectorOptionsForEnum(BudgetCategory, budgetCategoryLabelsV2)}
                              selected={value}
                              onSelect={value => onChange(value)}
                            />
                            {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                          </>
                          )}
                        />

                        {myTeam?.type === TeamType.Architect &&
                        <CostEstimateSelector onSelect={handleCostEstimatorSelect}/>
                        }
                        <div className={Styles.buttonContainer}>
                          <IonButton color='secondary' onClick={() => setPage(2)}><IonIcon icon={arrowBack} />Back</IonButton>
                          <IonButton color='primary' onClick={() => tryUnlockPage([ "budgetCategory" ], 4)}
                            data-testid="Budget.NextButton"
                          >Next</IonButton>
                        </div>
                      </div>
                    </ SwiperSlide>

                    <SwiperSlide data-testid="Budget.Page">
                      <div className={Styles.slideContainer}>
                        <h2>What&apos;s your budget?</h2>
                        <p>Budget information helps us match builders who have worked on similar budgets before, while keeping the exact amount confidential from the contractors.</p>
                        <h6>Budget Value (GBP, ex. VAT)</h6>
                        <Controller
                          control={control}
                          name="budgetAmount"
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => <>
                            <IonItem className={Styles.ionItemInput} lines="none">
                              <WeaverMoneyIonInput
                                value={value}
                                setValue={onChange}
                                withPennies={false}
                                minValue={asMoney(value?.currency ?? Currency.Gbp, MINIMUM_PROJECT_VALUE_IN_PENCE)}
                              />
                            </IonItem>
                            {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                          </>
                          }
                        />

                        <div className={Styles.contractorFlexibleBudgetContainer}>
                          <p className={Styles.contractorFlexibleBudgetTitle}>What budget contractors will see:</p>
                          <h6 className={Styles.contractorFlexibleBudgetText}>{getFlexibleBudget()}</h6>
                        </div>

                        <div className={Styles.buttonContainer}>
                          <IonButton color='secondary' onClick={() => setPage(3)}><IonIcon icon={arrowBack} /> Back</IonButton>
                          <SingleClickButton data-testid="CreateProjectButton"
                            color='primary'
                            type='submit'
                            onClick={checkBudgetAmountBeforeSumbit}>Create project</SingleClickButton>
                        </div>
                      </div>
                    </SwiperSlide>
                  </>
                  : <>
                    <SwiperSlide data-testid="ProjectTypeSelector.Page">
                      <div className={Styles.slideContainer}>
                        <h2>What type of project is it?</h2>
                        <p>We will match you with contractors who have relevant work experience. Select all that apply.</p>
                        <div>
                          <Controller
                            control={control}
                            name="projectTypes"
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (<div className={Styles.projectList}>
                              <GenericSelectorList
                                options={getGenericSelectorOptionsForEnum(ProjectType, projectTypeLabels)}
                                selected={value || []}
                                multiSelect={true}
                                onSelect={(value) => onChange(value)}
                                showItemDetail={false}
                                confirmPropertySelectionWithAlert={[ ProjectType.NewBuild ]}
                                alertMessage={"'New build' refers to a recently constructed, unused property or structure, built from scratch."}
                                colSize={'6'}
                                render={({ value }) => <p><div key={value} id={value}>{projectTypeLabels[value]}</div></p>}
                              />
                              {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                            </div>
                            )}
                          />
                        </div>

                        <>
                          <h2>Anything else to add?</h2>
                          <IonItem className={Styles.ionItemInput} lines='none'>
                            <IonTextarea autoGrow={true} placeholder='(Optional) Describe your project...' {...register("description")}></IonTextarea>
                          </IonItem>
                        </>

                        {errors.description ? <IonNote color='danger'>{errors.description.message}</IonNote> : null}

                        <div className={Styles.buttonContainer}>
                          <IonButton color='primary' onClick={() => tryUnlockPage([ "projectTypes", "description" ], 1)}
                            data-testid="ProjectTypeSelector.NextButton"
                          >
                            Next</IonButton>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide data-testid="Budget.Page">
                      <div className={Styles.slideContainer}>
                        <h2>What&apos;s your budget?</h2>
                        <h6>Budget Value (GBP, ex. VAT)</h6>
                        <Controller
                          control={control}
                          name="budgetAmount"
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => <>
                            <IonItem className={Styles.ionItemInput} lines="none">
                              <WeaverMoneyIonInput
                                value={value}
                                setValue={onChange}
                                withPennies={false}
                                minValue={asMoney(value?.currency ?? Currency.Gbp, MINIMUM_PROJECT_VALUE_IN_PENCE)}
                              />
                            </IonItem>
                            {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                          </>
                          }
                        />

                        {myTeam?.type === TeamType.Architect &&
                          <CostEstimateSelector onSelect={handleCostEstimatorSelect}/>
                        }

                        <h2>How flexible is your budget?</h2>
                        <Controller
                          control={control}
                          name="budgetCategory"
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (<>
                            <GenericSelectorList
                              options={getGenericSelectorOptionsForEnum(BudgetCategory, budgetCategoryLabelsV2)}
                              selected={value}
                              onSelect={value => onChange(value)}
                            />
                            {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                          </>
                          )}
                        />
                        <div className={Styles.buttonContainer}>
                          <IonButton color='secondary' onClick={() => setPage(0)}><IonIcon icon={arrowBack} /> Back</IonButton>
                          <IonButton color='primary' onClick={() => tryUnlockPage([ "budgetCategory", "budgetAmount" ], 2)}
                            data-testid="Budget.NextButton"
                          >Next</IonButton>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide data-testid="CreateProject.PropertyAddress.Container">
                      <div className={Styles.slideContainer}>
                        <h2>Where is your project?</h2>
                        <Controller
                          control={control}
                          name="address"
                          render={({
                            field: { onChange, value },
                            fieldState: { error: fieldStateEmptyError },
                            formState: { errors },
                          }) => (<>
                            {fieldStateEmptyError ? <p className={Styles.errorMessage}>{fieldStateEmptyError.message}</p> : null}
                            <PickAddress title='' value={value} setValue={onChange} hasError={errors.address} hideFields={[ "careOf", 'poBox', 'county', 'country' ]} />
                          </>
                          )}
                        />
                        <div className={Styles.buttonContainer}>
                          <IonButton color='secondary' onClick={() => setPage(1)}><IonIcon icon={arrowBack} /> Back</IonButton>
                          <IonButton color='primary' onClick={() => tryUnlockPage([ "address" ], 3)}
                            data-testid="CreateProject.PropertyAddress.NextButton"
                          >Next</IonButton>
                        </div>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide data-testid="ConstructionBegin.Page">
                      <div className={Styles.slideContainer}>
                        <h2>When do you want to begin construction?</h2>
                        <Controller
                          control={control}
                          name="workStartEstimate"
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (<div className={Styles.projectList}>
                            <GenericSelectorList
                              options={getGenericSelectorOptionsForEnum(WorkStartEstimate, getWorkStartEstimateLabels())}
                              selected={value}
                              onSelect={(value) => onChange(value)}
                              showItemDetail={false}
                              colSize="12"
                              render={({ value }) => <p><div key={value} id={value}>{getWorkStartEstimateLabels()[value]}</div></p>}
                            />
                            {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                          </div>
                          )} />
                        <div className={Styles.buttonContainer}>
                          <IonButton color='secondary' onClick={() => setPage(2)}><IonIcon icon={arrowBack} />Back</IonButton>
                          {weaverFlags['MW-2462-remove-onboarding-pages']
                            ? <SingleClickButton data-testid="CreateProjectButton"
                              color='primary'
                              type='submit'
                              onClick={handleSubmit(onSubmit, onError)}>Create project</SingleClickButton>
                            : <IonButton data-testid="ConstructionBegin.NextButton"
                              color='primary'
                              onClick={() => tryUnlockPage([ "workStartEstimate" ], 4)}>Next</IonButton>}
                        </div>
                      </div>
                    </SwiperSlide>
                  </>}

                {/** @deprecated by MW-2462-remove-onboarding-pages  */}
                <SwiperSlide data-testid="InviteContractors.Page">
                  <div className={Styles.slideContainer}>
                    <h2>Contractors</h2>
                    <h3>Would you like to invite your own contractors?</h3>
                    <p>Adding your own contractors lets Weaver know how many additional contractors to find.</p>
                    <div className={Styles.contractorChoices}>
                      <IonButton color='light' onClick={() => tryUnlockPage([], 6)} >
                        <div>
                          <h3>Yes</h3>
                          <p>I would like to add my own contractors</p>
                        </div>
                      </IonButton>
                      <IonButton data-testid="InviteContractors.DoNotHaveAnyContractorsButton" color='light' onClick={handleNoContractorsClick}>
                        <div>
                          <h3>No</h3>
                          <p>I don&apos;t have any contractors to add</p>
                        </div>
                      </IonButton>
                    </div>

                    <div className={Styles.buttonContainer}>
                      <IonButton color='secondary' onClick={() => setPage(3)}><IonIcon icon={arrowBack} /> Back</IonButton>
                    </div>
                  </div>
                </SwiperSlide>

                {/** @deprecated This view is not currently used and may be removed in the B5 iteration */}
                <SwiperSlide>
                  <Controller
                    control={control}
                    name="contractors"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (<div className={Styles.slideContainer}>
                      <AddContractorsSlide value={value} onChange={onChange} max={CREATE_PROJECT_CONTRACTORS.contractorCount} />
                      {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
                      <div className={Styles.buttonContainer}>
                        <IonButton color='secondary' onClick={() => setPage(4)}><IonIcon icon={arrowBack} /> Back</IonButton>
                        <IonButton color='primary' onClick={() => tryUnlockPage([], 6)}>Next</IonButton>
                      </div>
                    </div>
                    )}
                  />
                </SwiperSlide>

                {/** @deprecated by MW-2462-remove-onboarding-pages  */}
                <SwiperSlide>
                  <div className={Styles.slideContainer}>
                    <WeaverContractors />
                    <ContractorPrice />
                    <WeaverGuarantee />
                    <div className={Styles.buttonContainer}>
                      <IonButton color='secondary' onClick={() => setPage(4)}><IonIcon icon={arrowBack} /> Back</IonButton>
                      <IonButton data-testid="CreateProjectButton" color='primary' onClick={handleSubmit(onSubmit)}>Create project</IonButton>
                    </div>
                  </div>
                </SwiperSlide>

              </Swiper>
            </div>
          </>}
        </>}
      </div>
    </WeaverIonContent>
  </WeaverIonPage >
}

export default CreateProject
