import React, { ComponentProps } from "react"
import { IonBadge, IonIcon, IonPopover } from "@ionic/react"
import Styles from './WeaverVerifiedBadge.module.scss'
import { useWeaverFlags } from "../../../api/thirdParty/launchDarkly/useWeaverFlags"
import verifiedIcon from "../../../assets/icons/verified-icon.svg"
import { isPlatform } from "@ionic/core"

type WeaverBadgePros = ComponentProps<typeof IonBadge>

const WeaverVerifiedBadge: React.FC<WeaverBadgePros> = ({ ...ionBadgeProps }) =>  {
  const { ["MW-2646-remove-unverified-label"]: unverifiedLabel } = useWeaverFlags()
  return unverifiedLabel
    ? <>
      <IonBadge mode="md" id="hover-trigger" {...ionBadgeProps} className={`${Styles.verifiedBadge} ${isPlatform('mobileweb') ? Styles.mobile : undefined}`}><IonIcon src={verifiedIcon} /> Verified</IonBadge>
      <IonPopover className={`${Styles.popover} ${isPlatform('mobileweb') ? Styles.mobile : undefined}`} trigger="hover-trigger" triggerAction="hover" showBackdrop={false}>
        <div className={`${Styles.popoverContent} ion-padding`}>
          <h3>Weaver verified</h3>
          <div>We call up every known project the contractor has worked on. With our verified references, you know that a Weaver builder’s project experience is real.</div>
        </div>
      </IonPopover>
    </>
    : <IonBadge color='success' {...ionBadgeProps}>Verified</IonBadge>
}

export default WeaverVerifiedBadge
