import { zodResolver } from "@hookform/resolvers/zod"
import { IonModal, IonToolbar, IonTitle, IonContent, IonGrid, IonRow, IonCol, IonNote, IonTextarea } from "@ionic/react"
import classnames from 'classnames'
import React, { ComponentProps, useState } from "react"
import { useForm, UseFormReturn } from "react-hook-form"
import { z } from "zod"

import Styles from "./ChatAIModal.module.scss"
import SingleClickButton from "../../../../../common/components/SingleClickButton"
import { useGraphQLDataSource } from "../../../../../api/graphql"
import { useReWriteChatRoomMessageForAiMutation } from "../../../../../graphql/generated"
import ProfileDataForAIModal from "../ProfileDataForAIModal/ProfileDataForAIModal"
import { useAnalyticsEvent } from "../../../../../api/providers/SegmentProvider/hooks"
import { useMyIndividualActiveTeam } from "../../../../../api/providers/MyIndividualProvider/MyIndividualProvider"

const DEFAULT_MODAL_BREAKPOINT = 0.93
const COMMON_QUESTIONS = [
  "What's your experience?",
  "My experience",
  "When are you available for a site visit?",
]

const zChatAIModal = z.object({
  message: z.string(),
}).superRefine((data, ctx) => {
  if (!data.message) {
    ctx.addIssue({
      path: [ "message" ],
      code: z.ZodIssueCode.custom,
      message: "Required",
    })
  }
})

type ChatAIModalForm = z.infer<typeof zChatAIModal>

type ChatAIModalProps = {
  onSuccess: (reWrittenMessage: string) => void,
  chatRoomId: string,
  projectId: string | null | undefined,
} & ComponentProps<typeof IonModal>

type ChatAIModalSubmitFn = Parameters<UseFormReturn<ChatAIModalForm>["handleSubmit"]>[0]

const ChatAIModal: React.FC<ChatAIModalProps> =({ onSuccess, chatRoomId, projectId, ...ionModalProps }) => {
  if (!ionModalProps.initialBreakpoint) {
    ionModalProps.initialBreakpoint = DEFAULT_MODAL_BREAKPOINT
  }

  if (!ionModalProps.breakpoints) {
    ionModalProps.breakpoints = [ 0, DEFAULT_MODAL_BREAKPOINT ]
  }

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const myTeam = useMyIndividualActiveTeam()
  const reWriteChatoomMessageForAiMutation = useReWriteChatRoomMessageForAiMutation(gqlDataSource)

  const triggerChatAIRewriteClicked = useAnalyticsEvent("Chat_AI_Rewrite_Clicked")
  const triggerChatAIUpdateProfileClicked = useAnalyticsEvent("Chat_AI_UpdateProfile_Clicked")
  const triggerChatAICommonQuestionsClicked = useAnalyticsEvent("Chat_AI_CommonQuestions_Clicked")
  
  const { register, reset, handleSubmit, formState } = useForm<ChatAIModalForm>({ resolver: zodResolver(zChatAIModal) })
  const { errors } = formState

  const [ profileModalState, setProfileModalState ] = useState<string | undefined>(undefined)

  const reWriteMessage = async (message: string) => {
    await triggerChatAIRewriteClicked({
      projectId: projectId ?? undefined,
      teamId: myTeam?.id,
      teamName: myTeam?.name,
      chatRoomId,
      userQuery: message,
    })
    const response = await reWriteChatoomMessageForAiMutation.mutateAsync({
      input: {
        chatRoomId,
        projectId,
        message,
      },
    })
    
    reset({
      message: '',
    })    
    onSuccess(response.reWriteChatRoomMessageForAi)
  }

  const onFormValid: ChatAIModalSubmitFn = async (data) => {
    const { message } = data
    
    await reWriteMessage(message)
  }

  const onCommonQuestionSelected = async(commonQuestionSelected: string) => {
    await triggerChatAICommonQuestionsClicked({
      projectId: projectId ?? undefined,
      teamId: myTeam?.id,
      teamName: myTeam?.name,
      chatRoomId,
      questionTitle: commonQuestionSelected,
    })
    await reWriteMessage(commonQuestionSelected)
  }

  const onUpdateProfileClicked = async () => {
    await triggerChatAIUpdateProfileClicked({
      projectId: projectId ?? undefined,
      teamId: myTeam?.id,
      teamName: myTeam?.name,
      chatRoomId,
    })
    setProfileModalState("open")
  }

  return <IonModal
    className={Styles.modal}
    {...ionModalProps}>
    <IonToolbar>
      <div className={Styles.titleContainer}>
        <IonTitle>Weaver AI</IonTitle>
        <span className={Styles.betaNotice}>BETA</span>
      </div>
    </IonToolbar>
    <IonContent>
      <div className={`${classnames({
        [Styles.modalContainer]: true,
        [Styles.editableInputs]: true,
      })}`} >
        <p>Type roughly what you want to say: Example: “site visit 2pm friday?”</p>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonTextarea
                className={Styles.messageTextArea}
                enterkeyhint='send'
                {...register("message")}
                autoGrow
                rows={4}
                data-testid="rewrite-message-text-area"
                placeholder={"Type what you want to say..."}
              />
              {errors.message ? <IonNote color='danger'>{errors.message.message}</IonNote> : null}
            </IonCol>
          </IonRow>
          <SingleClickButton expand='block' onClick={handleSubmit(onFormValid)}>Rewrite message</SingleClickButton>
          <SingleClickButton expand='block' fill='outline'  onClick={() => onUpdateProfileClicked()}>Update Profile for better results</SingleClickButton>
        </IonGrid>

        <IonGrid>
          <h3>Common questions</h3>
          <IonRow>
            <IonCol>
              {
                COMMON_QUESTIONS.map((question: string) => <SingleClickButton key={question} fill='outline' onClick={() => onCommonQuestionSelected(question)}>{question}</SingleClickButton>)
              }
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <ProfileDataForAIModal isOpen={!!profileModalState} onDidDismiss={() => setProfileModalState(undefined)} onSuccess={() => setProfileModalState(undefined)} />
    </IonContent>
  </IonModal>
}

export default ChatAIModal
