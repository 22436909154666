import React from 'react'
import { IonRow, IonGrid, IonLabel, IonItem } from '@ionic/react'
import  { Value as E164Number } from 'react-phone-number-input'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form'
import 'react-phone-number-input/style.css'
import Styles from "./MobileNumberCapture.module.scss"
import { FieldError, FieldValues, UseControllerProps } from 'react-hook-form'

type ReactHookFormMobileNumberCaptureProps <T extends FieldValues> = {
  value: E164Number | undefined,
  onChange: (value: E164Number | undefined) => unknown,
  disabled?: boolean,
  error: FieldError | undefined,
  errorMessage?: string,
  textHeading?: string,
  textFieldPhoneNumberTitle?: string,
  textFieldPhoneNumberPlaceholder?: string,
  customClassName?: string,
} & UseControllerProps<T>

/** This is the correct import for using React Phone Input with React Hook Form - https://github.com/catamphetamine/react-phone-number-input#react-hook-form  */
export const ReactHookFormMobileNumberCapture = <T extends FieldValues> (props: ReactHookFormMobileNumberCaptureProps<T>) => {
  return (
    <IonGrid className={Styles.contentContainer}>
      <h3>{props.textFieldPhoneNumberTitle ?? 'Enter Mobile Number'}</h3>
      <IonLabel className={Styles.label} position='stacked'>
        {props.textHeading ?? 'Phone Number'}
      </IonLabel>
      <IonRow className={Styles.item}>
        <IonItem lines='none' className={Styles.inputContainer}>
          <PhoneInputWithCountry
            name={props.name}
            control={props.control}
            className={Styles.phoneInput}
            placeholder={props.textFieldPhoneNumberPlaceholder ?? 'Please Enter Your Phone Number'}
            international
            defaultCountry="GB"
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
            error={props.error?.message}
            data-testid="MobileNumberCapture.Input"
          />
          <p className={Styles.errorMessage}>{props.error && ( props.errorMessage ?? 'An error occured.')}</p>
        </IonItem>
      </IonRow>
    </IonGrid>
  )
}
