import React from 'react'
import { useMyContractorProfile } from '../profile/ContractorLeadPreferences/ContractorBudgetRanges/datasource'
import { getAllBadgeEligibleHistories } from "../profile/ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/ContractorProfileBudgetRangeBadge/hooks"
import { hasBadgeAlready } from '../profile/ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/BadgeUtils'
import { getBudgetRangesByMoneyRange } from '../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges'
import { getFlexibleProjectBudgetAsMoneyRange } from './common'
import { ShowLeadQuery } from '../../graphql/generated'
import { useWeaverFlags } from '../../api/thirdParty/launchDarkly/useWeaverFlags'

/**
 * Given a lead, determine if a contractor is eligible to pay for it.
 *
 * A contractor is eligible to pay for a lead:
 * if they have one badge eligible work history or
 * if they have already been awarded a badge for the lead's budget range
 */

type UseDoesContractorQualifyToPayForLeadProps = {
  lead: Pick<NonNullable<ShowLeadQuery['getLead']>, 'id' | 'budgetValue' | 'budgetCategory'>,
}

export const useDoesContractorQualifyToPayForLead = ({ lead }: UseDoesContractorQualifyToPayForLeadProps) => {
  const profile = useMyContractorProfile()
  const weaverFlags = useWeaverFlags()

  // Extract all budget ranges from the lead's budget value
  const moneyRange = getFlexibleProjectBudgetAsMoneyRange(lead, weaverFlags['MW-2600-expand-project-creation-flow'], weaverFlags['MW-2645-adjust-flex-budget-ranges'])
  const budgetRanges = getBudgetRangesByMoneyRange(moneyRange)

  if (!budgetRanges || !profile) {
    throw new Error("[useDoesContractorQualifyToPayForLead]: Budget range or Contractor profile is not defined")
  }

  // Handle case where a contractor has not claimed a badge yet but has at least one complete work history in a matching budget range
  const hasEligibleWorkHistoriesInBudgetRange = budgetRanges.flatMap(budgetRange => getAllBadgeEligibleHistories(profile, budgetRange)).length > 0

  // Could be a case where a user deletes their work history but maintains their badge, we allow them through in this case.
  const hasAnyAwardedBadges = budgetRanges.some(budgetRange => hasBadgeAlready(profile, budgetRange))

  return weaverFlags['MW-2599-block-users-with-no-wh-accepting-leads']
    ? [ hasEligibleWorkHistoriesInBudgetRange, hasAnyAwardedBadges ].every(Boolean)
    : [ hasEligibleWorkHistoriesInBudgetRange, hasAnyAwardedBadges ].some(Boolean)
}
