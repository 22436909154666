import { PropertyType } from "../../graphql/generated"

export const propertyTypeLabels: Record<PropertyType, string> = {
  [ PropertyType.Flat ]: 'Flat',
  [ PropertyType.TerracedHouse ]: 'Terraced house',
  [ PropertyType.SemiDetachedHouse ]: 'Semi-detached house',
  [ PropertyType.DetachedHouse ]: 'Detached house',
  [ PropertyType.Bungalow ]: 'Bungalow',
  [ PropertyType.Other ]: 'Other',
}
