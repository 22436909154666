import React, { useRef, useState } from "react"
import { IonButton } from "@ionic/react"
import LoadingSpinner from "../LoadingSpinner"
import useHasBeenUnmounted from "../../hooks/useHasBeenUnmounted"

type SingleClickButtonProps = {
  /** Name of this button (used to identify the loading spinner) */
  name?: string,
  processIsLoading?: boolean,
} &
/**
 * Include all the props of IonButton, except `disabled` as it's managed by this component
 */
React.ComponentProps<typeof IonButton>

type IonButtonOnClickHandler = NonNullable<React.ComponentProps<typeof IonButton>['onClick']>

export const SingleClickButton: React.FC<SingleClickButtonProps> = ({ name, processIsLoading, ...ionButtonProps }) => {
  /** State to disable the button when the onClick is in progress */
  const [ inProgress, setInProgress ] = useState(false)
  /** A fail-safe mutable lock which prevents the onClick logic running twice, in most race condition cases */
  const onClickRunning = useRef(false)

  const hasBeenUnmounted = useHasBeenUnmounted()

  const onClick: IonButtonOnClickHandler = async (...onClickArgs) => {
    // If there's no action, then we can short-circuit the whole dance
    if (!ionButtonProps.onClick) return

    // Set the In Progress flags (disabling the button)
    setInProgress(true)

    // Run the action
    if (onClickRunning.current) {
      console.warn(`[SingleClickButton] The onClick is already running and the button was disabled, but I still got onClicked!`)
    } else {
      onClickRunning.current = true
      await ionButtonProps.onClick(...onClickArgs)
      onClickRunning.current = false
    }

    // The following will trigger a react error if the button is unmounted in the onClick (such as a page change).

    // Set the In Progress flag (enabling the button)
    if (!hasBeenUnmounted.current) {
      setInProgress(false)
    }
  }

  return (
    <IonButton {...ionButtonProps} disabled={processIsLoading || inProgress} onClick={onClick} >
      {(inProgress || processIsLoading) && <LoadingSpinner name={name ?? 'singleClickButton'} />}
      {ionButtonProps.children}
    </IonButton>
  )
}

export default SingleClickButton
