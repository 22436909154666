import { useGraphQLDataSource } from "../../../api/graphql"
import { useListProjectTasksQuery } from "../../../graphql/generated"
import { filterMyProjectTasks } from "../../../common/utils/tasks"
import { useMyIndividualActiveTeam } from "../../../api/providers/MyIndividualProvider/MyIndividualProvider"

export const useGetMyProjectTasks = (projectId: string) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const myTeam = useMyIndividualActiveTeam()
  const listProjectTasksQuery = useListProjectTasksQuery(gqlDataSource, { id: projectId }, { refetchOnWindowFocus: false  })
  const projectTasks = listProjectTasksQuery?.data?.listProjectTasks

  if (!myTeam) return console.debug('[useGetMyProjectTasks] No active team found')
  const { pendingTasks, completedTasks } = filterMyProjectTasks(projectTasks, myTeam)

  return { pendingTasks, completedTasks }
}
