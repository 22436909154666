import React from "react"
import LoadingSpinnerContent from "../../../common/components/LoadingSpinner/LoadingSpinnerContent"
import { ScreenComponentProps } from "./TaskActionerPage"
import ScreenSimpleAction from "./common/ScreenSimpleAction"
import { pageConfig_ShowProject, useRouteTo } from "../../../routes"
import { TaskActionableType, TaskStatus, TeamType, useShowProjectQuery } from "../../../graphql/generated"
import { useGraphQLDataSource } from "../../../api/graphql"
import { Duration } from "luxon"
import { useCompleteBackgroundTask } from "../../../common/utils/tasks"

const ScreenInviteArchitect: React.FC<ScreenComponentProps> = (props) => {
  const { getActionableTaskQuery } = props
  const gqlDataSource = useGraphQLDataSource({ api: "core" })
  const routeToContractorProfile = useRouteTo(pageConfig_ShowProject.path)

  const showProject = useShowProjectQuery(gqlDataSource,
    { id: getActionableTaskQuery.data?.getTask.projectId || "" },
    {
      staleTime: Duration.fromObject({ seconds: 30 }).toMillis(),
      enabled: !!getActionableTaskQuery.data?.getTask.projectId,
      select: ({ getProject }) => {
        if (!getProject) throw new Error("Project not found")
        return { getProject }
      },
    },
  )

  if (!getActionableTaskQuery.data || !showProject.data) return <LoadingSpinnerContent name="ScreenInviteArchitect" />
  const task = getActionableTaskQuery.data.getTask
  const project = showProject.data.getProject

  const projectHasArchitect = project.members.some(x => x.team.type === TeamType.Architect) || project.memberInvites.some(x => x.requiredTeamType === TeamType.Architect)
  const buttonText = projectHasArchitect ? "Architect Invited" : "Invite Architect"
  return <>
    <ScreenSimpleAction {...props} disabled={projectHasArchitect} onClick={routeToContractorProfile({ id: task.projectId }, "invite=ARCHITECT")} name="ScreenInviteArchitect" buttonText={buttonText} />
  </>
}

export default ScreenInviteArchitect
