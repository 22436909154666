import React, { useCallback, useState } from 'react'
import { IonAlert, IonButton } from '@ionic/react'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'
import { pageConfig_Projects_AcceptLead_PaymentPlanPicker, useRouteTo } from '../../../routes'
import { getBudgetRangeByMoney } from '../../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges'
import { useDoesContractorQualifyToPayForLead } from '../useDoesContractorQualifyToPayForLead'
import { useMyContractorProfile } from '../../profile/ContractorLeadPreferences/ContractorBudgetRanges/datasource'
import { ShowLeadQuery } from '../../../graphql/generated'
import { budgetRangeLabels } from '../../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges.i18n'
import { getDocumentAndDocumentPreviewData, shouldPayForLead } from '../common'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import { useRouteToUnlockBudgetRange } from '../../profile/ContractorLeadPreferences/ContractorBudgetRanges/useRouteToUnlockBudgetRange'
import { EventProperties } from '../../../api/providers/SegmentProvider/events'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'

import Styles from "./AcceptLeadButton.module.scss"
import { alwaysArray } from '../../../common/utils'

type AcceptLeadButtonProps = {
  lead: NonNullable<ShowLeadQuery['getLead']>,
  loading: boolean,
  source: EventProperties['WorkHistory_References_Range_Selected']['source'],
}

export const AcceptLeadButton: React.FC<AcceptLeadButtonProps> = ({ lead, loading, source }) => {
  const profileQueryResult = useMyContractorProfile()
  const weaverFlags = useWeaverFlags()
  const myContractorProfile = profileQueryResult.getContractorProfileQuery.data?.getContractorProfile

  const canContractorAcceptLead = useDoesContractorQualifyToPayForLead({ lead })
  const { "MW-2604-copy-change-lead-to-tender": leadToTenderFlag, "MW-2609-tender-doc-preview-on-lead": tenderDocPreview } = useWeaverFlags()
  const budgetRange = getBudgetRangeByMoney(lead.budgetValue)

  const triggerEvent_Lead_Accepted_Clicked = useAnalyticsEvent("Lead_Accept_Clicked")
  const triggerEvent_Lead_Accept_Blocked = useAnalyticsEvent("Lead_Accept_Blocked")
  const triggerEvent_Lead_Accept_Blocked_Continued = useAnalyticsEvent("Lead_Accept_Blocked_Continued")
  const triggerEvent_Lead_Accept_Blocked_Cancelled = useAnalyticsEvent("Lead_Accept_Blocked_Cancelled")

  const routeToPaymentPlanPicker = useRouteTo(pageConfig_Projects_AcceptLead_PaymentPlanPicker.path)
  const routeToUnlockBudgetRange = useRouteToUnlockBudgetRange(source)

  const [ showBlockedLeadAlert, setShowBlockedLeadAlert ] = useState(false)

  const acceptLead = useCallback(async () => {
    const leadBudgetRange = getBudgetRangeByMoney(lead?.budgetValue)
    const { documentPreviewCount, activeDocuments, allDocumentTags } = getDocumentAndDocumentPreviewData(lead)

    await triggerEvent_Lead_Accepted_Clicked({
      projectId: lead.id,
      budgetRange: leadBudgetRange,
      documentCount: activeDocuments.length,
      documentPreviewCount,
      allDocumentTags: alwaysArray(allDocumentTags),
    })

    routeToPaymentPlanPicker({ leadId: lead.id })()
  }, [ lead.id, lead.budgetCategory ])

  if (myContractorProfile === undefined || myContractorProfile === null || budgetRange === undefined){
    console.debug('[AcceptLeadButton]: myContractorProfile or budget range is undefined')
    return <LoadingSpinner name="AcceptLeadButton" />
  }

  const blockAcceptLead = async () => {
    await triggerEvent_Lead_Accept_Blocked({ budgetRange, projectId: lead.id })
    setShowBlockedLeadAlert(true)
  }

  const handleAlertCancelClick = async () => {
    await triggerEvent_Lead_Accept_Blocked_Cancelled({
      projectId: lead.id,
      budgetRange: budgetRange,
    })
    setShowBlockedLeadAlert(false)
  }

  const handleAlertContinueClick = async () => {
    await triggerEvent_Lead_Accept_Blocked_Continued({
      projectId: lead.id,
      budgetRange: budgetRange,
    })
    routeToUnlockBudgetRange(budgetRange)()
  }

  /**
   *  We only want users with badges or completed work history to be able to accept leads.
   *  If they don't have any badges or completed work history, we want to show them an alert
   *  and redirect them to complete a work history in the budget range.
   *
   *  Paying customers or free invites can accept leads without having a badge or completed work history.
   */
  const handleOnClickAcceptLead = () => {
    if (!shouldPayForLead(lead, myContractorProfile, weaverFlags['MW-2600-expand-project-creation-flow'], weaverFlags['MW-2645-adjust-flex-budget-ranges'])) return acceptLead()

    canContractorAcceptLead
      ? acceptLead()
      : blockAcceptLead()
  }

  return <>
    {tenderDocPreview
      ? <IonButton expand='block' onClick={handleOnClickAcceptLead} disabled={loading} color="primary">
        I&apos;m interested
      </IonButton>
      : <IonButton expand='block' onClick={handleOnClickAcceptLead} disabled={loading} color="primary">
        Accept {leadToTenderFlag ? 'tender' : 'lead'}
      </IonButton>}

    <IonAlert
      cssClass={Styles.blockLeadAlert}
      isOpen={showBlockedLeadAlert}
      onDidDismiss={() => setShowBlockedLeadAlert(false)}
      message={`<div>
      <p>To be able to accept this ${leadToTenderFlag ? 'tender' : 'lead'}, add a <b>complete</b> past project in the ${budgetRangeLabels[budgetRange]} budget range</p>
      <p>A complete past project has the following:</p>
      <ul>
        <li>At least one reference</li>
        <li>At least one photo</li>
      </ul>
    </div>`}
      buttons={[
        {
          text: 'Cancel',
          role: 'dismiss',
          handler: handleAlertCancelClick,
        },
        {
          text: 'OK',
          handler: handleAlertContinueClick,
        },
      ]}
    ></IonAlert>
  </>
}
