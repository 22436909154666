import { useQueryClient } from "@tanstack/react-query"
import { Duration } from "luxon"
import { useEffect } from "react"
import { useGraphQLDataSource } from "../../../../../api/graphql"
import { useMyIndividualActiveTeam, useMyIndividual } from "../../../../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { useGetChatRoomTeamConnectionsQuery, useSetProjectPartnerReferralStatusMutation, GetChatRoomTeamConnectionsQuery, TeamType, QualifiedReason } from "../../../../../graphql/generated"
import { useChatRoom } from "./ChatRoomProvider"
import { useAnalyticsEvent } from "../../../../../api/providers/SegmentProvider/hooks"

/**
 * When a homeowner sends at least one message to a contractor, we want to qualify the project.
 * Qualification is used to pay referrals to partners. This is important for reporting and the partner dashboard.
 *
 * This hook binds to the project, chatroom, and members, and sends a mutation when the conditions are met. It uses the queryCache to avoid duplicate mutations.
 */
export const useAutoQualifyChatroom = () => {
  const queryClient = useQueryClient()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const currentTeam = useMyIndividualActiveTeam()
  const currentIndividual = useMyIndividual()
  const { chatRoomId, chatRoomMessages } = useChatRoom()
  const teamConnectionsQuery = useGetChatRoomTeamConnectionsQuery(gqlDataSource, { chatRoomId }, { staleTime: Duration.fromObject({ minutes: 1 }).toMillis(), refetchOnWindowFocus: false } )
  const projectPartnerQualified = useAnalyticsEvent("Project_Partner_Qualified")

  // when setting the project partner referral status, update the status in the query cache that this effect depends on
  const setReferral = useSetProjectPartnerReferralStatusMutation(gqlDataSource, {
    onError: (err, variables) => console.log("[useAutoQualifyChatroom] Error while qualifiying project. This will not retry until remount.", err, variables),
    onSuccess: async ({ setProjectPartnerReferralStatus: data }, { projectId, reason }) => {
      queryClient.setQueryData<GetChatRoomTeamConnectionsQuery>(useGetChatRoomTeamConnectionsQuery.getKey({ chatRoomId }), (oldData) => {
        if (!oldData) return oldData
        return {
          ...oldData,
          getChatRoom: oldData.getChatRoom
            ? {
              ...oldData.getChatRoom,
              project: oldData.getChatRoom.project
                ? {
                  ...oldData.getChatRoom.project,
                  partnerReferralStatus: data,
                }
                : undefined,
            }
            : undefined,
        }
      })
      await projectPartnerQualified({ projectId, reason })
    } })

  const getChatRoom = teamConnectionsQuery.data?.getChatRoom
  const project = teamConnectionsQuery.data?.getChatRoom?.project
  const projectIsQualified = !!getChatRoom?.project?.partnerReferralStatus?.reason

  useEffect(() => {
    async function chatQualifyProject() {
      if (setReferral.isError || setReferral.isLoading) return

      if (!!project && !projectIsQualified && currentTeam?.type === TeamType.Homeowner && project.members.some(x => x.team.type === TeamType.Partner)) {
        const chatroomHasContractor = getChatRoom?.teamConnections.some(x => x.team.type === TeamType.Contractor)

        // scanning chatroom messages for a message sent by the homeowner is intensive, so we only do it if the other conditions are met
        if (chatroomHasContractor && chatRoomMessages?.some(message => message.sentByIndividual.id === currentIndividual?.id)) {
          console.debug("[useAutoQualifyChatroom] Qualifying project", project.id)
          await setReferral.mutateAsync({ projectId: project.id, reason: QualifiedReason.Platform })
        }
      }
    }

    chatQualifyProject()
  }, [ project, projectIsQualified, currentTeam, chatRoomMessages, setReferral, currentIndividual?.id, setReferral.isError, setReferral.isLoading ])
}
