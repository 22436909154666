import React from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { IonButton, IonFooter,  IonTextarea } from '@ionic/react'
import SingleClickButton from '../../../common/components/SingleClickButton'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import GlobalHeader from '../../../common/components/GlobalHeader'
import { ScreenComponentProps } from './TaskActionerPage'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useMarkTaskCompleteMutation, useMarkTaskSkippedMutation } from './commonActions'
import LoadingSpinnerContent from '../../../common/components/LoadingSpinner/LoadingSpinnerContent'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import Styles from "./TaskActionerPage.module.scss"
import { useUpdatePublishedProjectMutation } from '../../../graphql/generated'

const FormSchema = z.object({
  description: z.string(),
})

type FormData = z.infer<typeof FormSchema>

const PLACEHOLDER = 'Ex: Removing internal wall, extending the living room and removing 2 chimneys.'

const ScreenProjectDescription: React.FC <ScreenComponentProps> = ({ getActionableTaskQuery }) => {

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const setTaskAsCompleted = useMarkTaskCompleteMutation(getActionableTaskQuery)
  const updatePublishedProject = useUpdatePublishedProjectMutation(gqlDataSource, {
    onSuccess: async () => await setTaskAsCompleted(),
  })

  const { handleSubmit, register } = useForm<FormData>({ resolver: zodResolver(FormSchema) })

  const onSubmit = async(data: FormData) => {
    if (!getActionableTaskQuery.data?.getTask.projectId) return console.error('[ScreenProjectDescription] No projectId found')

    await updatePublishedProject.mutateAsync({
      input: {
        id: getActionableTaskQuery.data?.getTask.projectId,
        description: data.description,
      },
    })
  }

  const onError = (error: FieldErrors) => {
    console.log(error)
  }

  if (!getActionableTaskQuery.data) return <LoadingSpinnerContent name="ProjectFinishesTask" />
  const task = getActionableTaskQuery.data.getTask

  return  <>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader pageTitle={task.actionTitle ?? task.title}/>
    </WeaverIonHeader>
    <WeaverIonContent>
      <h2>Work description</h2>
      <p>Accurate work description helps match contractors with relevant skills.</p>
      <IonTextarea autoGrow={true} className={Styles.descriptionTextArea} placeholder={PLACEHOLDER} {...register("description")}/>
    </WeaverIonContent>
    <IonFooter className='ion-padding-horizontal ion-no-border ion-margin-bottom'>
      <SingleClickButton  expand="block" onClick={handleSubmit(onSubmit, onError)}>Confirm</SingleClickButton>
      <IonButton expand="block" fill="clear" onClick={useMarkTaskSkippedMutation(getActionableTaskQuery)}>Skip</IonButton>
    </IonFooter>
  </>
}

export default ScreenProjectDescription
