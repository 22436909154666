import React from 'react'
import { IonIcon, IonItem, useIonViewDidEnter } from "@ionic/react"
import RangeBadge from "../../profile/ContractorLeadPreferences/ContractorBudgetReferences/RangeBadge"
import Styles from "./PaymentPlanPickerPage.module.scss"
import { card, checkmarkCircle } from 'ionicons/icons'
import { BudgetRange, GetStripeProductConfigsQuery, useGetStripeProductConfigByPriceIdQuery, useShowLeadQuery } from '../../../graphql/generated'
import { FeeOption } from './ContractorLeadAcceptorPage'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import GlobalHeader from '../../../common/components/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import SingleClickButton from '../../../common/components/SingleClickButton'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import NavBackButton from '../../../common/components/GlobalHeader/NavBackButton'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'
import { getMonthlyPrice, moneyToText } from '../../../common/utils/currency'
import { useGraphQLDataSource } from '../../../api/graphql'
import { Duration } from 'luxon'
import { getEnumLabel } from '../../../common/utils'
import { PplFAQ } from '../../../common/components/FAQ/PplFAQ'
import { isPlatform } from '@ionic/core'
import ErrorBlock from '../../../common/components/ErrorBlock'

type PaymentPlanPickerPageProps = {
  leadId: string,
  budgetRange: BudgetRange,
  budgetRangeLabel: string,
  stripeProductLeadFee: GetStripeProductConfigsQuery["getStripeProductConfigs"][number],
  stripeProductMonthlySubscription: GetStripeProductConfigsQuery["getStripeProductConfigs"][number],
  handleNextClick: (selected: FeeOption) => Promise<void> | void,
  lastDigitsCard?: string | null,
  subscriptionIsProcessing?:boolean,
  pptIsProcessing?: boolean,
  changePaymentCard?: () => Promise<void> | void,
  isGettingUrlForStripePaymentUpdate?:boolean,
}

const _PlanPickerPPLTest: React.FC<PaymentPlanPickerPageProps> = ({ leadId, budgetRange, stripeProductLeadFee: leadProductConfig, stripeProductMonthlySubscription: subscriptionProductConfig, handleNextClick, lastDigitsCard, subscriptionIsProcessing, pptIsProcessing, changePaymentCard, isGettingUrlForStripePaymentUpdate }) => {
  const weaverFlags = useWeaverFlags()
  const gqlDataSource = useGraphQLDataSource({ api: "core" })
  const leadQuery = useShowLeadQuery(gqlDataSource, { id: leadId }, { refetchOnWindowFocus: false, staleTime: Duration.fromObject({ minutes: 5 }).toMillis(), select: ({ getLead }) => {
    if (!getLead) throw new Error("Lead not found")
    return { getLead }
  } })
  const lead = leadQuery.data?.getLead

  const hasPPTTier = !!lead?.priceTier?.payPerTender?.priceId
  const hasSubscriptionTier = !!lead?.priceTier?.subscription?.priceId

  const pptPriceConfig = useGetStripeProductConfigByPriceIdQuery(gqlDataSource,
    { priceId: lead?.priceTier?.payPerTender?.priceId || '' },
    { refetchOnWindowFocus: false, enabled: hasPPTTier, staleTime: Duration.fromObject({ minutes: 5 }).toMillis() },
  )
  const subscriptionPriceConfig = useGetStripeProductConfigByPriceIdQuery(gqlDataSource,
    { priceId: lead?.priceTier?.subscription?.priceId || '' },
    { refetchOnWindowFocus: false, enabled: hasSubscriptionTier, staleTime: Duration.fromObject({ minutes: 5 }).toMillis() },
  )
  const triggerEvent_Lead_PaymentPlanPicker_Viewed = useAnalyticsEvent("Lead_PaymentPlanPicker_Viewed")

  useIonViewDidEnter(() => {
    triggerEvent_Lead_PaymentPlanPicker_Viewed({
      projectId: leadId,
      budgetRange: budgetRange,
    })
  }, [])

  const susbcriptionCopyUpdateFlag = weaverFlags['MW-2656-copy-change-on-subscription']
  // render nothing when the config is loading. we should never show a wrong price to the user
  if (
    leadQuery.isLoading ||
    (hasPPTTier && pptPriceConfig.isLoading) ||
    (hasSubscriptionTier && subscriptionPriceConfig.isLoading)
  ) {
    return <WeaverIonContent className='ion-padding-none'> </WeaverIonContent>
  }

  // Catastropic error - missing information about pricing.
  // if the error is something transient from stripe (e.g. ratelimit?), a refresh should work!
  if (
    leadQuery.isError ||
    (hasPPTTier && pptPriceConfig.isError) ||
    (hasSubscriptionTier && subscriptionPriceConfig.isError)
  ) {
    return <WeaverIonContent className='ion-padding'>
      <ErrorBlock name='PaymentPlanPickerPage' onRefresh={() => {
        leadQuery.refetch()
        pptPriceConfig.refetch()
        subscriptionPriceConfig.refetch()
      }}>
        <p>Oops! We weren&apos;t able to load the payment screen.</p>
        <p>If this problem persists, please contact support.</p>
      </ErrorBlock>
    </WeaverIonContent>
  }

  const budgetRangeLeadFee = moneyToText(leadProductConfig.price)
  const budgetRangeMonthlySubscriptionFee = moneyToText(getMonthlyPrice(subscriptionProductConfig))

  const payPerTenderPrice = hasPPTTier ? moneyToText(pptPriceConfig.data?.getStripeProductConfigByPriceId?.price) : budgetRangeLeadFee
  const subscriptionPrice = hasSubscriptionTier
    ? moneyToText(subscriptionPriceConfig.data?.getStripeProductConfigByPriceId ? getMonthlyPrice(subscriptionPriceConfig.data?.getStripeProductConfigByPriceId) : undefined)
    : budgetRangeMonthlySubscriptionFee

  return (
    <WeaverIonContent className='ion-padding-none'>
      <div className={`${Styles.topBackground} ion-text-center`}>
        <RangeBadge budgetRange={budgetRange} />
      </div>
      <div className={Styles.selectMembershipBody}>
        <h3 className={`ion-padding ${Styles.titleClassName}`}>
          <b>
            Select your payment preference
          </b>
        </h3>

        <IonItem lines='none' className={Styles.bulletPointContainer}>
          <div className={Styles.tenderReadinessText}>
            <div className={Styles.tenderReadinessTextLine}><IonIcon icon={checkmarkCircle} slot="start" /> Limited contractors tendering</div>
            <div className={Styles.tenderReadinessTextLine}><IonIcon icon={checkmarkCircle} slot="start" /> Satisfaction Money-back Guarantee</div>
            <div className={Styles.tenderReadinessTextLine}><IonIcon icon={checkmarkCircle} slot="start" /> No further fees when you win the tender</div>
          </div>
        </IonItem>

        <div className={`ion-padding ${Styles.paymentButtonsContainer}`}>
          <IonItem disabled={isGettingUrlForStripePaymentUpdate || subscriptionIsProcessing || pptIsProcessing} hidden={!lastDigitsCard} className={`${Styles.paymentCardInfoItem} ion-no-padding`} lines='none'>
            Pay with card: <IonIcon className={Styles.cardIcon} icon={card} /> ****{lastDigitsCard}
            <div className={`${Styles.paymentLink} ${isPlatform('desktop') ? Styles.onDesktop : undefined} `} slot={isPlatform('desktop') ? undefined : 'end'} color='primary' onClick={changePaymentCard}>
              Change
            </div>
          </IonItem>

          <IonItem lines='none' disabled={subscriptionIsProcessing} className={`${Styles.paymentItem} ion-no-padding`}>
            <SingleClickButton
              processIsLoading={pptIsProcessing}
              expand='block' className={Styles.firstButton}
              onClick={() => handleNextClick(FeeOption.PerLead)}>
              Pay only {payPerTenderPrice} for this tender
            </SingleClickButton>
          </IonItem>
          <div className="ion-text-center ion-padding">
            Or
          </div>
          <IonItem lines='none' disabled={pptIsProcessing} className={`${Styles.paymentItem} ion-no-padding`}>
            <SingleClickButton processIsLoading={subscriptionIsProcessing} expand='block' className={`${Styles.secondButton} ${susbcriptionCopyUpdateFlag && Styles.secondButtonHeight}`} fill='outline'onClick={() => handleNextClick(FeeOption.Subscription)}>
              <div>
                <p className={Styles.subscribeButtonMainText}>Subscribe for {susbcriptionCopyUpdateFlag ? '' : ' 3 tenders'} {subscriptionPrice}/month</p>
                {susbcriptionCopyUpdateFlag &&  <p className={Styles.subscribeButtonSubText}>1 to 4 tenders depending on seasonality</p>}
              </div>
            </SingleClickButton>
          </IonItem>
        </div>
        {weaverFlags['FAQ'] && <PplFAQ />}
      </div>
    </WeaverIonContent>
  )
}

const PaymentPlanPickerPage: React.FC<PaymentPlanPickerPageProps> = (props) => {
  const gqlDataSource = useGraphQLDataSource({ api: "core" })
  const { data } = useShowLeadQuery(gqlDataSource, { id: props.leadId }, { staleTime: Duration.fromObject({ minutes: 5 }).toMillis() })
  const leadTitle = data?.getLead?.projectTypes ? `Tender - ${data?.getLead?.projectTypes.map(x => ' ' + getEnumLabel(x))}` : "Tender"

  return (
    <WeaverIonPage id='PaymentPlanPickerPage' disableDirectChildStructureChecks={true}>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader pageTitle={leadTitle} navElement={<NavBackButton />} />
      </WeaverIonHeader>
      <_PlanPickerPPLTest {...props} />
    </WeaverIonPage>
  )
}

export default PaymentPlanPickerPage
