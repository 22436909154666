import React from "react"
import { IonButton, IonIcon, IonToolbar, useIonRouter } from "@ionic/react"
import  { GenericSelectorOption } from "../../../../common/components/GenericSelectorList"
import ListBudgetRanges, { ListBudgetRangeOption } from "./ListBudgetRanges"
import { budgetRangeLabels } from "./budgetRanges.i18n"
import { arrowBackOutline, lockClosed, lockOpen } from 'ionicons/icons'
import { createValidCssClassname } from "../../../../common/utils/string"
import { convertToLockedBudgetRangeStatuses, useMyContractorProfile } from "./datasource"
import { BudgetRange, ContractorProfileBudgetRangeBadge, ProgressionStatus } from "../../../../graphql/generated"
import { objectEntries } from "../../../../common/utils/objects"
import Styles from "./ContractorBudgetRanges.module.scss"
import { progressionStatusSubtextLabel, progressionStatusSubtextLabel_v1 } from "./progressionStatus.i18n"
import WeaverIonPage from "../../../../common/components/WeaverIonWrappers/WeaverIonPage"
import WeaverIonContent from "../../../../common/components/WeaverIonWrappers/WeaverIonContent"
import WeaverIonFooter from "../../../../common/components/WeaverIonWrappers/WeaverIonFooter"
import { BadgesRow } from "./UnlockContractorBudgetRange/BadgesRow"
import { useRouteToUnlockBudgetRange } from "./useRouteToUnlockBudgetRange"
import { z } from 'zod'
import { useSearchState } from "../../../../common/hooks/pages"
import { pageConfig_Profile, pageConfig_Profile_ContractorLeadPreferences, useRouteTo } from "../../../../routes"
import { useWeaverFlags } from "../../../../api/thirdParty/launchDarkly/useWeaverFlags"

type IonIconPropsIcon = Parameters<typeof IonIcon>['0']['icon']
const progressionStatusIcons: Partial<Record<ProgressionStatus, IonIconPropsIcon>> = {
  [ProgressionStatus.NotStarted]: lockClosed,
  [ProgressionStatus.WaitingOnUser]: lockClosed,
  [ProgressionStatus.WaitingOnWeaver]: lockOpen,
  [ProgressionStatus.WaitingOnUser]: lockClosed,
}

// Local Helpers
const getLockedBudgetRangeGenericSelectorOptions = (lockedBudgetRangeStatuses: Record<BudgetRange, ProgressionStatus>, filterTo?: ProgressionStatus[], budgetRangeBadgeData?: Partial<ContractorProfileBudgetRangeBadge>[] | undefined | null): GenericSelectorOption<ListBudgetRangeOption>[] =>
  objectEntries(lockedBudgetRangeStatuses)
    .filter(([ , lockedBudgetRangeStatus ]) => filterTo === undefined || filterTo.includes(lockedBudgetRangeStatus))
    .map(([ budgetRange, lockedBudgetRangeStatus ]) => ({
      key: budgetRange,
      value: {
        budgetRange,
        lockedBudgetRangeStatus,
        budgetRangeBadgeData,
      },
      itemClassName: Styles[createValidCssClassname(lockedBudgetRangeStatus)],
    }))

const GenericSelectorListRendererBudgetRange: React.FC<GenericSelectorOption<ListBudgetRangeOption>> = ({ value: { budgetRange, lockedBudgetRangeStatus, budgetRangeBadgeData } }) => {
  const { "MW-2604-copy-change-lead-to-tender": leadToTenderFlag } = useWeaverFlags()

  const progressionStatusSubtextLabel_version = leadToTenderFlag ? progressionStatusSubtextLabel_v1 : progressionStatusSubtextLabel

  return <>
    <p>
      {budgetRangeLabels[budgetRange]}
      <IonIcon icon={progressionStatusIcons[lockedBudgetRangeStatus]} />
    </p>
    <div>{progressionStatusSubtextLabel_version[lockedBudgetRangeStatus]}</div>
    <BadgesRow budgetRange={budgetRange} budgetRangeBadgeData={budgetRangeBadgeData} />
  </>
}

const zSearchSchema = z.object({
  referrer: z.union([ z.literal("budgetRangeLockedScreen"), z.literal("unlockBudgetRangeScreen") ]).optional(),
})

const ContractorBudgetRangesPage: React.FC = () => {
  const router = useIonRouter()
  const weaverFlags = useWeaverFlags()
  const { searchData } = useSearchState({}, { schema: zSearchSchema })
  const goToContractorLeadPreferences = useRouteTo(pageConfig_Profile_ContractorLeadPreferences.path)
  const myContractorProfile = useMyContractorProfile()
  const lockedBudgetRangeStatuses = convertToLockedBudgetRangeStatuses(myContractorProfile.getContractorProfileQuery.data?.getContractorProfile?.workHistory)
  const budgetRangeBadgeData = myContractorProfile.getContractorProfileQuery.data?.getContractorProfile?.budgetRangeBadges
  const routeToUnlockBudgetRange = useRouteToUnlockBudgetRange('ContractorBudgetRangesPage')
  const routeToProfile = useRouteTo(pageConfig_Profile.path)

  const selectBudgetRange = (option?: ListBudgetRangeOption) => {
    const budgetRange = option?.budgetRange
    budgetRange && routeToUnlockBudgetRange(budgetRange)()
  }

  const handleGoBack = () => {
    if (searchData.referrer === 'budgetRangeLockedScreen') {
      goToContractorLeadPreferences({})()
      return
    }

    if (searchData.referrer === 'unlockBudgetRangeScreen') {
      routeToProfile({})()
      return
    }
    router.goBack()
  }

  return (
    <WeaverIonPage id="ContractorBudgetRangesPage">
      <WeaverIonContent className={`ion-padding ${Styles.content}`} >
        <h3 className="ion-padding-start">Pick a budget range to unlock {weaverFlags['MW-2604-copy-change-lead-to-tender'] ? 'tenders' : 'leads'}</h3>
        <ListBudgetRanges
          heading="Unlocked Budget Ranges"
          options={getLockedBudgetRangeGenericSelectorOptions(lockedBudgetRangeStatuses, [ ProgressionStatus.Completed ], budgetRangeBadgeData)}
          onSelect={selectBudgetRange}
          render={GenericSelectorListRendererBudgetRange}
        />
        <ListBudgetRanges
          heading="Locked Budget Ranges"
          options={getLockedBudgetRangeGenericSelectorOptions(lockedBudgetRangeStatuses, [ ProgressionStatus.WaitingOnUser, ProgressionStatus.WaitingOnWeaver, ProgressionStatus.NotStarted ], budgetRangeBadgeData)}
          onSelect={selectBudgetRange}
          render={GenericSelectorListRendererBudgetRange}
        />
      </WeaverIonContent>
      <WeaverIonFooter className="ion-no-border ion-padding">
        <IonToolbar className={Styles.ionToolbar}>
          <IonButton color="secondary" slot="start" onClick={handleGoBack}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
        </IonToolbar>
      </WeaverIonFooter>
    </WeaverIonPage>
  )
}

export default ContractorBudgetRangesPage
