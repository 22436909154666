import { ShowProjectQuery, TeamType } from "../../../../graphql/generated"
import { ShowProject } from "../../../projects/ShowProjectTypes"

type ChatRoomOptionsProject = NonNullable<ShowProjectQuery["getProject"]>
type ChatRoomOptionsProjectMember = NonNullable<ChatRoomOptionsProject["members"][number]>
type ChatRoomOptionsProjectMemberInvite = NonNullable<ChatRoomOptionsProject["memberInvites"][number]>

const INVITATION_DISCLAIMERS = {
  HOMEOWNER_HAS_BEEN_INVITED: "Homeowner has been invited. Once they login, group chat will become available.",
  ARCHITECT_HAS_BEEN_INVITED: "Architect has been invited. Once they login, group chat will become available.",
  INVITE_A_HOMEOWNER: "Invite a homeowner to the project to start group chat.",
  INVITE_A_ARCHITECT: "Invite a architect to the project to start group chat.",
  ASK_ARCHITECT_TO_INVITE_HOMEOWNER: "To start a group chat, ask the architect to invite a homeowner to the project",
  ASK_HOMEOWNER_TO_INVITE_ARCHITECT: "To start a group chat, ask the homeowner to invite an architect to the project",
}

const isTeamTypeAMember = (teamType: TeamType, projectMembers: ChatRoomOptionsProjectMember[]) => {
  const doesTeamTypeExistsInMembers = projectMembers.find(member => member.team.type === teamType) !== undefined
  return doesTeamTypeExistsInMembers
}

const isTeamTypeInvited = (teamType: TeamType, projectMembersInvites: ChatRoomOptionsProjectMemberInvite[]) => {
  const doesTeamTypeExistsInInvitedMembers = projectMembersInvites.find(member => member.requiredTeamType === teamType) !== undefined
  return doesTeamTypeExistsInInvitedMembers
}

export const isEverybodyAMember = (project: ChatRoomOptionsProject) => {
  return isTeamTypeAMember(TeamType.Architect, project.members) && isTeamTypeAMember(TeamType.Homeowner, project.members) && isTeamTypeAMember(TeamType.Contractor, project.members)
}

/**
 * Returns the invitation disclaimer to de displayed in the Chat Room Options page depending on the current user's role (myTeamType), the team's type the
 * user initially selected to chat in the Project page (teamToChatType) and the membership properties of the project (who's a member and who's been invited.)
 * @param project The project that contains who's a member and who's been invited.
 * @param myTeamType The current user's role (myTeamType)
 * @param teamToChatType The type of the team the current user clicked on in the Project page Member's chat section
 */
export const getInvitationDisclaimer = (project: ChatRoomOptionsProject, myTeamType: string, teamToChatType: string): string | undefined => {
  const amIAnArchitect = myTeamType === TeamType.Architect
  const amIAContractor = myTeamType === TeamType.Contractor
  const amIAHomeowner = myTeamType === TeamType.Homeowner

  const doIWantToSpeakToAnArchitect = teamToChatType === TeamType.Architect
  const doIWantToSpeakToAHomeowner = teamToChatType === TeamType.Homeowner

  if (isEverybodyAMember(project)) return undefined

  if (amIAnArchitect){
    if (isTeamTypeInvited(TeamType.Homeowner, project.memberInvites)){
      return INVITATION_DISCLAIMERS.HOMEOWNER_HAS_BEEN_INVITED
    } else {
      return INVITATION_DISCLAIMERS.INVITE_A_HOMEOWNER
    }
  }

  if (amIAHomeowner){
    if (isTeamTypeInvited(TeamType.Architect, project.memberInvites)){
      return INVITATION_DISCLAIMERS.ARCHITECT_HAS_BEEN_INVITED
    } else {
      return INVITATION_DISCLAIMERS.INVITE_A_ARCHITECT
    }
  }

  if (amIAContractor){
    if (doIWantToSpeakToAnArchitect){
      if (isTeamTypeInvited(TeamType.Homeowner, project.memberInvites)){
        return INVITATION_DISCLAIMERS.HOMEOWNER_HAS_BEEN_INVITED
      } else {
        return INVITATION_DISCLAIMERS.ASK_ARCHITECT_TO_INVITE_HOMEOWNER
      }
    }

    if (doIWantToSpeakToAHomeowner){
      if (isTeamTypeInvited(TeamType.Architect, project.memberInvites)){
        return INVITATION_DISCLAIMERS.ARCHITECT_HAS_BEEN_INVITED
      } else {
        return INVITATION_DISCLAIMERS.ASK_HOMEOWNER_TO_INVITE_ARCHITECT
      }
    }
  }
}

/**
 * Returns the role to invite for the Architect or the Homeowner. It should always be undefined for Contractors.
 * @param project The project that contains who's a member and who's been invited.
 * @param myTeamType The current user's role (myTeamType)
 * @param teamToChatType The type of the team the current user clicked on in the Project page Member's chat section
 */
export const getRoleToInvite = (project: ChatRoomOptionsProject, myTeamType: string, teamTypeToChat: string): TeamType | undefined => {
  const amIAnArchitect = myTeamType === TeamType.Architect
  const amIAHomeowner = myTeamType === TeamType.Homeowner

  const doIWantToSpeakToAContractor = teamTypeToChat === TeamType.Contractor

  if (amIAnArchitect && doIWantToSpeakToAContractor){
    if (!isTeamTypeAMember(TeamType.Homeowner, project.members) && !isTeamTypeInvited(TeamType.Homeowner, project.memberInvites)){
      return TeamType.Homeowner
    }
  }

  if (amIAHomeowner && doIWantToSpeakToAContractor){
    if (!isTeamTypeAMember(TeamType.Architect, project.members) && !isTeamTypeInvited(TeamType.Architect, project.memberInvites)){
      return TeamType.Architect
    }
  }

  return undefined
}

/**
 * Returns the additional team type necessary to create a group chat.
 * team they both can have a group chat together.
 * @param myTeamType The current's user team type.
 * @param teamToChatType The type of the team the current user selected to have either a private chat or a group chat.
 */
export const getAdditionalTeamToCreateGroupChatRoom = (myTeamType: string, teamToChatType: string): TeamType | undefined => {
  const amIAnArchitect = myTeamType === TeamType.Architect
  const amIAHomeowner = myTeamType === TeamType.Homeowner
  const amIAContractor = myTeamType === TeamType.Contractor
  const doIWantToSpeakToAContractor = teamToChatType === TeamType.Contractor

  if (amIAnArchitect && doIWantToSpeakToAContractor) return TeamType.Homeowner

  if (amIAHomeowner && doIWantToSpeakToAContractor) return TeamType.Architect

  const doIWantToSpeakToAnArchitect = teamToChatType === TeamType.Architect
  const doIWantToSpeakToAHomeowner = teamToChatType === TeamType.Homeowner

  if (amIAContractor && doIWantToSpeakToAnArchitect) return TeamType.Homeowner

  if (amIAContractor && doIWantToSpeakToAHomeowner) return TeamType.Architect

  return
}

