import React, { useRef } from 'react'
import { IonAvatar, IonButton, IonImg, IonItem, IonItemDivider, IonLabel, IonList, IonMenu, IonIcon, IonSpinner } from "@ionic/react"
import { chatboxOutline, codeSlashOutline, helpBuoy, homeOutline, logoCodepen, peopleOutline } from 'ionicons/icons'
import { TAuth_LoggedIn, isAuthFunctional, useAuthContext } from '../../../api/providers/AuthProvider'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { TeamType, useUpdateStripePaymentDetailsMutation } from '../../../graphql/generated'
import { pageConfig_Help, pageConfig_MyChats, pageConfig_Profile, pageConfig_Profile_ContractorLeadPreferences, pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges, pageConfig_Projects } from '../../../routes'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useRedirectBaseURL } from '../../hooks/useRedirectBaseURL'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'

import Styles from './Menu.module.scss'

interface UserInfoProps {
  auth: TAuth_LoggedIn,
  onSignout: () => void,
}
const UserInfo: React.FC<UserInfoProps> = ({ auth, onSignout }) => {

  const handleSignOut = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    onSignout()
  }
  return (
    <IonItem routerLink={pageConfig_Profile.path} lines='full'>
      <IonAvatar slot="start">
        <IonImg src={auth.userData?.picture} />
      </IonAvatar>
      <IonLabel>{auth.userData?.given_name} {auth.userData?.family_name}</IonLabel>
      {isAuthFunctional(auth) && <IonButton size='small' color='danger' fill='outline' onClick={handleSignOut}>Sign out</IonButton>}
    </IonItem>
  )
}

const USER_MENU_ITEMS = {
  ADD_PAST_PROJECT: 'Add past project',
  CHANGE_TENDER_PREFERENCES: 'Change tender preferences',
  MANAGE_BILLING: 'Manage billing',
  PROJECTS: 'Projects',
  CHATS: 'Chats',
  PROFILE: 'Profile',
  HELP: 'Help',
}

const Menu: React.FC = () => {
  const auth = useAuthContext()
  const weaverFlags = useWeaverFlags()
  const myTeam = useMyIndividualActiveTeam()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const redirectUrl = useRedirectBaseURL('/')
  const updateStripePaymentDetailsMutation = useUpdateStripePaymentDetailsMutation(gqlDataSource)
  const isContractor = myTeam?.type === TeamType.Contractor
  const closeMenuRef = useRef<HTMLIonMenuElement>(null)
  const [ isGettingUrlForStripePaymentUpdate, setIsGettingUrlForStripePaymentUpdate ] = React.useState(false)

  const trigger_manageBillingClicked_event = useAnalyticsEvent('Manage_Billing_Clicked')

  const closeMenu = () => {
    closeMenuRef.current?.close()
  }

  const handleManageBillingRequest =  async () => {
    setIsGettingUrlForStripePaymentUpdate(true)
    if (!redirectUrl) return console.error('[Menu] Failed to get redirect url')
    const stripePageForUpdateDetails = await updateStripePaymentDetailsMutation.mutateAsync({ redirectUrl: redirectUrl })

    if (!stripePageForUpdateDetails.updateStripePaymentDetails.url) return console.error('[Menu] Failed to get stripe url')
    await trigger_manageBillingClicked_event({ teamId: myTeam?.id })

    setIsGettingUrlForStripePaymentUpdate(false)
    closeMenu()
    window.location.href = stripePageForUpdateDetails.updateStripePaymentDetails.url
  }

  return (
    <IonMenu ref={closeMenuRef} side="start" contentId='main'>
      <IonList>
        <IonItemDivider>
          <h5 className={Styles.menuItemHeader}>
            {weaverFlags['MW-2612-side-menu-improvements']
              ? isContractor
                ? 'Improve your profile'
                : 'My profile'
              : ' My Profile'
            }
          </h5>
        </IonItemDivider>
        <UserInfo auth={auth} onSignout={auth.logout} />
        {isContractor && weaverFlags['MW-2612-side-menu-improvements'] &&
        <IonList lines='full' className={Styles.contractorOptionList}>
          <IonItem routerLink={pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges.path} onClick={closeMenu}>
            <p className={Styles.labelBrandText}>{USER_MENU_ITEMS.ADD_PAST_PROJECT}</p>
          </IonItem>
          <IonItem routerLink={pageConfig_Profile_ContractorLeadPreferences.path} onClick={closeMenu}>
            <p className={Styles.labelBrandText}>{USER_MENU_ITEMS.CHANGE_TENDER_PREFERENCES}</p>
          </IonItem>
          {weaverFlags['MW-2613-manage-stripe-billing-link'] &&
          <IonItem button onClick={handleManageBillingRequest} disabled={isGettingUrlForStripePaymentUpdate}>
            <p className={Styles.labelBrandText}>{USER_MENU_ITEMS.MANAGE_BILLING}</p>
            {isGettingUrlForStripePaymentUpdate && <IonSpinner name="crescent" slot='end'></IonSpinner>}
          </IonItem>
          }
        </IonList>
        }

        {!isContractor && weaverFlags['MW-2613-manage-stripe-billing-link'] &&
          <IonItem lines='full' button onClick={handleManageBillingRequest} disabled={isGettingUrlForStripePaymentUpdate}>
            <p className={Styles.labelBrandText}>{USER_MENU_ITEMS.MANAGE_BILLING}</p>
            {isGettingUrlForStripePaymentUpdate && <IonSpinner name="crescent" slot='end'></IonSpinner>}
          </IonItem>
        }

        {weaverFlags['MW-2612-side-menu-improvements'] &&
        <IonList lines='full'>
          <IonItemDivider>
            <h5 className={Styles.menuItemHeader}>
              Navigation
            </h5>
          </IonItemDivider>
          <IonItem routerLink={pageConfig_Projects.path} onClick={closeMenu}>
            <IonIcon slot='start'  icon={homeOutline} />
            <p className={Styles.labelBrandText}>{USER_MENU_ITEMS.PROJECTS}</p>
          </IonItem>
          <IonItem routerLink={pageConfig_MyChats.path} onClick={closeMenu}>
            <IonIcon slot='start'  icon={chatboxOutline} />
            <p className={Styles.labelBrandText}>{USER_MENU_ITEMS.CHATS}</p>
          </IonItem>
          <IonItem routerLink={pageConfig_Profile.path} onClick={closeMenu}>
            <IonIcon slot='start'  icon={peopleOutline} />
            <p className={Styles.labelBrandText}>{USER_MENU_ITEMS.PROFILE}</p>
          </IonItem>
          <IonItem routerLink={pageConfig_Help.path} onClick={closeMenu}>
            <IonIcon slot='start'  icon={helpBuoy} />
            <p className={Styles.labelBrandText}>{USER_MENU_ITEMS.HELP}</p>
          </IonItem>
        </IonList>
        }

        {weaverFlags['miscellaneous-menu'].enabled && (
          <IonList lines="none">
            <IonItemDivider>
              <IonLabel>
                Miscellaneous
              </IonLabel>
            </IonItemDivider>
            <IonItem routerLink={'dev-settings'}>
              <IonIcon slot="start" icon={codeSlashOutline} />
              <IonLabel>{'Developer Settings'}</IonLabel>
            </IonItem>
            <IonItem routerLink={'tech-demo'}>
              <IonIcon slot="start" icon={logoCodepen} />
              <IonLabel>{'Tech Demo'}</IonLabel>
            </IonItem>
          </IonList>
        )}
      </IonList>
    </IonMenu>
  )
}

export default Menu
