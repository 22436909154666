import React from 'react'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import TeamMembers from '../TeamMembers'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useGetArchitectProfileQuery } from '../../../graphql/generated'
import ProfileHeader from '../ProfileHeader'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'
import { MyArchitectProfileDetailsCard } from './MyArchtiectProfileDetailsCard'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { ArchitectTimelineCard } from './ArchitectTimelineCard'
import { pageConfig_Profile_Edit, useRouteTo } from '../../../routes'
import Styles from './MyArchitectProfile.module.scss'

const MyArchitectProfilePage: React.FC = () => {
  const myTeam = useMyIndividualActiveTeam()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data , error, isFetching, refetch } = useGetArchitectProfileQuery(
    gqlDataSource,
    { architectTeamId: myTeam?.id ?? '' },
    {
      staleTime: 5 * 60 * 1000,
      enabled: myTeam !== undefined,
    },
  )
  const routeToEditMyProfile = useRouteTo(pageConfig_Profile_Edit.path)
  const goToEditMyProfile = routeToEditMyProfile({})

  if (myTeam === undefined || isFetching) {
    return <LoadingSpinnerPage name="MyArchitectProfilePage" />
  }

  if (error) {
    console.error(`[MyArchitectProfilePage] Failed to load Architect Profile: `, { myTeam, data, error })
    return <ErrorBlockPage name='MyArchitectProfilePage' onRefresh={refetch} />
  }

  if (data?.getArchitectProfile == null) {
    console.warn(`[MyArchitectProfilePage] Architect Profile is missing: `, { myTeam, data, error })
    return <ErrorBlockPage name='MyArchitectProfilePage'><p>Unable to find the Architect Profile.</p></ErrorBlockPage>
  }

  const profile = data.getArchitectProfile
  const isSoleTrader = !profile?.companyNumber

  return (
    <WeaverIonPage id='MyArchitectProfilePage'>
      <WeaverIonHeader>
        <ProfileHeader isMyTeam={true} />
      </WeaverIonHeader>
      <WeaverIonContent className={Styles.displayArchitectProfileContainer}>
        <MyArchitectProfileDetailsCard architectProfile={profile} goToEditMyProfile={goToEditMyProfile} />
        {!isSoleTrader && <ArchitectTimelineCard architectProfile={profile} />}
        <div className='ion-padding-start'>
          <TeamMembers teamId={myTeam.id} />
        </div>
      </WeaverIonContent>
    </WeaverIonPage>
  )
}

export default MyArchitectProfilePage
