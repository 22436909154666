import React, { useEffect } from 'react'
import ChatFooter from './footer/ChatFooter'
import ChatContentWithNewMessagesLines from './ChatContent'
import GlobalHeaderStyles from '../../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { useChatRoom } from './services/ChatRoomProvider'
import WeaverIonHeader from '../../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import { IonButton, IonButtons, IonContent, IonFooter, IonIcon, IonItem, IonLabel, IonList, IonModal, IonPopover, IonSpinner, IonTitle, IonToolbar, useIonAlert, useIonRouter } from '@ionic/react'
import { arrowBackOutline, ellipsisVertical, callOutline } from 'ionicons/icons'
import { useWeaverFlags } from '../../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { pageConfig_ChatRoomMembers, useRouteTo } from '../../../../routes'
import ImageLightbox from './ImageLightbox'
import useImageLightbox from './services/useImageLightbox'
import { DateTime } from 'luxon'
import { usePageTitleOnEnter } from '../../../../common/hooks/usePageTitle'
import { useAutoQualifyChatroom } from './services/useAutoQualifyChatroom'
import { TeamType, useCreateAudioCallMutation, useGetAllIndividualsWithTeamTypeInChatRoomQuery, useGetChatRoomTeamConnectionsQuery, useGetMyIndividualPhoneNumnberQuery, useSendChatRoomMessageMutation, useSetIndividualPhoneNumberMutation } from '../../../../graphql/generated'
import { useGraphQLDataSource } from '../../../../api/graphql'
import { ProjectMemberTeam } from '../../../projects/ShowProjectTypes'
import { useMyIndividual, useMyIndividualActiveTeam } from '../../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { zodResolver } from '@hookform/resolvers/zod'
import SingleClickButton from '../../../../common/components/SingleClickButton'
import { WeaverIdTokenClaims, useAuthContext } from '../../../../api/providers/AuthProvider'
import { teamTypeLabels } from '../../../../common/components/InviteMemberModal/inviteMemberTeamTypeLabels.i18n'
import { v4 as uuid } from "uuid"
import { useAnalyticsBrowserContext, useAnalyticsEvent } from '../../../../api/providers/SegmentProvider/hooks'
import { useParamsFromPageConfig } from '../../../../routesProvider'
import { ReactHookFormMobileNumberCapture } from '../../../../common/components/MobileNumberCapture/ReactHookFormMobileCatpure'
import MobileNumberCapture from '../../../../common/components/MobileNumberCapture'

import Styles from './Chat.module.scss'

type ChatRoomProps = {
  goToFilePickerScreen: () => void,
  goToVideoChat: () => void,
}

type CallableIndividual = {
  teamName?: string | null,
  teamId?: string,
  teamType?: TeamType,
  individualId?: string,
  givenName?: string,
  familyName?: string,
}

type CallUserClickedState = {
  isLoading: boolean,
  individualId: string | undefined,
};

const zFormSchema = z.object({
  phoneNumber: z.string(),
}).superRefine((validated, ctx) => {
  if (!isPossiblePhoneNumber(validated.phoneNumber)) {
    ctx.addIssue({
      path: [ "phoneNumber" ],
      code: z.ZodIssueCode.custom,
      message: "A valid Phone Number is required",
    })
  }
})

type PhoneNumberFormSchema = z.infer<typeof zFormSchema>

const ChatRoom: React.FC<ChatRoomProps> = ({ goToFilePickerScreen, goToVideoChat }) => {
  const { chatRoomName, chatRoomId, projectId } = useChatRoom()
  const weaverFlags = useWeaverFlags()
  const ionRouter = useIonRouter()
  const myTeam = useMyIndividualActiveTeam()
  const myIndvidual = useMyIndividual()
  const analytics = useAnalyticsBrowserContext()
  const myIndividualPhoneNumberResponse = useGetMyIndividualPhoneNumnberQuery(useGraphQLDataSource({ api: 'core' }))
  const auth = useAuthContext()
  const [ present ] = useIonAlert()
  const lastUserCalledIndividualIdRef = React.useRef<string | undefined>(undefined)

  const chatCallMenuClickedEvent = useAnalyticsEvent('Chat_Call_Menu_Clicked')
  const chatCallMenuUpdateNumberClickedEvent = useAnalyticsEvent('Chat_Call_Menu_Update_Number_Clicked')
  const chatCallMenuUpdateNumberSubmittedEvent = useAnalyticsEvent('Chat_Call_Menu_Update_Number_Submitted')
  const chatCallInitiatedEvent = useAnalyticsEvent('Chat_Call_Initiated')
  const trigger_Chat_Menu_Clicked = useAnalyticsEvent("Chat_Menu_Clicked")
  const trigger_Chat_Viewed = useAnalyticsEvent('Chat_Viewed')
  const trigger_Chat_Call_Fail = useAnalyticsEvent('Chat_Call_Failed')
  const { chatRoomId: id } = useParamsFromPageConfig<{chatRoomId: string}>()

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const resultGetChatRoomConnectionsQuery = useGetChatRoomTeamConnectionsQuery(gqlDataSource, { chatRoomId } )
  const resultGetChatRoomIndividualsWithTeam = useGetAllIndividualsWithTeamTypeInChatRoomQuery(gqlDataSource, { chatRoomId })
  const sendChatRoomMessage = useSendChatRoomMessageMutation(gqlDataSource)
  const createAudioCall = useCreateAudioCallMutation(gqlDataSource, {
    onSuccess: async (res) => {
      setCallUserClicked({ isLoading: false, individualId: '' })
      await sendChatRoomMessage.mutateAsync({
        message: {
          chatRoomId: chatRoomId,
          content: `📞 ${myIndvidual.givenName} called ${res.createAudioCall.callRecipientGivenName}`,
          idFromClient: uuid(),
          sentAt: new Date().toISOString(),
        },
      })
    },
    onError: async (error: Error) => {
      console.error('[ChatRoom] Error creating audio call: ', error.message)
      if (error.message === 'Call recipient has incorrect phone number.'){
        const userCalled = resultGetChatRoomIndividualsWithTeam.data?.getAllIndividualsWithTeamTypeInChatRoom.find(individual => individual.individualId === lastUserCalledIndividualIdRef.current)
        await trigger_Chat_Call_Fail({
          projectId: projectId ?? '',
          chatRoomId: chatRoomId,
          teamName: myTeam?.name ?? undefined,
          callRecipientIndividualId: callUserClicked.individualId,
          callRecipientTeamId: userCalled?.teamId ,
          callRecipientTeamType: userCalled?.teamType,
          callRecipientTeamName: userCalled?.teamName ?? undefined,
        })
        setCallUserClicked({ isLoading: false, individualId: '' })
        lastUserCalledIndividualIdRef.current = undefined
      }
      present({
        header: 'There was an error connecting your call:',
        message: error.message,
        buttons: [ 'OK' ],
      })
    },
  })

  const setIndividualPhoneNumberMutation  = useSetIndividualPhoneNumberMutation(gqlDataSource, {
    onSuccess: async (data, variables) => {
      await analytics.identify(myIndvidual.id, { phone: variables.phoneNumber }) // update Segment/C.io
      setUpdatePhoneNumberModalOpen(false)
      present({
        header: 'Your phone number has been updated.',
        buttons: [ 'OK' ],
      })
    },
    onError: (error) => {
      console.error('[ChatRoom] Error updating the individual phone number: ', error)
      setUpdatePhoneNumberModalOpen(false)
      present({
        header: 'There was an error updating your phone number.',
        buttons: [ 'OK' ],
      })
    },
  })

  const [ updatePhoneNumberModalOpen, setUpdatePhoneNumberModalOpen ] = React.useState(false)
  const [ callMemberListPopoverOpen, setCallMemberListPopoverOpen ] = React.useState(false)
  const [ callUserClicked, setCallUserClicked ] = React.useState<CallUserClickedState>({ isLoading: false, individualId: undefined })

  // PhoneNumber Form Setup
  const form = useForm<PhoneNumberFormSchema>({
    defaultValues: {
      phoneNumber: myIndividualPhoneNumberResponse.data?.getMyIndividualPhoneNumber.phoneNumber ?? '',
    },
    resolver: zodResolver(zFormSchema),
  })

  useEffect(() => {
    form.setValue('phoneNumber', myIndividualPhoneNumberResponse.data?.getMyIndividualPhoneNumber.phoneNumber ?? '')
  }, [ myIndividualPhoneNumberResponse.data?.getMyIndividualPhoneNumber.phoneNumber ])

  const shouldHideTeam = (team: ProjectMemberTeam) => {
    const hidePartnerFromContractor = myTeam?.type === TeamType.Contractor && team.type === TeamType.Partner
    const hideMyTeam = myTeam?.id === team.id
    return [ hidePartnerFromContractor, hideMyTeam ].some(Boolean) // add more conditions here
  }

  const individualsWithTeam = resultGetChatRoomIndividualsWithTeam.data?.getAllIndividualsWithTeamTypeInChatRoom

  const teamConnections = resultGetChatRoomConnectionsQuery.data?.getChatRoom?.teamConnections
  const visibleTeams = teamConnections?.filter(teamConnection => !shouldHideTeam(teamConnection.team)).map(teamConnection => {

    const individualTeam = individualsWithTeam?.find(individual => individual.teamId === teamConnection.team.id)

    return {
      teamName: individualTeam?.teamName,
      teamId: individualTeam?.teamId,
      teamType: individualTeam?.teamType,
      individualId: individualTeam?.individualId,
      givenName: individualTeam?.givenName ?? '',
      familyName: individualTeam?.familyName ?? '',
    }
  }) || []

  const goToChatRoomMembersPage = useRouteTo(pageConfig_ChatRoomMembers.path)
  const title = chatRoomName || 'My Chat'
  usePageTitleOnEnter(title)

  const {
    lightboxToggler,
    selectedUrl,
    images,
    openLightbox,
    addImageToLightbox,
  } = useImageLightbox(true)

  const goBack = () => ionRouter.goBack()

  useAutoQualifyChatroom()

  const routeRegex = /chats\/([^/]+)/

  useEffect(() => {
    if (ionRouter.routeInfo.pathname?.match(routeRegex) && id === chatRoomId)
      trigger_Chat_Viewed({
        projectId: projectId ?? '',
        chatRoomId: chatRoomId,
        individualId: myIndvidual.id ,
      })
  }, [ ionRouter.routeInfo.pathname ])

  const navigateToChatRoomMembersPage = async (chatRoomId: string) => {
    await trigger_Chat_Menu_Clicked({
      projectId: projectId ?? '',
      chatRoomId: chatRoomId,
      teamName: myTeam?.name,
    })
    goToChatRoomMembersPage({ chatRoomId })()
  }

  const callMember = async (team : CallableIndividual) => {
    const phoneNumber = auth.userData?.[WeaverIdTokenClaims.WeaverPhoneNumber]

    if (!phoneNumber){
      setCallUserClicked({ isLoading: false , individualId: undefined })
      return console.error('[ChatRoom] Error calling member: No phone number found in the user data.')
    }
    if (!team.teamName || !team.individualId || !team.givenName){
      setCallUserClicked({ isLoading: false , individualId: undefined })
      return console.error('[ChatRoom] Error calling member: Missing individual data.')
    }

    await createAudioCall.mutateAsync({ individualId: team.individualId, callRecipientGivenName: team.givenName, callRecipientTeamName: team.teamName, phoneNumber: phoneNumber, chatRoomId: chatRoomId })
    chatCallInitiatedEvent({ chatRoomId, projectId: projectId ?? '', teamName: myTeam?.name, callRecipientTeamId: team.teamId, callRecipientTeamType: team.teamType, callRecipientIndividualId: team.individualId })
    setCallMemberListPopoverOpen(false)
  }

  const sumbitPhoneNumber = async (data: PhoneNumberFormSchema) => {
    // This sets the Phone Number in Auth0, making it available in the tokens the next time they're generated
    console.debug('[RequirePhoneNumber.onSubmit] Setting the individual phone number: ', { data })
    await setIndividualPhoneNumberMutation.mutateAsync(data)
    chatCallMenuUpdateNumberSubmittedEvent({ chatRoomId, projectId: projectId?? '', teamName: myTeam?.name })
    setCallMemberListPopoverOpen(false)
  }

  return (
    <>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={goBack}>
              <IonIcon icon={arrowBackOutline}/>
            </IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>

          <IonButtons slot="end" >
            {weaverFlags['MW-2580-twilio-call-from-chatroom'] &&
            /** id allows the popover element to be positioned correctly in relation to element that controls action */
            <IonButton id='call-member-popover' onClick={() => {setCallMemberListPopoverOpen(true), chatCallMenuClickedEvent({ chatRoomId, projectId: projectId ?? '' })}}>
              <IonIcon icon={callOutline}/>
            </IonButton>
            }

            <IonPopover arrow={true} className={Styles.numberUpdatePopover} trigger='call-member-popover' side="bottom" alignment="end" isOpen={callMemberListPopoverOpen} onWillDismiss={() => setCallMemberListPopoverOpen(false)}>
              <IonContent >
                <IonList lines='full' className={Styles.memberCallList}>
                  {visibleTeams.map(team => (
                    <IonItem button disabled={callUserClicked.isLoading} onClick={() => {callMember(team), setCallUserClicked({ isLoading: true, individualId: team.individualId }), lastUserCalledIndividualIdRef.current = team.individualId}} key={team.individualId} detail={false} >
                      <IonLabel>
                        <h2>Call {team.givenName} from {team.teamName}</h2>
                        {team.teamType && <p>{teamTypeLabels[team.teamType]}</p>}
                      </IonLabel>
                      {callUserClicked.isLoading && team.individualId === callUserClicked.individualId ? <IonSpinner className={Styles.spinner} name="crescent" slot='start' /> : <IonIcon color='primary' slot='start' icon={callOutline}/>}
                    </IonItem>
                  ))}
                  <IonItem button onClick={() => {setUpdatePhoneNumberModalOpen(true), chatCallMenuUpdateNumberClickedEvent({ chatRoomId, projectId: projectId?? '', teamName: myTeam?.name })}} detail={false} >
                    <IonLabel>Update my phone number</IonLabel>
                  </IonItem>
                </IonList>
                <IonModal isOpen={updatePhoneNumberModalOpen} onWillDismiss={() => {setUpdatePhoneNumberModalOpen(false)}}>
                  <IonContent class="ion-padding">
                    <Controller
                      control={form.control}
                      name="phoneNumber"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        weaverFlags['MW-2628-phone-number-chat-update']
                          ? <MobileNumberCapture
                            textFieldPhoneNumberTitle={'Update your phone number'}
                            textHeading={'Phone number'}
                            value={value}
                            onChange={onChange}
                            error={error}
                            errorMessage={'A valid Phone Number is required.'} />
                          : <ReactHookFormMobileNumberCapture
                            name='phoneNumber'
                            control={form.control}
                            textFieldPhoneNumberTitle={'Update your phone number'}
                            textHeading={'Phone number'}
                            value={value}
                            onChange={onChange}
                            error={error}
                            errorMessage={'A valid Phone Number is required.'}
                          />
                      )}
                    />
                    <div className={Styles.updatePhoneNumberButtonsContainer}>
                      <IonButton className={Styles.updatePhoneNumberButton} fill='outline' onClick={() => setUpdatePhoneNumberModalOpen(false)}>
                        Cancel
                      </IonButton>
                      <SingleClickButton className={Styles.updatePhoneNumberButton} onClick={form.handleSubmit(sumbitPhoneNumber)}>
                        Save
                      </SingleClickButton>
                    </div>
                  </ IonContent>
                </IonModal>
              </IonContent>
            </IonPopover>

            <IonButton onClick={() => navigateToChatRoomMembersPage(chatRoomId)} >
              <IonIcon icon={ellipsisVertical}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </WeaverIonHeader>

      {weaverFlags['MW-2144-integrate-video-chat'] && (
        <IonFooter className='ion-no-border ion-padding'>
          <IonButton expand='block' onClick={goToVideoChat}>Go to video chat</IonButton>
        </IonFooter>
      )}

      <ChatContentWithNewMessagesLines openLightbox={openLightbox} addImageToLightbox={addImageToLightbox} />

      <ImageLightbox
        componentKey={selectedUrl}
        toggler={lightboxToggler}
        imageUrls={images.sort((a,b) => DateTime.fromISO(a.updatedAt).toMillis() - DateTime.fromISO(b.updatedAt).toMillis()).map(r => r.url)}
        selectedImageUrl={selectedUrl}
      />

      <ChatFooter goToFilePickerScreen={goToFilePickerScreen} />
    </>
  )
}

export default ChatRoom
