import { IonCard, IonItem, IonIcon, IonModal, IonToolbar, IonTitle, useIonAlert, IonAccordionGroup, IonAccordion, IonButton, IonText } from '@ionic/react'
import { alertCircleOutline, arrowBack, checkmarkCircle, closeOutline } from 'ionicons/icons'
import { Duration } from 'luxon'
import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useGraphQLDataSource } from '../../../../api/graphql'
import { useMyIndividualActiveTeam } from '../../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { useWeaverFlags } from '../../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { ProjectMemberRole, ShowProjectQuery, TaskActionableType, TaskStatus, TeamType, UploadedFileKind, UploadedFileStatus, useSetTaskStatusMutation, useShowProjectQuery, useUpdatePublishedProjectMutation } from '../../../../graphql/generated'
import { alwaysArray, uniqueFilter } from '../../../../common/utils'
import GoogleMapFromWeaverAddress from '../../../../common/components/GoogleMapFromWeaverAddress.tsx/GoogleMapFromWeaverAddress'
import { flexibleBudgetTextToSignificantFigures } from '../../../../common/utils/budgetTransformation'
import { getWorkStartEstimateLabels } from '../../../projects/workEstimate.i18n'
import { teamTypeLabels } from '../../../../common/components/InviteMemberModal/inviteMemberTeamTypeLabels.i18n'
import { getFlexibleProjectBudgetAsMoneyOrMoneyRange } from '../../../projects/common'
import { leadDocumentsKindTypeLabels } from '../../../projects/leadDocumentsKindTypeLabels.i18n'
import { ScreenComponentProps } from '../TaskActionerPage'
import WeaverIonHeader from '../../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import GlobalHeader from '../../../../common/components/GlobalHeader'
import WeaverIonContent from '../../../../common/components/WeaverIonWrappers/WeaverIonContent'
import GlobalHeaderStyles from '../../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import LoadingSpinnerContent from '../../../../common/components/LoadingSpinner/LoadingSpinnerContent'
import Styles from "./ScreenStartMatching.module.scss"
import EditIcon from './edit.svg'
import { ScreenStartMatchingContext } from './common'
import EditWorkStartEstimateModal from './EditWorkStartEstimateModal'
import EditProjectTypeModal from './EditProjectTypeModal'
import EditBudgetModal from './EditBudgetModal'
import EditDescriptionModal from './EditDescriptionModal'
import { TENDER_AND_PROJECT_PROPERTIES } from '../../../projects/projectAndTender.i18n'
import { projectTypeLabels } from '../../../onboarding/team/onboarding.i18n'
import { finishLabels, specialtiesLabels } from '../../../projects/projectTaskLabels.i18n'
import { propertyTypeLabels } from '../../../projects/commonProjectLabels.i18n'
import { useGetMyProjectTasks } from '../../../projects/checklist/useGetMyProjectTasks'
import EditPropertyTypeModal from './EditPropertyTypeModal'
import EditSpecialitiesModal from './EditSpecialtiesModal'
import { useMarkTaskCompleteMutation } from '../commonActions'
import { useAnalyticsEvent } from '../../../../api/providers/SegmentProvider/hooks'
import SingleClickButton from '../../../../common/components/SingleClickButton'
import EditFinishModal from './EditFinishModal'
import StartMatchingPaymentModal from './StartMatchingPaymentModal'
import { ExpandedProjectDescription } from '../../../projects/ExpandedProjectDescription'

const TENDER_PACK_IN_PROGRESS_HEADER = "Tender pack in progress"
const TENDER_PACK_IN_PROGRESS_CONTENT = [ "Join an early stage project, offering the unique opportunity to collaborate with the client/architect and shape the tender pack.", "Protected by Weaver Satisfaction Money-Back Guarantee. If for any reason you are not satisfied with your experience, we will refund you in full." ]

const ScreenStartMatching: React.FC<ScreenComponentProps> = ({ getActionableTaskQuery }) => {
  const weaverFlags = useWeaverFlags()
  const queryClient = useQueryClient()
  const [ editState, setEditState ] = useState<"projectType"|"budget"|"workStartEstimate"|"description"| "propertyType" | "specialties" | "finish" | undefined>(undefined)
  const [ startMatchingPaymentPickerModalState, setStartMatchingPaymentPickerModalState ] = useState(false)
  const [ present ] = useIonAlert()
  const myTeam = useMyIndividualActiveTeam()
  const nudgeSentRef = React.useRef(false)
  const markTaskAsCompleted = useMarkTaskCompleteMutation(getActionableTaskQuery)
  const triggerBudgetTaskChange = useAnalyticsEvent("Task_Status_Changed")

  const task = getActionableTaskQuery.data?.getTask
  const id = task?.projectId
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const showProjectQuery = useShowProjectQuery(gqlDataSource, { id: id || "" }, { refetchOnWindowFocus: false, retry: false, enabled: !!id, staleTime: Duration.fromObject({ minutes: 1 }).toMillis(), select: ({ getProject }) => {
    if (!getProject) throw new Error("Project not found")
    return { getProject }
  },
  })
  const markTaskStatusAsCompleted = useSetTaskStatusMutation(gqlDataSource)
  const updateProject = useUpdatePublishedProjectMutation(gqlDataSource, { onSuccess: async (data, variables) => {
    queryClient.setQueryData<ShowProjectQuery>([ "showProject", { id: variables.input.id } ], (oldData) => {
      if (!oldData) return oldData
      return {
        ...oldData,
        getProject: oldData.getProject ? {
          ...oldData.getProject,
          ...(data.updatePublishedProject || {}),
        } : oldData.getProject,
      }
    })

    /**   03/07/2023
       *   -- V1 --
       *  In the unlikely case a user has a confirmBudget task but has not completed it yet, we want to mark it here as completed on success.
       *
       *  1. A user at this point WILL have submitted a valid budget.
       *  2. We don't want a user to be able to go back and change the budget again after this point through the confirmBudgetTask.
       *  3. This includes even if they do not click confirm on the StartMatching screen.
       *  4. We will inform ops of the change in budget through the event as agreed to by product for this V1 iteration.
       *  5. We will not be triggering the success toast for budgetConfirm.
       **/

    if (variables.input.budgetValue && maybeBudgetConfirmationTask?.id && id) {

      // We only want to run the mutation once, but the below event is to be triggered on every budget change.
      if (maybeBudgetConfirmationTask.status !== TaskStatus.Completed) {
        await markTaskStatusAsCompleted.mutateAsync({ taskId: maybeBudgetConfirmationTask.id, newStatus: TaskStatus.Completed })
      }

      await triggerBudgetTaskChange({
        projectId: id,
        taskId: variables.input.id,
        taskTitle: getActionableTaskQuery.data?.getTask?.title ?? '',
        taskDueAt: getActionableTaskQuery.data?.getTask?.dueAt,
        taskStatus: TaskStatus.Completed,
        taskPreviousStatus: variables.input.isBudgetConfirmed ? TaskStatus.Completed : TaskStatus.NotStarted,
        taskActionableType: TaskActionableType.ConfirmProjectBudget,
        taskActionablePayload: null,
        genericActionableType: null,
      })
    }
    setEditState(undefined)
  } })

  const myTasks = useGetMyProjectTasks(id ?? "")
  const fireStartMatchingClicked = useAnalyticsEvent('Project_Start_Matching_Clicked')

  const cancel = () => setEditState(undefined)

  const project = showProjectQuery.data?.getProject
  const documentKinds = project?.documents?.filter((doc) => doc.status !== UploadedFileStatus.Archived).flatMap((item) => item.kind).filter(uniqueFilter).filter(i => i!= null)

  const handleOpenEditModal = (state: typeof editState) => setEditState(state)

  if (!project || !task || !myTasks) return <LoadingSpinnerContent name="ScreenStartMatching" />

  const context = {
    project,
    updateProject,
    cancel,
  }

  const showEarlyProjectAlert = !alwaysArray(documentKinds).includes(UploadedFileKind.Technical)
  const maybeBudgetConfirmationTask = [ ...myTasks.completedTasks, ...myTasks.pendingTasks ].find((task) => task.actionableType === TaskActionableType.ConfirmProjectBudget)

  const canConfirmStartMatching = async () => {
    if (!project.isBudgetConfirmed){
      return present({
        message: "You need a budget to proceed.",
        buttons: [
          {
            text: 'OK',
          } ,
        ],
      })
    } else {
      await markTaskAsCompleted()
    }
  }

  const handleOpenPaymentPlanPickerModal = () => {
    if (!myTeam?.id) return console.error('[ScreenStartMatching]: myTeam is undefined')

    fireStartMatchingClicked({
      projectId: project.id,
      teamId: myTeam?.id,
    })

    setStartMatchingPaymentPickerModalState(true)
  }

  return (
    <>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader pageTitle={task.actionTitle ?? task.title}/>
      </WeaverIonHeader>
      <WeaverIonContent>
        <div className={Styles.container}>
          <div>
            <div className="ion-margin">
              <h2>Confirm the project brief and start matching with contractors</h2>
              <p>Please confirm the details are correct. You won&apos;t be able to change them later.</p>
            </div>

            <p className={`ion-padding-horizontal ion-padding-top ${Styles.weaverContractorPreviewTextBold}`}>What Weaver contractors will see:</p>

            <IonCard className={Styles.showLeadCard}>
              {project.address && <GoogleMapFromWeaverAddress address={project.address} zoomLevel={13} />}

              {project.propertyType &&
              <IonItem lines='none' className={`${Styles.item} ion-margin-top`}>
                <div>{TENDER_AND_PROJECT_PROPERTIES.PROPERTY}</div>
                <div className={Styles.editableContent}>
                  {propertyTypeLabels[project.propertyType] }
                  <img src={EditIcon} alt="Edit" onClick={() => handleOpenEditModal('propertyType')}/>
                </div>
              </IonItem>
              }

              <IonItem lines='none' className={`${Styles.item} ion-margin-top`}>
                <div>{TENDER_AND_PROJECT_PROPERTIES.PROJECT_TYPE}</div>
                <div className={Styles.editableContent}>
                  {project.projectTypes.map((projectType) => projectTypeLabels[projectType]).join(', ')}
                  <img src={EditIcon} alt="Edit" onClick={() => handleOpenEditModal('projectType')}/>
                </div>
              </IonItem>

              {project.specialties &&
              <IonItem lines='none' className={`${Styles.item} ion-margin-top`}>
                <div>{TENDER_AND_PROJECT_PROPERTIES.SPECIALTY}</div>
                <div className={Styles.editableContent}>
                  {project.specialties.map((specialtyType) => specialtiesLabels[specialtyType]).join(', ')}
                  <img src={EditIcon} alt="Edit" onClick={() => handleOpenEditModal('specialties')}/>
                </div>
              </IonItem>
              }

              <IonItem lines='none' className={Styles.item}>
                <div>{TENDER_AND_PROJECT_PROPERTIES.CLIENT_ESTIMATED_BUDGET}</div>
                <div className={Styles.editableContent}>
                  {!project.isBudgetConfirmed
                    ? "Budget not confirmed"
                    : flexibleBudgetTextToSignificantFigures(getFlexibleProjectBudgetAsMoneyOrMoneyRange(project, myTeam, weaverFlags['MW-2600-expand-project-creation-flow'], weaverFlags['MW-2645-adjust-flex-budget-ranges']))}
                  <img src={EditIcon} alt="Edit" onClick={() => handleOpenEditModal('budget')} />
                </div>
              </IonItem>

              {project.finish &&
              <IonItem lines='none' className={`${Styles.item} ion-margin-top`}>
                <div>{TENDER_AND_PROJECT_PROPERTIES.FINISHES}</div>
                <div className={Styles.editableContent}>
                  {finishLabels[project.finish]}
                  <img src={EditIcon} alt="Edit" onClick={() => handleOpenEditModal('finish')}/>
                </div>
              </IonItem>
              }

              <IonItem lines='none' className={Styles.item}>
                <div>{TENDER_AND_PROJECT_PROPERTIES.WORK_START_ESTIMATE}</div>
                <div className={Styles.editableContent}>
                  {getWorkStartEstimateLabels()[project.workStartEstimate]}
                  <img src={EditIcon} alt="Edit" onClick={() => handleOpenEditModal('workStartEstimate')} />
                </div>
              </IonItem>

              <IonItem lines='none' className={Styles.item}>
                <div>{TENDER_AND_PROJECT_PROPERTIES.CONTACT_PERSON}</div>
                <div>
                  {
                    project.members
                      .filter(member => member.projectRole === ProjectMemberRole.Owner && member.team.type !== TeamType.Partner)
                      .filter(uniqueFilter)
                      .map(member => teamTypeLabels[member.team.type])
                      .join(', ')
                  }
                </div>
              </IonItem>

              {weaverFlags['MW-2659-project-description-ui-improvements']
                ? (
                  <>
                    <div className={Styles.descriptionItem}>{TENDER_AND_PROJECT_PROPERTIES.FURTHER_DESCRIPTION}</div>
                    <div className={`${Styles.editableContent} ion-padding-end`}>
                      <ExpandedProjectDescription description={project.description || "none"} characterLimit={275} />
                      <img src={EditIcon} alt="Edit" onClick={() => handleOpenEditModal('description')} />
                    </div>
                  </>
                )
                :  (
                  <>
                    <IonItem lines='none' className={Styles.item}>
                      <div>{TENDER_AND_PROJECT_PROPERTIES.FURTHER_DESCRIPTION}</div>
                    </IonItem>
                    <div className={`${Styles.editableContent} ion-padding-end ion-padding-start`} >
                      {project.description || "none"}
                    </div>
                  </>
                )
              }

              <>
                <IonItem lines='none'>
                  Available documents
                </IonItem>
                <IonItem lines='none'>
                  <div className={Styles.tenderReadinessItems}>
                    <div>
                      <IonIcon color={alwaysArray(documentKinds).includes(UploadedFileKind.Planning) || alwaysArray(documentKinds).includes(UploadedFileKind.Technical) ? 'success': 'light'}  icon={checkmarkCircle} slot="start" />
                      {leadDocumentsKindTypeLabels.Planning}
                    </div>
                    <div>
                      <IonIcon color={alwaysArray(documentKinds).includes(UploadedFileKind.Structural) ? 'success': 'light'}  icon={checkmarkCircle} slot="start" />
                      {leadDocumentsKindTypeLabels.Structural}
                    </div>
                    <div>
                      <IonIcon color={alwaysArray(documentKinds).includes(UploadedFileKind.Technical) ? 'success': 'light'}  icon={checkmarkCircle} slot="start" />
                      {leadDocumentsKindTypeLabels.Technical}
                    </div>
                    <div>
                      <IonIcon color={alwaysArray(documentKinds).includes(UploadedFileKind.Sow) ? 'success': 'light'}  icon={checkmarkCircle} slot="start" />
                      {leadDocumentsKindTypeLabels.SOW}
                    </div>
                  </div>
                </IonItem>
                { showEarlyProjectAlert &&
                <div>
                  <IonAccordionGroup expand="inset">
                    <IonAccordion className={Styles.tenderPackAccordionContainerBorder}>
                      <IonItem slot="header" className={Styles.tenderPackAccordingBackgroundColor}>
                        <IonIcon color="warning" className={Styles.tenderPackAlertIcon} icon={alertCircleOutline} slot="start" />
                        <p className={Styles.tenderPackHeader}>{TENDER_PACK_IN_PROGRESS_HEADER}</p>
                      </IonItem>
                      <div className={`ion-padding ${Styles.tenderPackAccordingBackgroundColor}`} slot="content">
                        <p className={Styles.tenderPackFirstParagraphNoMarginTop}>{TENDER_PACK_IN_PROGRESS_CONTENT[0]}</p>
                        <p>{TENDER_PACK_IN_PROGRESS_CONTENT[1]}</p>
                      </div>
                    </IonAccordion>
                  </IonAccordionGroup>
                </div>
                }
              </>

            </IonCard>
          </div>
          {weaverFlags['MW-2637-start-matching-skeleton']
            ? <IonButton className={Styles.confirmButtonMargin}  expand='block' onClick={handleOpenPaymentPlanPickerModal}>Start Matching Contractors</IonButton>
            : <SingleClickButton className={Styles.confirmButtonMargin} expand='block' onClick={canConfirmStartMatching}>Start Matching Contractors</SingleClickButton>
          }
        </div>
      </WeaverIonContent>
      <ScreenStartMatchingContext.Provider value={context}>
        <IonModal
          className={Styles.editProjectModal}
          id='screenStartMatchingModal'
          isOpen={!!editState}
          onDidDismiss={() => setEditState(undefined)}
          breakpoints={[ 0, 0.9, 1 ] } // HACK: IonModal is not able to scroll the full content when set to less that 1
          initialBreakpoint={0.9}
        >
          <IonToolbar>
            <IonTitle>
              {editState === "propertyType" && "Edit Property Type"}
              {editState === "projectType" && "Edit Project Type"}
              {editState === "specialties" && "Edit Specialties"}
              {editState === "finish" && "Edit Finish"}
              {editState === "budget" && "Edit Budget"}
              {editState === "workStartEstimate" && "Edit Work Start Estimate"}
              {editState === "description" && "Edit Description"}
            </IonTitle>
            <IonIcon className={Styles.closeIcon} onClick={() => setEditState(undefined)} icon={closeOutline} slot="end" />
          </IonToolbar>
          {editState === "propertyType" && <EditPropertyTypeModal />}
          {editState === "projectType" && <EditProjectTypeModal  />}
          {editState === "specialties" && <EditSpecialitiesModal />}
          {editState === "finish" && <EditFinishModal />}
          {editState === "budget" && <EditBudgetModal />}
          {editState === "workStartEstimate" && <EditWorkStartEstimateModal  />}
          {editState === "description" && <EditDescriptionModal />}
        </IonModal>
        <IonModal
          className={Styles.paymentPickerModal}
          id='screenStartMatchingPaymentModal'
          onDidDismiss={() => setStartMatchingPaymentPickerModalState(false)}
          isOpen={startMatchingPaymentPickerModalState}>
          <IonToolbar className='ion-padding-horizontal'>
            <IonTitle>Start matching contractors</IonTitle>
            <IonIcon className={Styles.closeIcon} onClick={() => setStartMatchingPaymentPickerModalState(false)} icon={arrowBack} slot="start" />
          </IonToolbar>
          <StartMatchingPaymentModal nudgeSentRef={nudgeSentRef}/>
        </IonModal>
      </ScreenStartMatchingContext.Provider>
    </>
  )
}

export default ScreenStartMatching
