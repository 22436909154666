import React from 'react'
import { CreateProjectMutation } from '../../../graphql/generated'
import { pageConfig_ShowProject, populateUrlForPath } from '../../../routes'
import BrilliantScreen from '../../../common/components/BrilliantScreen/BrilliantScreen'

type SuccessSlideProps = {
  createdProject: CreateProjectMutation["createProject"],
}

const SuccessSlide: React.FC<SuccessSlideProps> = ({ createdProject: { id } }) => {

  const routeToProject = populateUrlForPath(pageConfig_ShowProject.path, { id }, "referrer=createProject")

  return (
    <BrilliantScreen
      title='Nice one'
      actionButtonProps={{
        href: routeToProject,
        children: <>Continue</>,
        expand: 'block',
      }}/>
  )
}

export default SuccessSlide
