import React, { useCallback, useState } from "react"
import { IonButton, IonIcon, IonToolbar } from "@ionic/react"
import { pageConfig_Profile_ContractorLeadPreferences, useRouteTo } from "../../../../routes"
import { useScreens } from "../../../../common/hooks/useScreens"
import { arrowBackOutline, arrowForwardOutline } from "ionicons/icons"
import { LocationArea, useUpdateContractorProfileMutation } from "../../../../graphql/generated"
import { useInvalidateMyContractorProfile, useMyContractorProfile } from "../ContractorBudgetRanges/datasource"
import { getGenericSelectorOptionsForEnum } from "../../../../common/components/GenericSelectorList/helpers"
import { locationAreaLabels } from "../../../onboarding/team/onboarding.i18n"
import GenericSelectorList from "../../../../common/components/GenericSelectorList"
import { alwaysArray } from "../../../../common/utils"
import { useGraphQLDataSource } from "../../../../api/graphql"
import { useHistory } from "react-router"
import SingleClickButton from "../../../../common/components/SingleClickButton"
import { useAnalyticsEvent } from "../../../../api/providers/SegmentProvider/hooks"
import WeaverIonContent from "../../../../common/components/WeaverIonWrappers/WeaverIonContent"
import WeaverIonFooter from "../../../../common/components/WeaverIonWrappers/WeaverIonFooter"
import Styles from "./ContractorLeadLocations.module.scss"
import WeaverIonPage from "../../../../common/components/WeaverIonWrappers/WeaverIonPage"
import BrilliantScreen from "../../../../common/components/BrilliantScreen/BrilliantScreen"
import { useWeaverFlags } from "../../../../api/thirdParty/launchDarkly/useWeaverFlags"

enum LeadLocationScreens {
  PickLeadLocations = "PickLeadLocations",
  Finished = "Finished",
}

const ContractorLeadLocations: React.FC = () => {
  const history = useHistory()
  const triggerLocationPreferences = useAnalyticsEvent('WorkHistory_References_Set_Location_Preferences')

  // Loading this up here, so the data is ready by the time PickLeadLocations is rendered (so it can init from the data)
  const myContractorProfile = useMyContractorProfile()

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const updateContractorMutation = useUpdateContractorProfileMutation(gqlDataSource)
  const invalidateMyContractorProfile = useInvalidateMyContractorProfile()

  const goToContractorLeadPreferences = useRouteTo(pageConfig_Profile_ContractorLeadPreferences.path)

  const [ Screen, activeScreen ] = useScreens<LeadLocationScreens>({
    init: LeadLocationScreens.PickLeadLocations,
    resetWhenValueChanges: history.location.search,
    screens: {

      PickLeadLocations: {
        render: useCallback(({ useChangeScreen, previousScreen, nextScreen }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          const changeToNextScreen = useChangeScreen(nextScreen)
          const { "MW-2604-copy-change-lead-to-tender": leadToTenderFlag } = useWeaverFlags()
          const [ locationAreas, setLocationAreas ] = useState<string[]>(
            () => alwaysArray(myContractorProfile.getContractorProfileQuery.data?.getContractorProfile?.locationAreas),
          )

          const [ clickInProgress, setClickInProgress ] = useState(false)

          const onClickNext = async () => {
            setClickInProgress(true)

            const contractorId = myContractorProfile.getContractorProfileQuery.data?.getContractorProfile?.id
            if (!contractorId) {
              // This shouldn't happen, but can in theory
              throw new Error('Missing Contractor Profile ID!')
            }

            await updateContractorMutation.mutateAsync({
              input: { id: contractorId, locationAreas },
            })
            await triggerLocationPreferences({
              locations: locationAreas,
            })
            await invalidateMyContractorProfile()
            await changeToNextScreen()

            setClickInProgress(false)
          }

          return <>
            <WeaverIonContent>
              <h3 className="ion-padding-start">What areas do you want to add to your {leadToTenderFlag ? 'tender' : 'lead'} preferences</h3>
              <p className="ion-padding-start">Select up to 3 areas you want to receive {leadToTenderFlag ? 'tenders' : 'leads'} in</p>
              <GenericSelectorList
                options={getGenericSelectorOptionsForEnum(LocationArea, locationAreaLabels)}
                multiSelect={true}
                selected={locationAreas}
                onSelect={setLocationAreas}
                showItemDetail={false}
                colSize={'6'}
                disabled={clickInProgress}
                maxSelected={3}
              />
            </WeaverIonContent>
            <WeaverIonFooter className={`${Styles.ionFooter} ion-no-border ion-padding`}>
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton color="secondary" slot="start" disabled={clickInProgress} onClick={goToContractorLeadPreferences({})}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
                <SingleClickButton slot="end" disabled={locationAreas.length === 0} onClick={onClickNext}>Next <IonIcon icon={arrowForwardOutline} /></SingleClickButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, [ myContractorProfile ]),
      },

      Finished: {
        render: useCallback(() => {
          const { "MW-2604-copy-change-lead-to-tender": leadToTenderFlag } = useWeaverFlags()

          return <>
            <WeaverIonContent>
              <BrilliantScreen title='Got it' description={`We'll only send you ${leadToTenderFlag ? 'tenders' : 'leads'} for the selected locations. Return to this flow if you want to change them at any time in the future.`}/>
            </WeaverIonContent>
            <WeaverIonFooter className="ion-no-border ion-padding">
              <IonToolbar className={Styles.ionToolbar}>
                <IonButton expand="block" onClick={goToContractorLeadPreferences({})} data-testid="ContractorLeadLocations.FinishedButton">Continue</IonButton>
              </IonToolbar>
            </WeaverIonFooter>
          </>
        }, [ goToContractorLeadPreferences ]),
      },

    },
  })

  return (
    <WeaverIonPage id='ContractorLeadPreferencesPage' key={activeScreen} disableDirectChildStructureChecks={true}>
      {Screen}
    </WeaverIonPage>
  )
}

export default ContractorLeadLocations
