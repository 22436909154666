import { IonAvatar, IonIcon, IonItem, IonLabel } from '@ionic/react'
import { DateTime } from 'luxon'
import React from 'react'
import { allIonIcons } from '../../../common/components/IonIconTyped/icons'
import { GetArchitectProfileQuery } from '../../../graphql/generated'

import Styles from './ArchitectTimelineCard.module.scss'

type ArchitectDetailsCardProps = {
  architectProfile: NonNullable<GetArchitectProfileQuery['getArchitectProfile']>,
}

export const ArchitectTimelineCard:React.FC<ArchitectDetailsCardProps> = ({ architectProfile }) => {

  const companyInfoLink = `https://find-and-update.company-information.service.gov.uk/company/${architectProfile.companyNumber}`

  const architectCompanyIncorporationYear = DateTime.fromISO(architectProfile.companyIncorporationDate ?? '').year

  return (
    <section className={Styles.companyInfoContainer}>
      <div className={Styles.yearContainer}>
        {!Number.isNaN(architectCompanyIncorporationYear)
          ? <h5>{architectCompanyIncorporationYear}</h5>
          : <h5>Company information</h5> }
      </div>

      <IonItem lines='none' className={Styles.profileWorkHistoryItem}>
        <IonAvatar slot="start" className={[ Styles.architectAvatar, "ion-margin-top" ].join(" ")}>
          <IonIcon color="primary" icon={allIonIcons.business} />
        </IonAvatar>

        <IonLabel className={Styles.ionItemBody}>
          <h2>Company incorporated</h2>
          <p className={Styles.contractorTeamType}>{architectProfile.companyTradingAs}</p>
          <p>Private limited company</p>
          <p>Company number: {architectProfile.companyNumber}</p>
          {architectProfile.companyIncorporationDate && <p>Date of incorporation: {DateTime.fromISO(architectProfile.companyIncorporationDate).toFormat("MMMM yyyy")}</p>}
          <section className={Styles.architectOfficeContainer}>
            <a href={companyInfoLink} target='_blank' rel="noreferrer">View {architectProfile.companyTradingAs} on Companies House</a>
          </section>
        </IonLabel>
      </IonItem>
    </section>
  )
}
