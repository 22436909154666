import React from 'react'
import GlobalHeader from '../../../../common/components/GlobalHeader'
import WeaverIonPage from '../../../../common/components/WeaverIonWrappers/WeaverIonPage'
import GlobalHeaderStyles from '../../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import WeaverIonHeader from '../../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import { IonButton, IonIcon, IonList, IonText, useIonAlert, useIonRouter } from '@ionic/react'
import { arrowBackOutline } from 'ionicons/icons'
import WeaverIonContent from '../../../../common/components/WeaverIonWrappers/WeaverIonContent'
import Styles from "./ChatRoomMembers.module.scss"
import { useGraphQLDataSource } from '../../../../api/graphql'
import { useParamsFromPageConfig } from '../../../../routesProvider'
import { TeamType, useGetChatRoomTeamConnectionsQuery } from '../../../../graphql/generated'
import LoadingSpinnerPage from '../../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import { ProjectMemberTeam } from '../../../projects/ShowProjectTypes'
import { useChatRoomFunctions } from '../../../../api/services/chat/useChatRoomFunctions'
import { MemberRow } from '../../../../common/components/MemberRow/MemberRow'
import { getChatName } from '../utils'
import { useMyIndividualActiveTeam } from '../../../../api/providers/MyIndividualProvider/MyIndividualProvider'

export const ChatRoomMembers: React.FC = () => {
  const router = useIonRouter()
  const [ presentAlert ] = useIonAlert()
  const myTeam = useMyIndividualActiveTeam()

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const { chatRoomId } = useParamsFromPageConfig<{chatRoomId: string}>()

  const { createChatRoom } = useChatRoomFunctions()
  const resultGetChatRoomConnectionsQuery = useGetChatRoomTeamConnectionsQuery(gqlDataSource, { chatRoomId } )

  if (resultGetChatRoomConnectionsQuery.isFetching && !resultGetChatRoomConnectionsQuery.data) return <LoadingSpinnerPage name="ChatRoomOptions"/>

  const shouldHideTeam = (team: ProjectMemberTeam) => {
    const hidePartnerFromContractor = myTeam?.type === TeamType.Contractor && team.type === TeamType.Partner
    return [ hidePartnerFromContractor ].some(Boolean) // add more conditions here
  }

  const teamConnections = resultGetChatRoomConnectionsQuery.data?.getChatRoom?.teamConnections
  const project = resultGetChatRoomConnectionsQuery.data?.getChatRoom?.project
  const visibleTeams = teamConnections?.filter(teamConnection => !shouldHideTeam(teamConnection.team)).map(teamConnection => teamConnection.team) || []

  const createPrivateChatRoomBetweenTeams = (myTeam: ProjectMemberTeam, selectedTeam: ProjectMemberTeam) =>
    createChatRoom({
      name: getChatName([ myTeam, selectedTeam ], project?.title),
      teamIds: [ myTeam.id, selectedTeam.id ],
      projectId: project?.id, // When this component is accessed through a Project the projectId of the new ChatRoom is the same as the existing ChatRoom
    })

  const goBack = () => router.goBack()

  const onChatBubbleClicked = (selectedTeam: ProjectMemberTeam, goToChatRoomCreateIfNecessary: () => void) => {
    presentAlert({
      header: `Private message`,
      message: `Are you sure you want to send a private message to Team ${selectedTeam.name}`,
      buttons: [
        {
          text: 'Yes',
          role: 'confirm',
          handler: () => {
            goToChatRoomCreateIfNecessary()
          },
        },
        {
          text: 'No',
          role: 'cancel',
        },
      ],
    })
  }

  return (
    <WeaverIonPage id='ChatRoomMembers' disableDirectChildStructureChecks={true}>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader
          pageTitle={"Chat Members"}
          navElement={
            <IonButton onClick={goBack}>
              <IonIcon slot="start" icon={arrowBackOutline} />
            </IonButton>
          }
        />
      </WeaverIonHeader>
      <WeaverIonContent>
        <div className='mainContainer'>
          <section className={Styles.membersSection}>
            <IonText><p className={Styles.membersHeading}>Members</p></IonText>
            <IonList className={Styles.membersListBottomMargin}>
              { visibleTeams?.map(team => <MemberRow isChatRoomMemberPage={true} key={team.id} team={team} projectId={project?.id} createChatRoom={createPrivateChatRoomBetweenTeams} onChatBubbleClickedAction={onChatBubbleClicked} />) }
            </IonList>
          </section>
        </div>
      </WeaverIonContent>
    </WeaverIonPage>
  )
}
