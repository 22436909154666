import { zodResolver } from "@hookform/resolvers/zod"
import { IonContent, IonTextarea } from "@ionic/react"
import React from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { requireSomething } from "../../../../common/utils"
import { ScreenStartMatchingContext } from "./common"
import SingleClickButton from "../../../../common/components/SingleClickButton"

import Styles from '../TaskActionerPage.module.scss'

const FormSchema = z.object({
  description: z.string(),
})

const PLACEHOLDER = 'Ex: Removing internal wall, extending the living room and removing 2 chimneys.'

type FormData = z.infer<typeof FormSchema>

const EditDescriptionModal: React.FC = () => {
  const { project, updateProject, cancel } = requireSomething( React.useContext(ScreenStartMatchingContext) )
  const { handleSubmit, register } = useForm<FormData>({ resolver: zodResolver(FormSchema), defaultValues: { description: project.description } })

  const onSubmit = async (data: FormData) => {
    await updateProject.mutateAsync({ input: {
      id: project.id,
      description: data.description,
    } })
  }

  return <>
    <IonContent className="ion-padding">
      <p>Accurate work description helps match contractors with relevant skills.</p>
      <IonTextarea autoGrow className={Styles.descriptionTextArea} placeholder={PLACEHOLDER} {...register("description")}/>
      <SingleClickButton className={Styles.descriptionEditModalButton} expand="block" onClick={handleSubmit(onSubmit)}>Confirm</SingleClickButton>
    </IonContent>
  </>
}

export default EditDescriptionModal
