import React from 'react'
import { IonAccordion, IonAccordionGroup, IonItem, IonLabel } from '@ionic/react'
import Styles from "./FAQ.module.scss"
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'

export const PplFAQ: React.FC = () => {
  const weaverFlags = useWeaverFlags()

  return (
    <div className={`ion-padding ${Styles.faqContainer}`}>
      <h3>FAQ</h3>
      <IonAccordionGroup multiple={true}>
        <IonAccordion value="1">
          <IonItem lines="none" slot="header">
            <IonLabel className='ion-text-wrap'>What is the difference between a monthly subscription and a single tender payment?</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            When you opt for the monthly subscription you will receive {weaverFlags['MW-2656-copy-change-on-subscription'] ? '1 to 4 tenders depending on seasonality' : '3 tenders per month'}. You can cancel your subscription
            at any time via the platform instructions. On the other hand, the single tender payment is only for the tender
            you viewed on the previous screen, allowing you to flexibly use Weaver as and when you need tenders.
          </div>
        </IonAccordion>
        <IonAccordion value="2">
          <IonItem lines="none" slot="header">
            <IonLabel className='ion-text-wrap'>How do I know this tender is real? How does Satisfaction Money-back Guarantee work?</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            At Weaver, we believe in the power of our service to transform your project pipeline.
            That&apos;s why we proudly offer our Satisfaction Money-Back Guarantee, a promise that puts your mind at ease.
            We stand behind the quality of our tenders, and we want you to feel confident you will experience a fair tender
            process.
            If for any reason you are not satisfied with your experience, we will refund you in full.
          </div>
        </IonAccordion>
        <IonAccordion value="3">
          <IonItem lines="none" slot="header">
            <IonLabel className='ion-text-wrap'>What happens after I accept the tender?</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            You will be able to immediately download the project tender documents and straight away message/call the
            homeowner/architect to begin organising a site meeting so you can submit a tender.
          </div>
        </IonAccordion>
        <IonAccordion value="4">
          <IonItem lines="none" slot="header">
            <IonLabel className='ion-text-wrap'>If I pay for this tender, does this mean I will win the project?</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            Paying for the tender does not guarantee winning the project, however the tender will be a fair and competitive
            one where the most suitable contractor is chosen by the homeowner/architect.
            Weaver does guarantee that the tender is real based on our Satisfaction Money-back Guarantee defined above.
          </div>
        </IonAccordion>
        <IonAccordion value="5">
          <IonItem lines="none" slot="header">
            <IonLabel className='ion-text-wrap'>What if I am unhappy with Weaver&apos;s services or a particular tender?</IonLabel>
          </IonItem>
          <div className="ion-padding" slot="content">
            At Weaver, customer satisfaction is of utmost importance to us. In the unlikely event that you are unhappy with
            our service, we kindly request that you contact our support team at <a href='mailto:support@weaver.build'>support@weaver.build</a>.
            Our dedicated team will review your case on a personal level and carefully evaluate your position. We will
            strive to find a resolution that satisfies your unique circumstances.
          </div>
        </IonAccordion>

      </IonAccordionGroup>
    </div>
  )
}

export default PplFAQ
