import { Finish, Specialty } from "../../graphql/generated"

// The order is important here
export const finishLabels: Record<Finish, string> = {
  [Finish.Standard]: "Standard",
  [Finish.HighEnd]: "High End",
  [Finish.Luxury]: "Luxury",
}

export const finishDescriptions: Record<Finish, string> = {
  [Finish.Standard]: "Normal detailing, no bespoke requirements or complex interfaces. ",
  [Finish.HighEnd]: "Recessed skirtings, curved walls, or more complex construction methodologies. ",
  [Finish.Luxury]: "Intricate detailing and highly complex construction methodologies.",
}

export const specialtiesLabels: Record<Specialty, string> = {
  [Specialty.BespokeJoinery]: "Bespoke Joinery",
  [Specialty.Cladding]: "Cladding",
  [Specialty.Flooring]: "Flooring",
  [Specialty.Glazing]: "Glazing",
  [Specialty.Metalwork]: "Metalwork",
  [Specialty.Heritage]: "Heritage",
  [Specialty.SmartHomeAutomation]: "Smart home automation",
  [Specialty.SpecialistBuildingServices]: "Specialist building services",
  [Specialty.SpecialistFinishes]: "Specialist finishes",
  [Specialty.SpecialistLighting]: "Specialist lighting",
  [Specialty.SustainableConstruction]: "Sustainable construction",
  [Specialty.SpecialistBuildingServices]: "Specialist building services",
}
