import React, { useState } from "react"
import { BudgetRange, Team } from "../../../../graphql/generated"
import { useContractorProfile, useMyContractorProfile } from "../ContractorBudgetRanges/datasource"
import { BadgeLevel, getBadgeInfo } from "../ContractorBudgetRanges/UnlockContractorBudgetRange/BadgeUtils"
import { getAllBadgeEligibleHistories } from "../ContractorBudgetRanges/UnlockContractorBudgetRange/ContractorProfileBudgetRangeBadge/hooks"
import { badgeInfoModalState } from "../ContractorBudgetRanges/UnlockContractorBudgetRange/BadgeUtils"
import { BadgeInfoModal } from "./BadgeInfoModal"
import { useAnalyticsEvent } from "../../../../api/providers/SegmentProvider/hooks"

/**
 * @param requestedForPublicUse - determines if we can fetch builder's profile by using useContractorProfile or useMyContractorProfile
 * @param team - team object in case the requestedForPublicUse is true.
 * @returns openBadgeInfoModal function and BadgeInfoModal component.
*/
export const useOpenBadgeInfoModal = (requestedForPublicUse: boolean, team? :Pick<Team, 'id' | 'type'>) => {
  const [ badgeInfoModalState, setBadgeInfoModalState ] = useState<badgeInfoModalState | undefined>(undefined)
  const myContractorProfile = requestedForPublicUse ? useContractorProfile(team) : useMyContractorProfile()

  const triggerPublicProfileBadgeViewed = useAnalyticsEvent("PublicProfile_Badge_Viewed")

  const openBadgeInfoModal = (badgeLevel: BadgeLevel, budgetRange: BudgetRange, locked: boolean) => {
    const allBadgeEligibleHistories = getAllBadgeEligibleHistories(myContractorProfile, budgetRange)
    if (!budgetRange) return

    if (requestedForPublicUse) {
      triggerPublicProfileBadgeViewed({
        budgetRange,
      })
    }

    const usageExplanation = !requestedForPublicUse ? `(shown on your public profile)` : ''

    setBadgeInfoModalState({
      locked: locked,
      title: `${badgeLevel} badge ${usageExplanation} ${locked ? ' - Locked' : ''}`,
      description: getBadgeInfo(badgeLevel, budgetRange, allBadgeEligibleHistories.length)?.description,
      badgeLevel: badgeLevel,
      projectHistorySummary: getBadgeInfo(badgeLevel, budgetRange, allBadgeEligibleHistories.length)?.projectHistorySummary,
      budgetRange: budgetRange,
    })
  }

  const displayBadgeInfoModal = () => {
    return (
      <BadgeInfoModal
        locked={badgeInfoModalState?.locked}
        title={badgeInfoModalState?.title}
        description={badgeInfoModalState?.description}
        projectHistorySummary={badgeInfoModalState?.projectHistorySummary}
        budgetRange={badgeInfoModalState?.budgetRange}
        badgeLevel={badgeInfoModalState?.badgeLevel}
        isOpen={!!badgeInfoModalState}
        onDidDismiss={() => setBadgeInfoModalState(undefined)}
        initialBreakpoint={0.5}
        breakpoints={[ 0, 0.5 ]}
      />
    )
  }
  return {
    openBadgeInfoModal,
    displayBadgeInfoModal,
  }
}
