import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { checkmarkCircle, openOutline, wallet } from "ionicons/icons"
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import Styles from "./MembershipPayPerLead.module.scss"
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import GlobalHeader from '../../../common/components/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import CentralisedBlock from '../../../common/components/CentralisedBlock'
import SingleClickButton from '../../../common/components/SingleClickButton'

type MembershipPayPerLeadProps = {
  title: string,
  /** A list of product benefits / key features. e.g. "Satisfaction Money back guarantee!*" */
  list: string[],
  budgetRange: string,
  paymentFee?: string | null,
  isSubscriptionFee?: boolean,
  /** Indicate when the button is ready to be pressed. Button is disabled when not ready. */
  isReady: boolean,
  /** Indicates payment process has been initiated. A message will be displayed instructing the user of next steps. */
  inProgress: boolean,
  /** Provide a url to present as a hyperlink as a fallback to a blocked pop-up */
  fallbackUrl: string | undefined,
  navigateTo: () => void | Promise<void>,
  goBack: () => void | Promise<void>,
}

const MembershipPayPerLead: React.FC<MembershipPayPerLeadProps> = ({ title, budgetRange, list, paymentFee, isSubscriptionFee, isReady, inProgress, fallbackUrl, navigateTo }) => {
  const { "MW-2604-copy-change-lead-to-tender": leadToTenderFlag } = useWeaverFlags()
  return <>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader pageTitle={title} />
    </WeaverIonHeader>
    <WeaverIonContent className="ion-padding">
      {isSubscriptionFee
        ? (
          <div className={Styles.budgetFeeInfo}>
            <p className={Styles.largerHeading}>Get unlimited {budgetRange} {leadToTenderFlag ? 'tenders' : 'leads'}</p>
            <p className={Styles.paymentFee}>
              {paymentFee}
              <span className={Styles.paymentVAT}> incl. VAT</span>
            </p>
            <p>per month</p>
          </div>
        )
        : (
          <div className={Styles.budgetFeeInfo}>
            <p className={Styles.largerHeading}>Accept this {budgetRange} {leadToTenderFlag ? 'tender' : 'lead'}</p>
            <p className={Styles.paymentFee}>
              {paymentFee}
              <span className={Styles.paymentVAT}> incl. VAT</span>
            </p>
            <p>for this {leadToTenderFlag ? 'tender' : 'lead'} only</p>
          </div>
        )}
      <div className={Styles.feeListItems}>
        {list.map((itemText: string) => (
          <span key={itemText}><IonIcon icon={checkmarkCircle} /><p>{itemText}</p></span>
        ))}
      </div>

      {inProgress
        ? (
          <CentralisedBlock>
            <IonIcon icon={wallet} size="large" color="primary" />
            <h4>Payment in progress…</h4>
            <p>You should be directed to the payment screen.</p>
            <p>If the screen doesn’t change, please check for a pop up blocker or <a href={fallbackUrl} target="_blank" rel="noreferrer">open the checkout manually.</a></p>

            <SingleClickButton className={Styles.retryButton} href={fallbackUrl} target="_blank" color="secondary"><IonIcon icon={openOutline} /> Open checkout</SingleClickButton>
          </CentralisedBlock>
        )
        : (
          isSubscriptionFee
            ? (
              <IonButton className={Styles.payButton} expand='block' disabled={!isReady} onClick={navigateTo}>
                <b>Pay {paymentFee} per month</b>
              </IonButton>
            )
            : (
              <IonButton className={Styles.payButton} expand='block' disabled={!isReady} onClick={navigateTo}>
                <b>Pay {paymentFee} to accept this {leadToTenderFlag ? 'tender' : 'lead'}</b>
              </IonButton>
            )
        )
      }

    </WeaverIonContent>
  </>
}

export default MembershipPayPerLead
