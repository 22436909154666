import React from 'react'
import TeamMembers from '../TeamMembers'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useGetArchitectProfileQuery } from '../../../graphql/generated'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import ErrorBlock from '../../../common/components/ErrorBlock'
import { PublicArchitectProfileDetailsCard } from './PublicArchitectProfileDetailsCard'
import { ArchitectTimelineCard } from '../MyArchitectProfilePage/ArchitectTimelineCard'

type PublicArchitectProfileProps = {
  id: string,
}

const PublicArchitectProfile: React.FC<PublicArchitectProfileProps> = ({ id }) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data , error, isFetching, refetch } = useGetArchitectProfileQuery(gqlDataSource, { architectTeamId: id })

  if (isFetching) {
    return <LoadingSpinner name="PublicArchitectProfile" />
  }

  if (error) {
    console.error(`[PublicArchitectProfile] Failed to load Architect Profile: `, { id, data, error })
    return <ErrorBlock name='PublicArchitectProfile.failed' onRefresh={refetch} />

  }

  if (data?.getArchitectProfile == null) {
    console.warn(`[PublicArchitectProfile] Architect Profile is missing: `, { id, data, error })
    return <ErrorBlock name='PublicArchitectProfile.missing'><p>Unable to find the Architect Profile.</p></ErrorBlock>
  }

  const profile = data.getArchitectProfile
  const isSoleTrader = !profile?.companyNumber

  return <>
    <PublicArchitectProfileDetailsCard architectProfile={profile}/>
    {!isSoleTrader && <ArchitectTimelineCard architectProfile={profile} />}
    <div className='ion-padding-start'>
      <TeamMembers teamId={id} />
    </div>
  </>
}

export default PublicArchitectProfile
