import React from 'react'
import TeamMembers from '../TeamMembers/TeamMembers'
import ButtonChangeLeadPreferences from '../ContractorLeadPreferences/ButtonChangeLeadPreferences'
import { pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges, pageConfig_Profile_Edit, useRouteTo } from '../../../routes'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import { MyContractorProfileScreenProps, MyContractorScreenNames } from './MyContractorProfilePage'
import { ScreenRenderProps } from '../../../common/hooks/useScreens'
import GlobalHeader from '../../../common/components/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { MyContractorProfileDetailsCard } from './MyContractorProfileDetailsCard'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { MyWorkHistoryTimeline } from './MyWorkHistoryTimeline'
import { MissingConstructionDates } from './MissingConstructionDates'
import Styles from './MyContractorProfilePage.module.scss'
import RangeBadge from '../ContractorLeadPreferences/ContractorBudgetReferences/RangeBadge'
import { BadgeLevel } from '../ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/BadgeUtils'
import { useOpenBadgeInfoModal } from '../ContractorLeadPreferences/ContractorBudgetReferences/useOpenBadgeInfoModal'
import { alwaysArray } from '../../../common/utils'
import { IonButton, IonChip, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react'
import { eye, statsChart } from 'ionicons/icons'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'

const MyContractorProfileScreenHome: React.FC<MyContractorProfileScreenProps & ScreenRenderProps<MyContractorScreenNames>> = (props) => {
  const routeToEditMyProfile = useRouteTo(pageConfig_Profile_Edit.path)
  const goToEditMyProfile = routeToEditMyProfile({})
  const { openBadgeInfoModal, displayBadgeInfoModal } = useOpenBadgeInfoModal(false)
  const weaverFlags = useWeaverFlags()
  const hasAwardedBadges = !!props?.profile?.budgetRangeBadges
  const goToContractorBudgetRanges = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges.path)
  const onlyAwardedBadgeLevels = alwaysArray(props?.profile?.budgetRangeBadges).filter((item) => item?.badgeLevel !== null)
  const triggerProfile_Add_Past_Project_Clicked = useAnalyticsEvent('Profile_Add_Past_Project_Clicked')

  const navigateToContractorBudgetRanges = async () => {
    await triggerProfile_Add_Past_Project_Clicked({ badgeCounter: onlyAwardedBadgeLevels.length })
    goToContractorBudgetRanges({})()
  }

  return <>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader pageTitle='My Profile' />
    </WeaverIonHeader>
    <WeaverIonContent className={Styles.displayContractorProfileContainer}>

      <>
        <MyContractorProfileDetailsCard contractorProfile={props.profile} goToEditMyProfile={goToEditMyProfile} />
        {hasAwardedBadges && <section className={`${Styles.tieredBadgeRangeContainer} ion-padding-horizontal`}>
          {onlyAwardedBadgeLevels.length > 0 && (
            <>
              <h5 className={Styles.badgeTitle}>Badges</h5>
              {onlyAwardedBadgeLevels?.map((budgetRangeBadge, index) => (<RangeBadge key={index} badgeLevel={budgetRangeBadge.badgeLevel ?? BadgeLevel.Bronze} budgetRange={budgetRangeBadge.budgetRange} width="90" height="90"
                onClick={() => openBadgeInfoModal(budgetRangeBadge.badgeLevel ?? BadgeLevel.Bronze, budgetRangeBadge.budgetRange, false)}  />))}
            </>
          )}
        </section>
        }
        <div className={[ Styles.profileDetailsContainer, 'ion-margin-vertical' ].join(" ")}>
          <section className={[ Styles.profileDetails, 'ion-padding' ].join(" ")} >
            <IonChip color="tertiary">NEW</IonChip>
            <h3>Improve your profile</h3>
            <div className={Styles.eyeContainer}><IonIcon icon={eye}/> Private to you</div>
            <div className={[ Styles.profileStatistics, 'ion-padding' ].join(" ")}>
              <div className={Styles.title} color='dark'><IonIcon icon={statsChart} /> Showcase your best work</div>
              <p className={Styles.subTitle}>Add past projects to impress clients and get more {weaverFlags['MW-2604-copy-change-lead-to-tender'] ? 'tenders' : 'leads'}</p>
            </div>
            <IonGrid className='ion-no-padding'>
              <IonRow>
                <IonCol>
                  <IonButton fill='outline' onClick={navigateToContractorBudgetRanges}>Add past project</IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <ButtonChangeLeadPreferences fill='outline'>Change {weaverFlags['MW-2604-copy-change-lead-to-tender'] ? 'tender' : 'lead'} preferences</ButtonChangeLeadPreferences>
                </IonCol>
              </IonRow>
            </IonGrid>
          </section>
        </div>
      </>

      <section className={Styles.listWorkHistoryContainer}>
        <h3 className={[ Styles.pastProjectsTitle, 'ion-padding-horizontal' ].join(" ")}>Past projects</h3>
        {!props.profile.workHistory && <p className="ion-padding">No work history has been added</p>}
        {/** //TODO: after one month from 16/02/23 check with product if MissingConstructionDates can be removed MW-2231. DO NOT REMOVE MyWorkHistoryTimeline! **/}
        <MissingConstructionDates weaverPageRef={props.weaverPageRef}>
          <MyWorkHistoryTimeline {...props} />
        </MissingConstructionDates>
      </section>

      <section className={Styles.teamMemberListContainer}>
        <TeamMembers teamId={props.profile.id} />
      </section>

      {displayBadgeInfoModal()}
    </WeaverIonContent>
  </>
}

export default MyContractorProfileScreenHome
