import React from 'react'
import { IonAvatar, IonIcon, IonItem, IonLabel, IonSkeletonText, IonText } from '@ionic/react'
import { allIonIcons } from '../IonIconTyped/icons'
import { GetContractorProfileWorkHistory, getReferenceCount, getWorkHistoryStatus } from '../../../domains/profile/ContractorLeadPreferences/ContractorBudgetRanges/datasource'
import { formatAddressToStreetPostcodeCity } from '../../utils/addresses'
import { DateTime } from 'luxon'
import { projectTypeLabels } from '../../../domains/onboarding/team/onboarding.i18n'
import { DisplayUploadedFileAsPhoto } from '../../../domains/profile/ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/WorkHistoryPhotos/DisplayUploadedFileAsPhoto'
import classNames from 'classnames'
import { alwaysArray } from "../../utils"
import { UploadedFileStatus } from '../../../graphql/generated'
import { moneyAsTextBySignificantFigures } from '../../utils/budgetTransformation'

import Styles from './WorkHistoryItem.module.scss'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import WeaverUnverifiedBadge from '../WeaverUnverifiedBadge/WeaverUnverifiedBadge'
import WeaverVerifiedBadge from '../WeaverVerifiedBadge/WeaverVerifiedBadge'
import { WorkHistoryStatus } from './WorkHistoryItem.i18n'

type WorkHistoryItemProps = {
  workHistory: GetContractorProfileWorkHistory,
  primaryClickHandler?: () => void,
  destructiveClickHandler?: () => void,
  backgroundClassName?: string,
}

export const WorkHistoryItem: React.FC<WorkHistoryItemProps> = ({ workHistory, primaryClickHandler, destructiveClickHandler, backgroundClassName }) => {
  const weaverFlags = useWeaverFlags()

  const workHistoryStatus = getWorkHistoryStatus(workHistory)
  const hasConstructionDates = workHistory.constructionStartedDate && workHistory.constructionCompletedDate

  const completedWorkHistoryImages = weaverFlags['MW-2614-handle-archivable-items-on-server']
    ? alwaysArray(workHistory.photos)
    : alwaysArray(workHistory.photos).filter(photo => photo.status === UploadedFileStatus.Completed)

  return (
    <IonItem lines='none' className={classNames({ [Styles.workHistoryItem]: true, [Styles[backgroundClassName ? backgroundClassName : '']]: !!backgroundClassName })}>
      <IonAvatar slot="start" className={[ Styles.avatar, "ion-margin-top" ].join(" ")}>
        <IonIcon color="medium" icon={allIonIcons.home} />
      </IonAvatar>
      {destructiveClickHandler && <IonLabel slot='end' className={Styles.trashIconContainer} onClick={destructiveClickHandler}>
        <IonIcon icon={allIonIcons.trashBin} className={Styles.trashIcon} />
      </IonLabel>}
      <IonLabel className={Styles.ionItemBody} onClick={primaryClickHandler}>
        <section className={Styles.addressTextContainer}>
          <h5 className={Styles.addressText}>{formatAddressToStreetPostcodeCity(workHistory.projectAddress)}</h5>
        </section>

        <section className={[ Styles.constructionDatesAndValueContainer, Styles.workHistoryItemSection ].join(' ')}>
          {hasConstructionDates && <>
            <p className={Styles.ionItemSubtitle}>{ workHistory?.constructionStartedDate && DateTime.fromISO(workHistory?.constructionStartedDate).toFormat('MMM yyyy')} - {workHistory?.constructionCompletedDate && DateTime.fromISO(workHistory?.constructionCompletedDate).toFormat('MMM yyyy')}</p>
            <p className={Styles.dotDivider}>•</p>
          </>}
          <p className={Styles.projectCost}>{workHistory.constructionValue ? moneyAsTextBySignificantFigures(workHistory.constructionValue): ""}</p>
        </section>

        <section className={Styles.projectTypeListContainer}>
          <p className={Styles.projectTypeList}>{workHistory.projectTypes?.map(projectType => projectTypeLabels[projectType]).join(', ')}</p>
        </section>

        <section className={Styles.validReferenceContainer}>
          <IonText color="primary"><p className={Styles.verifiedReferenceCount}>{getReferenceCount(workHistory)}</p></IonText>
        </section>

        <section className={Styles.photosContainer}>
          {completedWorkHistoryImages.slice(0,6).map((photo) => {
            return <DisplayUploadedFileAsPhoto photoSizeSmall={true} allowFullscreenImage={false} key={photo.id} uploadedFileId={photo.id} />
          })}
        </section>

        { workHistoryStatus === WorkHistoryStatus.VERIFIED
          ? <WeaverVerifiedBadge className='ion-margin-top ion-margin-end' />
          : <WeaverUnverifiedBadge className='ion-margin-top ion-margin-end' />
        }
      </IonLabel>
    </IonItem>
  )
}

export const SkeletonWorkHistoryItem = () => {
  return <IonItem lines='none'  className={Styles.workHistoryItem}>
    <IonAvatar slot="start" className={[ Styles.avatar, "ion-margin-top" ].join(" ")}>
      <IonIcon color="medium" icon={allIonIcons.home} />
    </IonAvatar>
    <IonLabel className={Styles.ionItemBody}>
      <h5><IonSkeletonText animated={true} ></IonSkeletonText></h5>

      <section className={[ Styles.constructionDatesAndValueContainer, Styles.workHistoryItemSection ].join(' ')}>
        <p className={Styles.ionItemSubtitle}><IonSkeletonText animated={true} /></p>
        <p className={Styles.dotDivider}><IonSkeletonText animated={true} /></p>
        <p><IonSkeletonText animated={true} /></p>
      </section>

      <section className={Styles.projectTypeListContainer}>
        <p className={Styles.projectTypeList}><IonSkeletonText animated={true} /></p>
      </section>

      <section className={Styles.validReferenceContainer}>
        <IonText color="primary"><p className={Styles.verifiedReferenceCount}><IonSkeletonText animated={true} /></p></IonText>
      </section>
    </IonLabel>
  </IonItem>
}
