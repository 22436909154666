import React, { useState } from 'react'
import { IonBadge, IonButton, IonIcon, IonItem } from '@ionic/react'
import { alwaysArray, isSomething } from '../../../common/utils'
import { allIonIcons } from '../../../common/components/IonIconTyped/icons'
import { projectTypeLabels } from '../../onboarding/team/onboarding.i18n'
import Styles from './ScreenWorkHistory.module.scss'
import { formatAddressToSingleLine } from '../../../common/utils/addresses'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import { usePublicContractorProfileScreenState } from './PublicContractorProfileScreenState'
import { PublicContractorProfileScreenWithRenderProps, PublicContractorScreenNames } from './PublicContractorProfile'
import ErrorBlock from '../../../common/components/ErrorBlock'
import { arrowBack } from 'ionicons/icons'
import GlobalHeader from '../../../common/components/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { UploadedFileStatus } from '../../../graphql/generated'
import { moneyAsTextBySignificantFigures } from '../../../common/utils/budgetTransformation'
import { ContractorWorkHistoryReference } from '../MyContractorProfilePage/ContractorWorkHistoryReference'
import FsLightbox from 'fslightbox-react'
import ThumbnailStyles from '../../../common/components/ThumbnailPhoto/ThumbnailPhoto.module.scss'

const ScreenWorkHistory: React.FC<PublicContractorProfileScreenWithRenderProps> = ({ profile, useChangeScreen }) => {

  const {
    workHistoryItemIdState: [ workHistoryItemId ],
  } = usePublicContractorProfileScreenState()

  const [ lightboxController, setLightboxController ] = useState({
    toggler: true,
    slide: 1,
  })

  const openLightBox = (index: number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: index,
    })}

  const changeScreenToProfileHome = useChangeScreen(PublicContractorScreenNames.Home)
  const ScreenWeaverIonHeader: React.FC = () =>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader
        pageTitle='Public Profile'
        navElement={
          <IonButton onClick={() => changeScreenToProfileHome()}>
            <IonIcon slot="start" icon={arrowBack} />
          </IonButton>
        }
      />
    </WeaverIonHeader>

  const workHistoryItem = alwaysArray(profile.workHistory).find(item => item.id === workHistoryItemId)
  console.debug(`[PublicContractorWorkHistoryPage] Render: `, { profile, workHistoryItemId, workHistoryItem })
  const images = alwaysArray(workHistoryItem?.photos).filter((img) => img && img.status !== UploadedFileStatus.Archived && isSomething(img.signedUrlForDownload.url))
  const documentPreviewUrls = images.map((doc) => doc.signedUrlForDownload?.url ?? '')

  if (workHistoryItem === undefined) {
    return <>
      <ScreenWeaverIonHeader />
      <WeaverIonContent>
        <ErrorBlock name='ScreenWorkHistory'><p>Unable to find Work History Item</p></ErrorBlock>
      </WeaverIonContent>
    </>
  }

  return <>
    <ScreenWeaverIonHeader />
    <WeaverIonContent>
      <h3>{formatAddressToSingleLine(workHistoryItem.projectAddress) ?? 'Work History'}</h3>

      <IonItem className={Styles.costOfWorkContainer}>
        <IonIcon slot='start' icon={allIonIcons.cashOutline} />
        <span>
          <p>Cost of Work</p>
          {workHistoryItem.constructionValue && <p>{moneyAsTextBySignificantFigures(workHistoryItem.constructionValue)}</p>}
        </span>
      </IonItem>

      <IonItem className={Styles.typeOfWorkContainer}>
        <IonIcon icon={allIonIcons.hammerOutline} slot="start" />
        <span>
          <p>Type</p>
          {alwaysArray(workHistoryItem.projectTypes).map(projectType => <IonBadge key={projectType}>{projectTypeLabels[projectType]}</IonBadge>)}
        </span>
      </IonItem>

      <h3>Reference</h3>
      {workHistoryItem?.references !== undefined
        ? alwaysArray(workHistoryItem.references)
          .map(reference => <ContractorWorkHistoryReference key={reference.id} reference={reference} />)
        : <p>No references have been uploaded</p>
      }

      <h3>Photos</h3>
      <div className={`${Styles.photoContainer} ${Styles.gridRowGap}`}>
        {workHistoryItem.photos ? alwaysArray(workHistoryItem.photos).filter(photo => photo.status !== UploadedFileStatus.Archived)
          .map((photo, index) =>
            <div key={photo.id} className={Styles.photoItemContainer}>
              <div data-uploadedfile={photo.id} className={`${ ThumbnailStyles.thumbnailPhotoContainer} ${Styles.success}`} onClick={() => openLightBox(index + 1)}>
                <img className={ThumbnailStyles.thumbnailPhoto} src={photo.signedUrlForDownload.url ?? ''} alt={"Thumbnail"} />
              </div>
            </div>,
          )
          : <p>No photos have been uploaded</p>
        }
      </div>
    </WeaverIonContent>

    <FsLightbox
      sources={documentPreviewUrls}
      type="image"
      toggler={lightboxController.toggler}
      slide={lightboxController.slide}
    />

  </>
}

export default ScreenWorkHistory
