import { DateTime } from "luxon"
import { WorkStartEstimate } from "../../graphql/generated"

/**
 * Get the i18n labels for `WorkStartEstimate`, based off a given `anchorDate`.
 * The anchorDate is offsetted into the future by 3 weeks and then generates the months from then for each enum value accordingly.
 *
 * @param anchorDate An optional date to based the calculation off. Default to `now`.
 * @returns Computed i18n mapping labels for `WorkStartEstimate`
 */
export const getWorkStartEstimateLabels = (anchorDate?: DateTime): Record<WorkStartEstimate, string> => {
  const defaultedDate = anchorDate ?? DateTime.now()
  const offsetDate = defaultedDate.plus({ days: 21 })

  return {
    [WorkStartEstimate.EndOfMonth]: `By the end of ${offsetDate.monthLong}`,
    [WorkStartEstimate.OneThreeMonths]: `${offsetDate.monthLong} - ${offsetDate.plus({ months: 2 }).monthLong}`,
    [WorkStartEstimate.ThreeSixMonths]: `${offsetDate.plus({ months: 3 }).monthLong} - ${offsetDate.plus({ months: 5 }).monthLong}`,
    [WorkStartEstimate.OverSixMonths]: `Later than ${offsetDate.plus({ months: 5 }).monthLong}`,
    [WorkStartEstimate.Unknown]: "Flexible. The priority is finding the right contractor",
  }
}
