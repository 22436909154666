import React from "react"
import { IonCol, IonGrid, IonImg, IonRow, IonText } from "@ionic/react"
import { budgetRangeLabels } from "../budgetRanges.i18n"
import { BudgetRange } from "../../../../../graphql/generated"
import Padlock from '../../../../../assets/icons/padlock.svg'
import Styles from './ScreenThisBudgetRangeIsLocked.module.scss'
import { useWeaverFlags } from "../../../../../api/thirdParty/launchDarkly/useWeaverFlags"

type ThisBudgetRangeIsLockedScreenProps = {
  budgetRange: BudgetRange,
}

const ThisBudgetRangeIsLockedScreen: React.FC<ThisBudgetRangeIsLockedScreenProps> = ({ budgetRange }) => {
  const { "MW-2604-copy-change-lead-to-tender": leadToTenderFlag } = useWeaverFlags()
  return <>
    <IonGrid className="ion-padding">
      <IonRow className='ion-justify-content-center ion-align-items-center'>
        <IonCol className='ion-margin-top ion-text-center'>
          <h5><b>This budget range is locked</b></h5>
          <h1><b> {budgetRangeLabels[budgetRange]} {leadToTenderFlag ? 'TENDERS' : 'LEADS'} </b></h1>
          <IonImg className={Styles.padlockImage} src={Padlock} />
          <IonText color="medium"> To unlock the budget range, complete the next steps. </IonText>
        </IonCol>
      </IonRow>
    </IonGrid>
  </>}

export default ThisBudgetRangeIsLockedScreen
