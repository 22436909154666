import { IonCard, IonCardContent, IonGrid, IonIcon, IonItem, IonItemDivider, IonRow, IonText } from '@ionic/react'
import { arrowForwardSharp, construct, home, person } from 'ionicons/icons'
import React from 'react'
import { useGraphQLDataSource } from '../../../../api/graphql'
import ErrorBlock from '../../../../common/components/ErrorBlock'
import LoadingSpinner from '../../../../common/components/LoadingSpinner'
import MemberAvatar from '../../../../common/components/MemberRow/MemberAvatar'
import { alwaysArray } from '../../../../common/utils'
import { UploadedFileStatus, useGetContractorProfileQuery } from '../../../../graphql/generated'
import { getWeaverStats } from '../../../profile/ContractorLeadPreferences/ContractorBudgetRanges/datasource'
import { PhotosHighlightsSection } from '../../../profile/PhotosHighlightsSection'
import Styles from './ContractorDetailsCard.module.scss'
import { useWeaverFlags } from '../../../../api/thirdParty/launchDarkly/useWeaverFlags'

type ContractorDetailsCardProps = {
  id: string | undefined,
}

export const ContractorDetailsCard: React.FC<ContractorDetailsCardProps> = ({ id }) => {
  if (!id) throw Error("Missing required param contractor id")
  const weaverFlags = useWeaverFlags()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data, error, isLoading, refetch } = useGetContractorProfileQuery(gqlDataSource, { contractorTeamId: id }, { staleTime: 5 * 60 * 1000 })
  const contractorProfile = data?.getContractorProfile
  const stats = getWeaverStats(data?.getContractorProfile?.workHistory)
  const completedWorkHistoryImages = weaverFlags['MW-2614-handle-archivable-items-on-server']
    ? alwaysArray(data?.getContractorProfile?.workHistory?.flatMap(w => w.photos))
    : alwaysArray(data?.getContractorProfile?.workHistory?.filter(w => !w.isArchived).flatMap(w => w.photos)).filter(p => p.status !== UploadedFileStatus.Archived)

  if (isLoading) {
    return <LoadingSpinner name="ContractorProfileCard" />
  }

  if (error) {
    console.error(`[ContractorProfileCard] Failed to load ContractorProfile`, { id })
    return <ErrorBlock name='ContractorProfileCard.failed' onRefresh={refetch}/>
  }

  if (contractorProfile == null) {
    return <ErrorBlock name='ContractorProfileCard.missing'><p>Unable to fetch contractor card data</p></ErrorBlock>
  }

  return (
    <div className={Styles.cardContainer}>
      <IonCard className={`${Styles.card} ion-margin-top ion-no-padding`}>
        <IonCardContent>
          <IonItem className={`${Styles.avatarDetailItem} ion-no-padding`} lines='none'>
            <MemberAvatar slot="start" title={contractorProfile?.companyTradingAs} />
            <div>
              <span className={Styles.name}>{contractorProfile?.companyTradingAs}</span>
              <p className={Styles.teamType}>Main Contractor</p>
            </div>
          </IonItem>
          <section className={Styles.weaverStatsContainer}>
            <div className={Styles.statRowContainer}>
              <IonIcon className={Styles.statIcon} color='primary' src={construct} size="medium" />
              <p>{stats?.pastProjectsCopy}</p>
            </div>
            {stats?.recentCompletedProjectCountCopy && <div className={Styles.statRowContainer}>
              <IonIcon className={Styles.statIcon} color='primary' src={home} />
              <p>{stats?.recentCompletedProjectCountCopy}</p>
            </div>}
            {stats?.referenceCountCopy && <div className={Styles.statRowContainer}>
              <IonIcon className={Styles.statIcon} color='primary' src={person} />
              <p>{stats?.referenceCountCopy}</p>
            </div>}
          </section>

          <PhotosHighlightsSection photos={completedWorkHistoryImages}  />

        </IonCardContent>
        <IonItemDivider className={Styles.divider} color={'light'} />
        <IonGrid className='ion-padding-start'>
          <IonRow>
            <div className={Styles.cardFooter}>
              <div>
                <IonText>View profile</IonText>
              </div>
              <div>
                <IonIcon className='ion-text-end' slot='end' color='primary' size='small' icon={arrowForwardSharp}/>
              </div>
            </div>
          </IonRow>
        </IonGrid>
      </IonCard>
    </div>
  )
}
