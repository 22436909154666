import React from 'react'
import { GetContractorProfileQuery } from '../../graphql/generated'
import { DisplayUploadedFileAsPhoto } from './ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/WorkHistoryPhotos/DisplayUploadedFileAsPhoto'
import Styles from './PhotosHighlightsSection.module.scss'
type PhotoHighlightsSectionProps = {
  photos : NonNullable<NonNullable<GetContractorProfileQuery['getContractorProfile']>['workHistory']>[number]['photos'],
}

export const PhotosHighlightsSection: React.FC<PhotoHighlightsSectionProps> = ({ photos }) => {
  return (
    <section className={Styles.photosContainer}>
      {photos?.slice(0,6).map((photo) => {
        return <DisplayUploadedFileAsPhoto photoSizeSmall={true} allowFullscreenImage={false} key={photo?.id} uploadedFileId={photo.id} />
      })}
    </section>
  )
}
