import { zodResolver } from "@hookform/resolvers/zod"
import { IonNote, IonContent } from "@ionic/react"
import React from "react"
import { useForm, Controller } from "react-hook-form"
import { z } from "zod"
import GenericSelectorList from "../../../../common/components/GenericSelectorList"
import { getGenericSelectorOptionsForEnum } from "../../../../common/components/GenericSelectorList/helpers"
import { requireSomething } from "../../../../common/utils"
import { ScreenStartMatchingContext } from "./common"
import SingleClickButton from "../../../../common/components/SingleClickButton"
import { ProjectType } from "../../../../graphql/generated"
import { projectTypeLabels } from "../../../onboarding/team/onboarding.i18n"

const zFormSchema = z.object({
  projectTypes: z.array(z.nativeEnum(ProjectType)).min(1),
})

type FormSchema = z.infer<typeof zFormSchema>

const EditProjectTypeModal: React.FC = () => {
  const { project, updateProject, cancel } = requireSomething( React.useContext(ScreenStartMatchingContext) )
  const { handleSubmit, control, formState: { errors } } = useForm<FormSchema>({ resolver: zodResolver(zFormSchema), defaultValues: {
    projectTypes: project.projectTypes,
  } })

  const onSubmit = async (data: FormSchema) => {
    await updateProject.mutateAsync({ input: {
      id: project.id,
      projectTypes: data.projectTypes,
    } })
  }
  return <>
    <IonContent className="ion-padding">
      <p>We will match you with contractors who have relevant work experience. Select all that apply.</p>
      <div>
        <Controller
          control={control}
          name="projectTypes"
          render={({
            field: { onChange, value },
            fieldState: { error },
          }) => (<div>
            <GenericSelectorList
              options={getGenericSelectorOptionsForEnum(ProjectType, projectTypeLabels)}
              selected={value || []}
              multiSelect={true}
              onSelect={(value) => onChange(value)}
              showItemDetail={false}
              colSize={'6'}
              render={({ value }) => <p>{projectTypeLabels[value]}</p>}
            />

            {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
          </div>
          )}
        />
      </div>

      <div className='ion-padding-horizontal ion-no-border ion-margin-bottom'>
        <SingleClickButton  expand="block" onClick={handleSubmit(onSubmit)}>Confirm</SingleClickButton>
      </div>
    </IonContent>

  </>
}

export default EditProjectTypeModal
