import React, { useCallback, useEffect, useState } from "react"
import { IonAccordion, IonAccordionGroup, IonButton, IonCard, IonIcon, IonItem, IonSpinner, useIonAlert, useIonRouter } from "@ionic/react"
import { alertCircleOutline, checkmarkCircle, eye } from "ionicons/icons"
import { useGraphQLDataSource } from "../../api/graphql"
import Styles from "./ShowLeadPage.module.scss"
import { useShowLeadQuery, useRejectLeadMutation, ProjectMemberRole, useListMyProjectsAndLeadsQuery, TeamType, UploadedFileKind, UploadedFileStatus, ContentDisposition } from "../../graphql/generated"
import GlobalHeader from "../../common/components/GlobalHeader/GlobalHeader"
import GlobalHeaderStyles from '../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { alwaysArray, getEnumLabel, isSomething, uniqueFilter } from "../../common/utils"
import { Duration, DateTime } from "luxon"
import { getWorkStartEstimateLabels } from "./workEstimate.i18n"
import { pageConfig_Projects_AcceptLead_PaymentPlanPicker, useRouteTo } from "../../routes"
import { useParamsFromPageConfig } from "../../routesProvider"
import LoadingSpinnerPage from "../../common/components/LoadingSpinner/LoadingSpinnerPage"
import WeaverIonPage from "../../common/components/WeaverIonWrappers/WeaverIonPage"
import WeaverIonContent from "../../common/components/WeaverIonWrappers/WeaverIonContent"
import WeaverIonHeader from "../../common/components/WeaverIonWrappers/WeaverIonHeader"
import { useWeaverFlags } from "../../api/thirdParty/launchDarkly/useWeaverFlags"
import { useMyIndividualActiveTeam } from "../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { getDocumentAndDocumentPreviewData, getFlexibleProjectBudgetAsMoneyOrMoneyRange, getFlexibleProjectBudgetAsText } from "./common"
import { useAnalyticsEvent } from "../../api/providers/SegmentProvider/hooks"
import GoogleMapFromWeaverAddress from "../../common/components/GoogleMapFromWeaverAddress.tsx/GoogleMapFromWeaverAddress"
import { flexibleBudgetTextToSignificantFigures } from "../../common/utils/budgetTransformation"
import { getBudgetRangeByMoney } from "../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges"
import { teamTypeLabels } from "../../common/components/InviteMemberModal/inviteMemberTeamTypeLabels.i18n"
import { LeadErrorPage } from "./LeadErrorPage"
import { AcceptLeadButton } from "./AcceptLeadButton/AcceptLeadButton"
import { CountDownTimerProps, useCountDown } from "../../common/hooks/useCountDown"
import { useQueryClient } from "@tanstack/react-query"
import { leadDocumentsKindTypeLabels } from "./leadDocumentsKindTypeLabels.i18n"
import WeaverIonFooter from "../../common/components/WeaverIonWrappers/WeaverIonFooter"
import FsLightbox from 'fslightbox-react'
import { propertyTypeLabels } from "./commonProjectLabels.i18n"
import { finishLabels, specialtiesLabels } from "./projectTaskLabels.i18n"
import { TENDER_AND_PROJECT_PROPERTIES } from "./projectAndTender.i18n"
import { ExpandedProjectDescription } from "./ExpandedProjectDescription"

const LeadCountDownTimer: React.FC<CountDownTimerProps> = props => {
  const countDown = useCountDown(props)
  const { "MW-2604-copy-change-lead-to-tender": leadToTenderFlag } = useWeaverFlags()
  return countDown === null
    ? null
    : <div>
      {leadToTenderFlag ? 'Tender' : 'Lead'} expires in
      <span className={Styles.countDownTextColor}>{countDown}</span>
    </div>
}

const TENDER_PACK_IN_PROGRESS_HEADER = "Tender pack in progress"
const TENDER_PACK_IN_PROGRESS_CONTENT = [ "Join an early stage project, offering the unique opportunity to collaborate with the client/architect and shape the tender pack.", "Protected by Weaver Satisfaction Money-Back Guarantee. If for any reason you are not satisfied with your experience, we will refund you in full." ]

const ShowLead: React.FC = () => {
  const queryClient = useQueryClient()
  const weaverFlags = useWeaverFlags()
  const triggerEvent_Project_Lead_Responded_Rejected = useAnalyticsEvent("Project_Lead_Responded_Rejected")
  const triggerEvent_Lead_Accepted_Clicked = useAnalyticsEvent("Lead_Accept_Clicked")

  const myTeam = useMyIndividualActiveTeam()

  const { id } = useParamsFromPageConfig<{ id: string }>()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const [ present ] = useIonAlert()
  const router = useIonRouter()

  const showLeadQuery = useShowLeadQuery(gqlDataSource, { id, config: { disposition: ContentDisposition.Attachment, transformation: { width: 1000, height: 1000 } } },
    {
      refetchOnWindowFocus: false,
      retry: false,
      select: ({ getLead }) => {
        // HACK: Consider replacing this with actual response unions/detecting the difference between an error and returning null
        if (!getLead) throw new Error("Lead not found")
        return { getLead }
      },
    })
  const routeToPaymentPlanPicker = useRouteTo(pageConfig_Projects_AcceptLead_PaymentPlanPicker.path)
  const rejectLeadMutation = useRejectLeadMutation(gqlDataSource, { onSuccess: async () => {
    if (weaverFlags["MW-2415-lead-cache-busting"]) {
      await queryClient.invalidateQueries(useListMyProjectsAndLeadsQuery.getKey())
    }
  } })
  const [ rejectLoading, setRejectLoading ] = useState<boolean>(false)
  const [ lightboxController, setLightboxController ] = useState({
    toggler: true,
    slide: 1,
  })
  const triggerEvent_Lead_Viewed = useAnalyticsEvent("Lead_Viewed")
  const triggerEvent_DocumentPreview_Viewed = useAnalyticsEvent("Lead_Document_Preview_Clicked")

  useEffect(() => {
    const lead = showLeadQuery.data?.getLead
    if (!lead) return console.error('[ShowLeadPage]: Tender loading or not found')

    const leadBudgetRange = getBudgetRangeByMoney(lead?.budgetValue)
    const { documentPreviewCount, activeDocuments, allDocumentTags } = getDocumentAndDocumentPreviewData(lead)

    if (lead?.id) {
      triggerEvent_Lead_Viewed({
        projectId: id,
        titleOfProject: lead.title,
        description: lead.description,
        budgetShownToContractor: getFlexibleProjectBudgetAsText(lead, myTeam, weaverFlags["MW-2600-expand-project-creation-flow"], weaverFlags['MW-2645-adjust-flex-budget-ranges']) ?? undefined,
        workStartEstimate: getWorkStartEstimateLabels()[lead.workStartEstimate],
        /** @deprecated MW-2386-remove-tender-return-date - MAY STILL BE USED BY C.IO AND CAUSE FAILURE IF REMOVED */
        tenderReturnDate: undefined,
        type: lead.projectTypes,
        budgetRange: leadBudgetRange,
        documentPreviewCount: documentPreviewCount,
        documentCount: activeDocuments.length,
        allDocumentTags: alwaysArray(allDocumentTags),
        pptCount: lead.pptCount ?? 0,
        subscriberCount: lead.subscriberCount ?? 0,
      })
    }
  }, [ showLeadQuery.data?.getLead.id ])

  /** @deprecated remove with flag: MW-2360-block-contractor-accepting-lead  **/
  const acceptLead = useCallback(async () => {
    const lead = showLeadQuery.data?.getLead
    if (!lead) return console.error('[ShowLeadPage]: Tender not found')

    const leadBudgetRange = getBudgetRangeByMoney(lead?.budgetValue)
    const { documentPreviewCount, activeDocuments, allDocumentTags } = getDocumentAndDocumentPreviewData(lead)

    await triggerEvent_Lead_Accepted_Clicked({
      projectId: id,
      budgetRange: leadBudgetRange,
      documentPreviewCount: documentPreviewCount,
      documentCount: activeDocuments.length,
      allDocumentTags: alwaysArray(allDocumentTags),
    })

    routeToPaymentPlanPicker({ leadId: id })()
  }, [ id, showLeadQuery.data?.getLead.budgetCategory ])

  const rejectLead = useCallback(async () => {
    setRejectLoading(true)
    try {
      await rejectLeadMutation.mutateAsync({ id })
      await triggerEvent_Project_Lead_Responded_Rejected({
        projectId: id,
        titleOfProject: lead.title,
        description: lead.description,
        budgetShownToContractor: getFlexibleProjectBudgetAsText(lead, myTeam, weaverFlags["MW-2600-expand-project-creation-flow"], weaverFlags['MW-2645-adjust-flex-budget-ranges']) ?? undefined,
        workStartEstimate: getWorkStartEstimateLabels()[lead.workStartEstimate],
        /** @deprecated MW-2386-remove-tender-return-date - MAY STILL BE USED BY C.IO AND CAUSE FAILURE IF REMOVED */
        tenderReturnDate: undefined,
        type: lead.projectTypes,
      })
    } catch (e) {
      if (e instanceof Error) {
        present({
          header: `Unable to Reject ${weaverFlags['MW-2604-copy-change-lead-to-tender'] ? 'Tender' : 'Lead'}`,
          message: e.message,
          buttons: [
            {
              text: "Dismiss",
              role: 'cancel',
            },
          ],
        })
      }
    }

    setRejectLoading(false)
    router.push("/projects")
  }, [ rejectLeadMutation, id, setRejectLoading, router ])

  if (showLeadQuery.isFetching && !showLeadQuery.data) {
    return <LoadingSpinnerPage name="ShowLoadPage.showLeadQuery" />
  }

  if (showLeadQuery.error || !showLeadQuery.data) {
    return <LeadErrorPage name='ShowLeadPage.showLeadQuery' />
  }

  const { data: { getLead: lead } } = showLeadQuery

  const loading = rejectLoading

  const randomisedContractorNumber = Math.floor(Math.random() * 14) + 6

  const documentKinds = weaverFlags['MW-2614-handle-archivable-items-on-server']
    ? lead?.documents?.flatMap((item) => item.kind).filter(uniqueFilter).filter(i => i!= null)
    : lead?.documents?.filter((doc) => doc.status !== UploadedFileStatus.Archived).flatMap((item) => item.kind).filter(uniqueFilter).filter(i => i!= null)

  const documentPreviews = weaverFlags['MW-2614-handle-archivable-items-on-server']
    ? alwaysArray(lead.documentPreviews).filter((doc) => doc && isSomething(doc.signedUrlForDownload?.url))
    : lead.documentPreviews
      ? lead.documentPreviews
        .filter((doc) => doc && doc.status !== UploadedFileStatus.Archived && isSomething(doc.signedUrlForDownload?.url))
      : []

  const documentPreviewUrls = documentPreviews.map((doc) => doc.signedUrlForDownload?.url ?? '')

  /** Calculates the Leftover Time from the lead against the FOMO time needed  */
  const FOMO_TIMEOUT_IN_SECS =  Duration.fromObject({ milliseconds: 43200000 })
  const timeSinceLeadSentInSeconds = DateTime.fromISO(lead.createdAt).diff(DateTime.now())
  const timeLeftInTimer = FOMO_TIMEOUT_IN_SECS.plus(timeSinceLeadSentInSeconds)
  const isInFomoTimeOut = (timeLeftInTimer.milliseconds > 0) ? true : false

  const openLightBox = () => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: 1,
    })}

  const fireDocumentPreviewViewed = () => {
    triggerEvent_DocumentPreview_Viewed({
      projectId: id,
      titleOfProject: lead.title,
      description: lead.description,
      budgetShownToContractor: getFlexibleProjectBudgetAsText(lead, myTeam, weaverFlags["MW-2600-expand-project-creation-flow"], weaverFlags['MW-2645-adjust-flex-budget-ranges']) ?? undefined,
      workStartEstimate: getWorkStartEstimateLabels()[lead.workStartEstimate],
      tenderReturnDate: undefined,
      type: lead.projectTypes,
      documentPreviewCount: documentPreviewUrls.length,
    })
  }

  const showEarlyProjectAlert = !alwaysArray(documentKinds).includes(UploadedFileKind.Technical)

  return <WeaverIonPage id='ShowLeadPage'>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader pageTitle={`${weaverFlags['MW-2604-copy-change-lead-to-tender'] ? 'Tender' : 'Lead'} - ${lead.projectTypes.map(x => {return  ' ' + getEnumLabel(x)})}`} />
    </WeaverIonHeader>
    <WeaverIonContent>
      <IonCard className={Styles.card}>
        {lead.address && <GoogleMapFromWeaverAddress address={lead.address} zoomLevel={13} />}

        { weaverFlags['MW-2387-fomo-lead-acceptance']
          ? <IonItem className={`${Styles.fomoContractors}`}>
            <IonIcon className="ion-padding" color="danger" icon={eye}/>
            <div>{randomisedContractorNumber} contractors are looking at this {weaverFlags['MW-2604-copy-change-lead-to-tender'] ? 'tender' : 'lead'} for 3 tender places</div>
          </IonItem>
          :<></>
        }

        {(weaverFlags["MW-2616-tender-and-show-project-ui-updates"] && lead?.propertyType) &&
        <IonItem className={`ion-margin-top`}>
          <div className={Styles.row}>
            <div>{TENDER_AND_PROJECT_PROPERTIES.PROPERTY}</div>
          </div>
          <div className={Styles.row}>{propertyTypeLabels[lead.propertyType]}</div>
        </IonItem>
        }

        <IonItem className={`ion-margin-top`}>
          <div className={Styles.row}>
            <div>{TENDER_AND_PROJECT_PROPERTIES.PROJECT_TYPE}</div>
          </div>
          <div className={Styles.row}>
            {lead.projectTypes.map(getEnumLabel).join(', ')}
          </div>
        </IonItem>

        {(weaverFlags["MW-2616-tender-and-show-project-ui-updates"] && lead?.specialties)  &&
        <IonItem className={`ion-margin-top`}>
          <div className={Styles.row}>
            <div>{TENDER_AND_PROJECT_PROPERTIES.SPECIALTY}</div>
          </div>
          <div className={Styles.row}>
            {lead.specialties.map((specialty) => specialtiesLabels[specialty]).join(', ')}
          </div>
        </IonItem>
        }

        <IonItem>
          <div className={Styles.row}>
            <div>{weaverFlags["MW-2616-tender-and-show-project-ui-updates"] ? TENDER_AND_PROJECT_PROPERTIES.CLIENT_ESTIMATED_BUDGET : 'Budget'}</div>
          </div>
          <div className={Styles.row}>
            {flexibleBudgetTextToSignificantFigures(getFlexibleProjectBudgetAsMoneyOrMoneyRange(lead, myTeam, weaverFlags['MW-2600-expand-project-creation-flow'], weaverFlags['MW-2645-adjust-flex-budget-ranges']))}
          </div>
        </IonItem>

        {(weaverFlags["MW-2616-tender-and-show-project-ui-updates"] && lead.finish) &&
        <IonItem className={`ion-margin-top`}>
          <div className={Styles.row}>
            <div>{TENDER_AND_PROJECT_PROPERTIES.FINISHES}</div>
          </div>
          <div className={Styles.row}>
            {finishLabels[lead.finish] }
          </div>
        </IonItem>
        }

        <IonItem>
          <div className={Styles.row}>
            <div>{TENDER_AND_PROJECT_PROPERTIES.WORK_START_ESTIMATE}</div>
          </div>
          <div className={Styles.row}>
            {getWorkStartEstimateLabels()[lead.workStartEstimate]}
          </div>
        </IonItem>

        <IonItem>
          <div className={Styles.row}>
            <div>{weaverFlags['MW-2616-tender-and-show-project-ui-updates'] ? TENDER_AND_PROJECT_PROPERTIES.CONTACT_PERSON : 'Project Led By'}</div>
          </div>
          <div className={Styles.row}>
            {
              lead.members
                .filter(member => member.projectRole === ProjectMemberRole.Owner && member.team.type !== TeamType.Partner)
                .filter(uniqueFilter)
                .map(member => teamTypeLabels[member.team.type])
                .join(', ')
            }
          </div>
        </IonItem>

        {weaverFlags["MW-2659-project-description-ui-improvements"]
          ? <>
            <div className={Styles.descriptionLabel}>{TENDER_AND_PROJECT_PROPERTIES.FURTHER_DESCRIPTION}</div>
            <ExpandedProjectDescription description={lead.description || "none"} characterLimit={275} />
          </>
          : (
            <>
              <IonItem>
                <div>{TENDER_AND_PROJECT_PROPERTIES.FURTHER_DESCRIPTION}</div>
              </IonItem>
              <div className={`${Styles.descriptionText} ion-padding-start`}>
                {lead.description || "none"}
              </div>
            </>
          )
        }

        <div className={Styles.tenderReadinessContainer}>
          <IonItem className={Styles.tenderReadinessTitle}>
            {TENDER_AND_PROJECT_PROPERTIES.AVAILABLE_DOCUMENTS}
          </IonItem>
          <IonItem>
            <div className={Styles.tenderReadinessText}>
              <div>
                <IonIcon color={alwaysArray(documentKinds).includes(UploadedFileKind.Planning) || alwaysArray(documentKinds).includes(UploadedFileKind.Technical) ? 'success': 'light'}  icon={checkmarkCircle} slot="start" />
                {leadDocumentsKindTypeLabels.Planning}
              </div>
              <div>
                <IonIcon color={alwaysArray(documentKinds).includes(UploadedFileKind.Structural) ? 'success': 'light'}  icon={checkmarkCircle} slot="start" />
                {leadDocumentsKindTypeLabels.Structural}
              </div>
              <div>
                <IonIcon color={alwaysArray(documentKinds).includes(UploadedFileKind.Technical) ? 'success': 'light'}  icon={checkmarkCircle} slot="start" />
                {leadDocumentsKindTypeLabels.Technical}
              </div>
              <div>
                <IonIcon color={alwaysArray(documentKinds).includes(UploadedFileKind.Sow) ? 'success': 'light'}  icon={checkmarkCircle} slot="start" />
                {leadDocumentsKindTypeLabels.SOW}
              </div>
            </div>
          </IonItem>
        </div>

        {weaverFlags['MW-2616-tender-and-show-project-ui-updates'] && showEarlyProjectAlert &&
        <div>
          <IonAccordionGroup expand="inset">
            <IonAccordion className={Styles.tenderPackAccordionContainerBorder}>
              <IonItem slot="header" className={Styles.tenderPackAccordingBackgroundColor}>
                <IonIcon color="warning" className={Styles.tenderPackAlertIcon} icon={alertCircleOutline} slot="start" />
                <p className={Styles.tenderPackHeader}>{TENDER_PACK_IN_PROGRESS_HEADER}</p>
              </IonItem>
              <div className={`ion-padding ${Styles.tenderPackAccordingBackgroundColor}`} slot="content">
                <p className={Styles.tenderPackFirstParagraphNoMarginTop}>{TENDER_PACK_IN_PROGRESS_CONTENT[0]}</p>
                <p>{TENDER_PACK_IN_PROGRESS_CONTENT[1]}</p>
              </div>
            </IonAccordion>
          </IonAccordionGroup>
        </div>
        }

        {documentPreviewUrls.length > 0 && weaverFlags["MW-2609-tender-doc-preview-on-lead"]
          ? <div className={Styles.documentPreviewPadding}>
            <p className={Styles.previewTitle}>Preview {documentPreviewUrls.length === 1 ? '1 document' : `${ documentPreviewUrls.length } documents` }</p>
            <div className={Styles.outerContainer}>
              <img className={Styles.docPreviewImage}
                src={documentPreviewUrls[0]}
                onClick={() => { openLightBox(), fireDocumentPreviewViewed() }}>
              </img>
            </div>
          </div>
          : null}

        { weaverFlags['MW-2387-fomo-lead-acceptance']
          ? <IonItem>
            {isInFomoTimeOut ? <LeadCountDownTimer duration={timeLeftInTimer}/> : null}
          </IonItem>
          :<></>
        }
        {!weaverFlags["MW-2609-tender-doc-preview-on-lead"] &&
        <div className={Styles.acceptRejectButtons}>
          {weaverFlags['MW-2360-block-contractor-accepting-lead']
            ? <AcceptLeadButton lead={lead} loading={loading} source="ShowLeadPage" />
            : <IonButton expand='block' disabled={loading} onClick={acceptLead} color="primary">
              Accept {weaverFlags['MW-2604-copy-change-lead-to-tender'] ? 'Tender' : 'Lead'}
            </IonButton>}
          <IonButton expand='block' fill="outline" disabled={loading} onClick={rejectLead} color="primary">
            {rejectLoading ? <IonSpinner /> : <>Reject</>}
          </IonButton>
        </div>
        }

      </IonCard>
    </WeaverIonContent>
    {weaverFlags["MW-2609-tender-doc-preview-on-lead"] &&
    <WeaverIonFooter className={Styles.stickyButtonLeadAcceptReject}>
      <div className={Styles.acceptRejectButtons}>
        {weaverFlags['MW-2360-block-contractor-accepting-lead']
          ? <AcceptLeadButton lead={lead} loading={loading} source="ShowLeadPage" />
          : <IonButton expand='block' disabled={loading} onClick={acceptLead} color="primary">
            Accept {weaverFlags['MW-2604-copy-change-lead-to-tender'] ? 'Tender' : 'Lead'}
          </IonButton>}
        <IonButton expand='block' fill="outline" disabled={loading} onClick={rejectLead} color="primary">
          {rejectLoading ? <IonSpinner /> : <>Reject</>}
        </IonButton>
      </div>
      <FsLightbox sources={documentPreviewUrls} type="image" toggler={lightboxController.toggler} slide={lightboxController.slide}/>
    </WeaverIonFooter>
    }

  </WeaverIonPage>
}

export default ShowLead
