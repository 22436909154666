import { zodResolver } from "@hookform/resolvers/zod"
import { IonNote, IonContent } from "@ionic/react"
import React from "react"
import { useForm, Controller } from "react-hook-form"
import { z } from "zod"
import GenericSelectorList from "../../../../common/components/GenericSelectorList"
import { getGenericSelectorOptionsForEnum } from "../../../../common/components/GenericSelectorList/helpers"
import { requireSomething } from "../../../../common/utils"
import { WorkStartEstimate } from "../../../../graphql/generated"
import { getWorkStartEstimateLabels } from "../../../projects/workEstimate.i18n"
import { ScreenStartMatchingContext } from "./common"
import SingleClickButton from "../../../../common/components/SingleClickButton"

const zFormSchema = z.object({
  workStartEstimate: z.nativeEnum(WorkStartEstimate),
})

type FormSchema = z.infer<typeof zFormSchema>

const EditWorkStartEstimateModal: React.FC = () => {
  const { project, updateProject, cancel } = requireSomething( React.useContext(ScreenStartMatchingContext) )
  const { handleSubmit, control } = useForm<FormSchema>({ resolver: zodResolver(zFormSchema), defaultValues: {
    workStartEstimate: project.workStartEstimate,
  } })

  const onSubmit = async (data: FormSchema) => {
    await updateProject.mutateAsync({ input: {
      id: project.id,
      workStartEstimate: data.workStartEstimate,
    } })
  }
  return <>
    <IonContent className="ion-padding">
      <p>Construction timeline ensures matched contractors availability for the project.</p>
      <Controller
        control={control}
        name="workStartEstimate"
        render={({
          field: { onChange, value },
          fieldState: { error },
        }) => (<div>
          <GenericSelectorList
            options={getGenericSelectorOptionsForEnum(WorkStartEstimate, getWorkStartEstimateLabels())}
            selected={value}
            onSelect={(value) => onChange(value)}
            showItemDetail={false}
            colSize="12"
            render={({ value }) => <p><div key={value} id={value}>{getWorkStartEstimateLabels()[value]}</div></p>}
          />
          {error ? <IonNote color='danger'>{error.message}</IonNote> : null}
        </div>
        )} />
      <div className='ion-padding-horizontal ion-no-border ion-margin-bottom'>
        <SingleClickButton  expand="block" onClick={handleSubmit(onSubmit)}>Confirm</SingleClickButton>
      </div>
    </IonContent>

  </>
}

export default EditWorkStartEstimateModal
