import { MyIndividualActiveTeam, useMyIndividualActiveTeam } from "../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { parseIconName } from "../components/IonIconTyped/icons"
import { multiSort, sortBy } from "../sort"
import { ListProjectTasksQuery, TaskStatus, TeamType, useSetTaskStatusMutation } from "../../graphql/generated"
import { Duration } from "luxon"
import { useListProjectTasksQuery } from "../../graphql/generated"
import { useAsyncQuery } from "../hooks/query"
import { useGraphQLDataSource } from "../../api/graphql"
import { useAchievementToast } from "../../api/providers/AchievementToastProvider/AchievementToastProvider"

type BaseTask = ListProjectTasksQuery["listProjectTasks"][number]

type TaskWithoutChildrenProp = Omit<BaseTask, "childTasks">
type TaskWithChildrenProp = TaskWithoutChildrenProp & { "childTasks": AnyLevelTask[] }
type AnyLevelTask = TaskWithChildrenProp | TaskWithoutChildrenProp
export type NormalizedTask = TaskWithChildrenProp & { depth: number }

const normalizeTask = (task: AnyLevelTask, depth: number): NormalizedTask => {
  const childTasks = ("childTasks" in task && task.childTasks) ? task.childTasks : []

  return {
    ...task,
    childTasks,
    depth,
  }
}

const isPendingTask = (task: AnyLevelTask) => !([ TaskStatus.Completed, TaskStatus.Skipped, TaskStatus.Disabled ].includes(task.status))

/**
 * Extract the "current" top-level task. This should be the highest-ordered inProgress item, or the first in the list.
*/
export const getCurrentTopLevelTask = (allTasks: AnyLevelTask[]): AnyLevelTask | undefined => {
  const inProgressTasks = allTasks.filter((task) => task.status === TaskStatus.InProgress)

  // Always take the last inProgress task, or the first task if none available
  return inProgressTasks.slice(-1)[0] || allTasks[0]
}

/** Unpacks a tree of tasks into a sorted flat list.
 * Also adds depth & childTask fields to all tasks.
 *
 * Note: `order` is a relative value and is not normalized, so it can't be used to sort tasks after flattening.
 */
export const unpackTasksWithDepth = (tasks: AnyLevelTask[], depth = 0): NormalizedTask[] => {
  const sorted = multiSort(tasks, [ sortBy("order"), sortBy("title") ])

  return sorted.reduce((acc, task) => {
    const unpackedParent = normalizeTask(task, depth)

    const unpackedChildren = unpackTasksWithDepth(unpackedParent.childTasks, depth + 1)

    return acc.concat([ unpackedParent ], unpackedChildren)
  }, [] as NormalizedTask[])
}

export const filterMyProjectTasks = (projectTasks: AnyLevelTask[] = [], myInvidividualActiveTeam: MyIndividualActiveTeam) => {
  const isMyTask = getMyTasksFilter(myInvidividualActiveTeam)
  const unpacked = unpackTasksWithDepth(projectTasks).filter(task => {
    if (!isMyTask(task)) return false

    // It can't be your task if it has children (because it's not directly completable)
    if (task.childTasks.length > 0) return false

    // top level tasks shouldn't be owned as they should always contain children
    if (task.depth === 0) return false

    return true
  })

  const completedTasks = unpacked.filter(n => !isPendingTask(n))
  const pendingTasks = unpacked.filter(n => isPendingTask(n))
  const myTasksCount = completedTasks.length + pendingTasks.length

  return {
    myTasksCount,
    completedTasks,
    pendingTasks,
  }
}

const getMyTasksFilter = (myInvidividualActiveTeam: MyIndividualActiveTeam) => (task: AnyLevelTask) => {
  // it's not your task if it's assigned and it's assigned to someone else
  if (task.assignedTeamId && task.assignedTeamId !== myInvidividualActiveTeam.id) return false

  // contractors cannot action unassigned tasks
  if (myInvidividualActiveTeam?.type === TeamType.Contractor && !task.assignedTeamId) return false

  return true
}

/** binds the MyTasksFilter to the current MyIndividualActiveTeam
 *
 * Useful for determining historically actionable tasks (e.g. uncompleteable)
 */
export const useMyTasksFilter = () => {
  const myInvidividualActiveTeam = useMyIndividualActiveTeam()
  if (!myInvidividualActiveTeam) throw new Error("useFilterActionableTasks requires an active team")

  return getMyTasksFilter(myInvidividualActiveTeam)
}

const getActionableTaskFilter = (myInvidividualActiveTeam: MyIndividualActiveTeam) => {
  const isMyTask = getMyTasksFilter(myInvidividualActiveTeam)
  return (task: AnyLevelTask) => {
    if (!isPendingTask(task)) return false

    if (!isMyTask(task)) return false

    // don't show tasks with children (not directly completable)
    const childTasks = ("childTasks" in task && task.childTasks) ? task.childTasks : []
    if (childTasks.length > 0) return false

    return true
  }
}

/**
 * binds a function that removes tasks that the current user cannot interact with.
 * This function does not unpack child tasks from parent Tasks
 *
 * Use this to determine "next step" tasks and also tasks which can be routed to the taskActioner.
 */
export const useActionableTaskFilter = () => {
  const myInvidividualActiveTeam = useMyIndividualActiveTeam()
  if (!myInvidividualActiveTeam) throw new Error("useFilterActionableTasks requires an active team")

  return getActionableTaskFilter(myInvidividualActiveTeam)
}

const DEFAULT_ICON = parseIconName("checkmarkCircleOutline")

/** Get the icon for a task, including skipped and completed states */
export const getTaskIcon = (icon: string | null | undefined, task: AnyLevelTask): ReturnType<typeof parseIconName>  => {
  if (TaskStatus.Completed === task.status) return 'checkmarkOutline'
  if (TaskStatus.Skipped === task.status) return 'playForward'
  return icon ? parseIconName(icon) : DEFAULT_ICON
}

/** Returns a function that selects the next actionable task from a tree of tasks */
export const useNextTaskSelector = () => {
  const isTaskActionable = useActionableTaskFilter()

  return (tasks: AnyLevelTask[]): NormalizedTask | undefined => {
    const filtered = unpackTasksWithDepth(tasks).filter(x => {
      if (!isTaskActionable(x)) return false

      // top-level tasks (Shortlisting, Hiring, Tendering) are never actionable
      if (x.depth === 0) return false

      return true
    })

    return filtered[0]
  }
}

/**
 * Returns a function that can complete an actionable task for a given project and predicate
 *
 * This function also shows the achievement toast
 */
export const useCompleteBackgroundTask = () => {
  const gqlDataSource = useGraphQLDataSource({ api: "core" })
  const asyncQuery = useAsyncQuery({ api: "core" })
  const isActionableFilter = useActionableTaskFilter()
  const setTaskStatus = useSetTaskStatusMutation(gqlDataSource)
  const { present } = useAchievementToast()

  return async (projectId: string, predicate: (task: NormalizedTask) => boolean) => {
    const { listProjectTasks: tasks } = await asyncQuery(useListProjectTasksQuery, { id: projectId }, { staleTime: Duration.fromObject({ seconds: 30 }).toMillis() })
    const unpackedTasks = unpackTasksWithDepth(tasks)
    const nextActionableTask = unpackedTasks.find(task => isActionableFilter(task) && predicate(task))

    if (!nextActionableTask) {
      console.debug("[useBackgroundActionTask] no actionable task found")
      return
    }
    console.debug("[useBackgroundActionTask] found actionable task", nextActionableTask, "completing...")
    const setTask = await setTaskStatus.mutateAsync({ taskId: nextActionableTask.id, newStatus: TaskStatus.Completed })
    present({ message: nextActionableTask.title })

    return setTask
  }
}
