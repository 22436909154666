import { DateTime } from 'luxon'
import React from 'react'
import { getWorkHistoryTimeline } from '../ContractorLeadPreferences/ContractorBudgetRanges/datasource'
import { PublicContractorProfileScreenWithRenderProps, PublicContractorScreenNames } from './PublicContractorProfile'
import { usePublicContractorProfileScreenState } from './PublicContractorProfileScreenState'
import { WorkHistoryItem } from '../../../common/components/WorkHistoryItem/WorkHistoryItem'
import { ContractorTimelineCard } from '../MyContractorProfilePage/ContractorTimelineCard'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'

import Styles from '../MyContractorProfilePage/WorkHistoryTimeline.module.scss'

export const PublicWorkHistoryTimeline:React.FC<PublicContractorProfileScreenWithRenderProps & {handlePublicProfileWorkHistoryClick: (workhistoryId: string) => Promise<void>}> = (props) => {

  const {
    workHistoryItemIdState: [ , setWorkHistoryItemId ],
  } = usePublicContractorProfileScreenState()

  const weaverFlags = useWeaverFlags()

  const changeScreenToWorkHistory = props.useChangeScreen(PublicContractorScreenNames.WorkHistory)
  const showContractorWorkHistoryItem = async (workHistoryItemId: string) => {
    await props.handlePublicProfileWorkHistoryClick(workHistoryItemId)

    setWorkHistoryItemId(workHistoryItemId)
    changeScreenToWorkHistory()
  }

  const { sortedTimelineData, filteredWorkHistoryWithNoCompletedDate, yearsSortedByNewest } = getWorkHistoryTimeline(props.profile.workHistory, weaverFlags['MW-2614-handle-archivable-items-on-server'])
  const contractorCompanyIncorporationYear = DateTime.fromISO(props.profile.companyIncorporationDate ?? '').year

  /**
   * If no work histories are present we still want to show the company info at the top of the timeline
   */
  if (!sortedTimelineData){
    return <section>
      {contractorCompanyIncorporationYear
        ? <div className={Styles.yearContainer}>
          <h5>{Number.isNaN(contractorCompanyIncorporationYear) ? 'Company information' : contractorCompanyIncorporationYear}</h5>
        </div>
        : <></>}
      <ContractorTimelineCard {...props} />
    </section>
  }

  return (
    <>
      {yearsSortedByNewest.map((year) => <>
        <div className={Styles.yearContainer}>
          <h5>{year}</h5>
        </div>
        {sortedTimelineData[year].map((workHistory) => {
          return <WorkHistoryItem
            key={workHistory.id}
            workHistory={workHistory}
            backgroundClassName={'profileWorkHistoryItem'}
            primaryClickHandler={() => showContractorWorkHistoryItem(workHistory.id)}
          />
        })}
      </>,
      )}

      {filteredWorkHistoryWithNoCompletedDate.length > 0 &&
      <>
        <div className={Styles.yearContainer}>
          <h5>Other past projects</h5>
        </div>
        {filteredWorkHistoryWithNoCompletedDate.map((workHistory) => (
          <WorkHistoryItem
            key={workHistory.id}
            workHistory={workHistory}
            backgroundClassName={'profileWorkHistoryItem'}
            primaryClickHandler={() => showContractorWorkHistoryItem(workHistory.id)}
          />
        ))}
      </>
      }

      <section>
        {/* If date is in sortedTimelineData it must be the oldest year, if not found add year header */}
        {!sortedTimelineData[contractorCompanyIncorporationYear] && <div className={Styles.yearContainer}>
          <h5>{Number.isNaN(contractorCompanyIncorporationYear) ? 'Company information' : contractorCompanyIncorporationYear}</h5>
        </div>}
        <ContractorTimelineCard {...props} />
      </section>

    </>
  )
}
