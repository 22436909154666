import React, { ComponentProps } from 'react'
import { IonModal, IonPage } from '@ionic/react'
import { ContentDisposition, GetContractorProfileQuery, useGetContractorProfileQuery } from "../../../graphql/generated"
import { useGraphQLDataSource } from '../../../api/graphql'
import LoadingSpinner from '../../../common/components/LoadingSpinner'
import ErrorBlock from '../../../common/components/ErrorBlock'
import { ScreenRenderProps, useScreensWithProps } from '../../../common/hooks/useScreens'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import PublicContractorProfileScreenState from './PublicContractorProfileScreenState'
import ScreenHome from './ScreenHome'
import ScreenWorkHistory from './ScreenWorkHistory'
import WeaverIonModal from '../../../common/components/WeaverIonWrappers/WeaverIonModal'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'
import { fullSizeTransformation } from '../../../common/utils/imageTransformations'
import { Duration } from 'luxon'

// Types and Discriminator for supporting multi-render modes

export enum RenderAs {
  Page = "Page",
  Modal = "Modal",
}

type RenderProps = {
  as: RenderAs.Page,
  ionPageProps: ComponentProps<typeof IonPage>,
} | {
  as: RenderAs.Modal,
  ionModalProps: ComponentProps<typeof IonModal>,
}

// The primary PublicContractorProfile component for use in a given render mode
// This component is intended to gather the data and then pass it to screens for rendering

type PublicContractorProfileProps = {
  id: string,
  renderProps: RenderProps,
}

const PublicContractorProfile: React.FC<PublicContractorProfileProps> = ({ id, renderProps }) => {
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data, error, isFetching, refetch } = useGetContractorProfileQuery(gqlDataSource,
    { contractorTeamId: id,
      config: { transformation: fullSizeTransformation, disposition:  ContentDisposition.Attachment } },
    { staleTime: Duration.fromObject({ minutes: 5 }).toMillis() },
  )

  console.debug(`[PublicContractorProfile] Render: `, { id, data })

  if (isFetching) {
    return renderProps.as === RenderAs.Page
      ? <LoadingSpinnerPage name="PublicContractorProfile" />
      : <LoadingSpinner name="PublicContractorProfile" />
  }

  if (error) {
    console.error(`[PublicContractorProfile] Failed to load Contractor Profile: `, { id, data, error })
    return renderProps.as === RenderAs.Page
      ? <ErrorBlockPage name='PublicContractorProfile' onRefresh={refetch} />
      : <ErrorBlock name='PublicContractorProfile' onRefresh={refetch} />
  }

  if (data?.getContractorProfile == null) {
    console.warn(`[PublicContractorProfile] Contractor Profile is missing: `, { id, data, error })
    return <ErrorBlock name='PublicContractorProfile'><p>Unable to find the Contract Profile.</p></ErrorBlock>
  }

  return <PublicContractorProfileScreens {...{ profile: data.getContractorProfile, renderProps }} />
}

// The screens component for switching between the available screens
// Provides a context for state storage and (in future, possibly) more abstracted controlling logic
// This component and its constituent parts should be render mode aware

export enum PublicContractorScreenNames {
  Home = "Home",
  WorkHistory = "WorkHistory",
}

export type PublicContractorProfileScreenProps = {
  renderProps: RenderProps,
  profile: NonNullable<GetContractorProfileQuery['getContractorProfile']>,
}

export type PublicContractorProfileScreenWithRenderProps = PublicContractorProfileScreenProps & ScreenRenderProps<PublicContractorScreenNames>

const PublicContractorProfileScreens: React.FC<PublicContractorProfileScreenProps> = (screenProps) => {
  const [ Screen, activeScreen ] = useScreensWithProps<PublicContractorScreenNames, PublicContractorProfileScreenProps>({
    init: PublicContractorScreenNames.Home,
    screenProps,
    screens: {
      Home: { render: ScreenHome },
      WorkHistory: { render: ScreenWorkHistory },
    },
  })

  console.debug('[PublicContractorProfileScreens] Display Screen: ', { screenProps, activeScreen })

  return (
    <PublicContractorProfileScreenState>
      {screenProps.renderProps.as === RenderAs.Page && (
        <WeaverIonPage id='PublicContractorProfile' {...screenProps.renderProps.ionPageProps} key={activeScreen} disableDirectChildStructureChecks={true}>
          {Screen}
        </WeaverIonPage>
      )}
      {screenProps.renderProps.as === RenderAs.Modal && (
        <WeaverIonModal id='PublicContractorProfileModal' {...screenProps.renderProps.ionModalProps} disableDirectChildStructureChecks={true}>
          {Screen}
        </WeaverIonModal>
      )}
    </PublicContractorProfileScreenState>
  )
}

export default PublicContractorProfile
