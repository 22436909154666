import React from 'react'
import { ReferralType, ShowLeadQuery, useAcceptLeadMutation, useListMyProjectsAndLeadsQuery } from '../../../graphql/generated'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import BrilliantScreen from '../../../common/components/BrilliantScreen/BrilliantScreen'
import { getWorkStartEstimateLabels } from '../workEstimate.i18n'
import { getDocumentAndDocumentPreviewData, getFlexibleProjectBudgetAsText } from '../common'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { pageConfig_ShowProject, populateUrlForPath, useRouteTo } from '../../../routes'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { useQueryClient } from '@tanstack/react-query'
import { alwaysArray } from '../../../common/utils'

type FreeLeadAcceptancePageProps = {
  lead:  NonNullable<ShowLeadQuery['getLead']>,
}

const FreeLeadAcceptancePage: React.FC<FreeLeadAcceptancePageProps> = ({ lead }) => {
  const queryClient = useQueryClient()
  const myTeam = useMyIndividualActiveTeam()
  const triggerEvent_Project_Lead_Responded_Accepted = useAnalyticsEvent("Project_Lead_Responded_Accepted")
  const weaverFlags = useWeaverFlags()

  const routeToProject = populateUrlForPath(pageConfig_ShowProject.path, { id: lead.id }, "referrer=acceptLead", weaverFlags['MW-2647-remove-extra-question-mark-from-url'])
  const navigateToProject = useRouteTo(routeToProject, weaverFlags['MW-2647-remove-extra-question-mark-from-url'])

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const acceptLead = useAcceptLeadMutation(gqlDataSource, { onSuccess: async () => {
    if (weaverFlags["MW-2415-lead-cache-busting"]) {
      await queryClient.invalidateQueries(useListMyProjectsAndLeadsQuery.getKey())
    }
  } })
  const isProjectOwnerReferral = lead.referral?.type === ReferralType.ProjectOwner
  const { documentPreviewCount, activeDocuments, allDocumentTags } = getDocumentAndDocumentPreviewData(lead)

  return (
    <WeaverIonPage id='LeadBrilliantScreen'>
      <WeaverIonContent>
        <BrilliantScreen
          title='Amazing!'
          subtitle={`You have accepted a ${weaverFlags["MW-2604-copy-change-lead-to-tender"] ? "tender" : "lead"}`}
          actionButtonProps={{
            onClick: async () => {
              // We only accept the lead when Continue is clicked
              await acceptLead.mutateAsync({ id: lead.id })

              await triggerEvent_Project_Lead_Responded_Accepted({
                projectId: lead.id,
                titleOfProject: lead.title,
                description: lead.description,
                budgetShownToContractor: getFlexibleProjectBudgetAsText(lead, myTeam, weaverFlags['MW-2600-expand-project-creation-flow'], weaverFlags['MW-2645-adjust-flex-budget-ranges']) ?? undefined,
                workStartEstimate: getWorkStartEstimateLabels()[lead.workStartEstimate],
                /** @deprecated MW-2386-remove-tender-return-date - MAY STILL BE USED BY C.IO AND CAUSE FAILURE IF REMOVED */
                tenderReturnDate: undefined,
                type: lead.projectTypes,
                isNonWeaverBuilder: isProjectOwnerReferral,
                documentPreviewCount,
                allDocumentTags: alwaysArray(allDocumentTags),
                documentCount: activeDocuments.length,
              })
              navigateToProject({})()
            },
            children: <>Continue</>,
            expand: 'block',
          }}
        />
      </WeaverIonContent>
    </WeaverIonPage>
  )
}

export default FreeLeadAcceptancePage
