import { Capacitor } from "@capacitor/core"
import { isPlatform } from "@ionic/react"
import * as icons from "ionicons/icons"

function arrayElement<T>(list: T[]) {
  return list[Math.floor(Math.random() * list.length)]
}

export const isMobile: boolean = (isPlatform('ios') || isPlatform('android')) && !isPlatform('mobileweb')
export const isAndroid: boolean = isPlatform('android')
export const isRunningLocallyInDevelopment = process.env.NODE_ENV === 'development'
export const isHotReloadingInMobileSimulator = isMobile && isRunningLocallyInDevelopment

export const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications')

export const getRandomIconName = () => arrayElement(Object.keys(icons))
export const isMobileScreenSize = () => window.innerWidth < 576 // Ionic's default breakpoint for mobile is 576px -- sm --
