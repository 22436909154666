import { IonIcon } from '@ionic/react'
import { checkmarkCircle } from 'ionicons/icons'
import React from 'react'
import Styles from './CheckMarkItem.module.scss'

type CheckMarkItemProps = {
  description: string,
  iconColor: string,
}

export const CheckMarkItem: React.FC<CheckMarkItemProps> = ({ description, iconColor }) => {
  return (
    <div className={Styles.checkMarkItem}>
      <div className={Styles.checkMarkIconContainer}>
        <IonIcon color={iconColor} className={Styles.checkMarkIcon}  icon={checkmarkCircle} slot="start" />
      </div>
      <p className={Styles.descriptionText}>{description}</p>
    </div>
  )
}
