import React from 'react'
import { Duration } from 'luxon'
import { GetReleaseInfoQuery, useFlushCachesFrontendSpaMutation, useGetReleaseInfoQuery } from '../../../graphql/generated'
import { useGraphQLDataSource } from '../../graphql'
import { TAuth_LoggedIn, useMaybeAuthContext } from '../AuthProvider'
import { Environment, useEnvironmentContext } from '../EnvironmentProvider'
import { IonToast } from '@ionic/react'
import { arrowUpCircleOutline } from 'ionicons/icons'
import { useWeaverFlags, WeaverFlags } from '../../thirdParty/launchDarkly/useWeaverFlags'

export type MyWeaverConsole = {
  environment?: Environment,
  auth?: TAuth_LoggedIn,
  mechaReleaseInfo?: GetReleaseInfoQuery['getReleaseInfo'],
  releaseSanityCheck?: {
    mechaDeployedVersion: string | undefined,
    appFrontendDeployedVersion: string | undefined,
    appFrontendBrowserVersion: string | undefined,
    invalidateFrontendSPA: () => unknown,
  },
  weaverFlags?: WeaverFlags,
}

export const MyWeaverConsoleContext = React.createContext<MyWeaverConsole>({})

export const MyWeaverConsoleProvider: React.FC= ({ children }) => {
  const environment = useEnvironmentContext()
  const auth = useMaybeAuthContext()
  const weaverFlags = useWeaverFlags()
  const env = useEnvironmentContext()

  const datasource = useGraphQLDataSource({ api: 'core' })
  const releaseInfoQuery = useGetReleaseInfoQuery(datasource, {}, {
    staleTime: Duration.fromObject({ day: 1 }).as('milliseconds'),
    refetchOnWindowFocus: false,
    enabled: env.account.type === "published",
  })
  const flushCachesFrontendSPA = useFlushCachesFrontendSpaMutation(datasource)

  const myWeaverConsole: MyWeaverConsole = {
    environment,
    auth,
    mechaReleaseInfo: releaseInfoQuery.data?.getReleaseInfo,
    releaseSanityCheck: {
      mechaDeployedVersion: releaseInfoQuery.data?.getReleaseInfo.mechaVersion ?? undefined,
      appFrontendDeployedVersion: releaseInfoQuery.data?.getReleaseInfo.appFrontendVersion ?? undefined,
      appFrontendBrowserVersion: environment.release.version,
      invalidateFrontendSPA: () => flushCachesFrontendSPA.mutateAsync({}),
    },
    weaverFlags,
  }

  const isBrowserOutOfDate =
     environment.release.version != null && // I have a browser version
      releaseInfoQuery.data?.getReleaseInfo.appFrontendVersion != null && // I have a deployed version
      environment.release.version !== releaseInfoQuery.data?.getReleaseInfo.appFrontendVersion // browser version !== deployed version

  // Export the MyWeaverConsole object to `window` so it can be used in the Browser Console
  // eslint-disable-next-line
  const anyWindow: any = window as any
  anyWindow.MyWeaverConsole = myWeaverConsole

  // Export the MyWeaverConsole object to the `MyWeaverConsoleContext` React Context so it can be used programmatically
  return (
    <MyWeaverConsoleContext.Provider value={myWeaverConsole}>
      <IonToast
        isOpen={isBrowserOutOfDate}
        position='top'
        icon={arrowUpCircleOutline}
        message='A new version of Weaver is available'
        color='tertiary'
        buttons={[
          {
            text: 'Reload',
            role: 'reload',
            handler: () => {
              const url = new URL(window.location.href)
              url.searchParams.set('reloadTime', Date.now().toString())
              window.location.href = url.toString()
            },
          },
        ]}
      />
      {children}
    </MyWeaverConsoleContext.Provider>
  )
}

export default MyWeaverConsoleProvider
