import React from 'react'
import { useGetStripeProductConfigsQuery, useShowLeadQuery } from "../../../graphql/generated"
import { useGraphQLDataSource } from '../../../api/graphql'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import { alwaysArray } from '../../../common/utils'
import { FeeOption } from './ContractorLeadAcceptorPage'
import { budgetRangeLeadFeeStripeProduct, budgetRangeMonthlySubscriptionStripeProduct, getBudgetRangeByMoney } from '../../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges'
import { useParamsFromPageConfig } from '../../../routesProvider'
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'
import SingleLeadPaymentPage from './SingleLeadPaymentPage'
import { budgetRangeLabels } from '../../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges.i18n'
import { moneyToText } from '../../../common/utils/currency'
import { Duration } from 'luxon'
import useRedirectToStripePaymentForLeadPaymentHandler from '../../../api/thirdParty/stripe/useRedirectToStripePaymentHandler'

const DisplaySingleLeadPaymentPage: React.FC = () => {
  const { leadId } = useParamsFromPageConfig<{ leadId: string }>()

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const useShowLeadResponse = useShowLeadQuery(gqlDataSource , { id: leadId }, {
    staleTime: Duration.fromObject({ day: 1 }).as('milliseconds'),
    select: ({ getLead }) => {
      if (!getLead) throw new Error("Lead not found")
      return { getLead }
    },
  })
  const getStripeProductConfigsQuery = useGetStripeProductConfigsQuery(gqlDataSource, {}, {
    staleTime: Duration.fromObject({ day: 1 }).as('milliseconds'),
  })

  const budgetValueAsMoney = useShowLeadResponse.data?.getLead.budgetValue
  const lead = useShowLeadResponse.data?.getLead
  const leadBudgetRange = getBudgetRangeByMoney(budgetValueAsMoney)    // NOTE: `leadBudgetAmountInPennies` should never be undefined/null because of the guard

  const makeStripePayment = useRedirectToStripePaymentForLeadPaymentHandler({ leadId, source: 'DisplaySingleLeadPaymentPage' })

  if (useShowLeadResponse.isLoading || getStripeProductConfigsQuery.isLoading) {
    return <LoadingSpinnerPage name="DisplaySingleLeadPaymentPage" />
  }

  if (useShowLeadResponse.error || getStripeProductConfigsQuery.error || lead == null || leadBudgetRange == null) {
    console.error(`[DisplaySingleLeadPaymentPage] Failed to load tender and Stripe product data: `, { useShowLeadResponse, getStripeProductConfigsQuery, budgetValueAsMoney, leadBudgetRange })
    return <ErrorBlockPage name='DisplaySingleLeadPaymentPage' onRefresh={() => {
      useShowLeadResponse.refetch()
      getStripeProductConfigsQuery.refetch()
    }} />
  }

  const stripeProductLeadFee = alwaysArray(getStripeProductConfigsQuery.data?.getStripeProductConfigs)
    .find(each => each.product === budgetRangeLeadFeeStripeProduct[leadBudgetRange])
  const budgetRangeLeadFee = moneyToText(stripeProductLeadFee?.price)

  const stripeProductMonthlySubscription = alwaysArray(getStripeProductConfigsQuery.data?.getStripeProductConfigs)
    .find(each => each.product === budgetRangeMonthlySubscriptionStripeProduct[leadBudgetRange])
  const budgetRangeMonthlySubscriptionFee = moneyToText(stripeProductMonthlySubscription?.price)

  if (stripeProductLeadFee == null|| budgetRangeLeadFee == null || stripeProductMonthlySubscription == null || budgetRangeMonthlySubscriptionFee == null) {
    console.error(`[DisplaySingleLeadPaymentPage] Failed to resolve fees: `, { getStripeProductConfigsQuery, stripeProductLeadFee, stripeProductMonthlySubscription })
    return <ErrorBlockPage name='DisplaySingleLeadPaymentPage'>
      <p>Unable to find the pricing. Please contact Weaver Support.</p>
    </ErrorBlockPage>
  }

  const handleNextClick = async (feeOption: FeeOption) => {
    switch (feeOption) {
      case FeeOption.Subscription: {
        await makeStripePayment(stripeProductMonthlySubscription)
        break
      }
      case FeeOption.PerLead: {
        await makeStripePayment(stripeProductLeadFee)
        break
      }
    }
  }

  console.debug(`[DisplaySingleLeadPaymentPage] Render: `, {
    leadId,
    useShowLeadResponse,
    getStripeProductConfigsQuery,
    stripeProductLeadFee,
    stripeProductMonthlySubscription,
  })

  return (
    <SingleLeadPaymentPage
      budgetRange={leadBudgetRange}
      budgetRangeLabel={budgetRangeLabels[leadBudgetRange]}
      stripeProductLeadFee={stripeProductLeadFee}
      stripeProductMonthlySubscription={stripeProductMonthlySubscription}
      handleNextClick={handleNextClick}
    />
  )
}

export default DisplaySingleLeadPaymentPage
