import { IonText } from '@ionic/react'
import React, { MouseEventHandler } from 'react'
import Styles from './CostEstimatorSelector.module.scss'
import SingleClickButton from '../../../common/components/SingleClickButton'

type CostEstimateSelectorProps = {
  onSelect: MouseEventHandler<HTMLIonButtonElement>,
}

export const CostEstimateSelector: React.FC<CostEstimateSelectorProps> = ({ onSelect }) => {
  return (
    <div className={Styles.costEstimatorSelector} >
      <IonText>
        <h4>Not sure? Get a cost estimate</h4>
        <p>Start off your projects with a RICS-standard independent build estimate</p>
      </IonText>
      <SingleClickButton expand='block' onClick={onSelect} fill="outline" color="primary">QS cost estimate</SingleClickButton>
    </div>
  )
}

