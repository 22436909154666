export enum OnboardingStepStatus {
  Done = "Done",
  Next = "Next",
  ToDo = "ToDo"
}

export type StepType = {
  id: string,
  title: string,
  icon: string,
  status: OnboardingStepStatus,
}

const architectSteps_deprecated = [
  { id: '1', title: 'Sign Up', icon: 'pencilOutline', status: OnboardingStepStatus.Done },
  { id: '2', title: 'Enter Company Details', icon: 'shieldCheckmarkOutline', status: OnboardingStepStatus.Next },
]

const architectSteps = [
  { id: '1', title: 'Sign Up', icon: 'pencilOutline', status: OnboardingStepStatus.Done },
  { id: '2', title: 'Create your profile', icon: 'personOutline', status: OnboardingStepStatus.Next },
  { id: '3', title: 'Upload projects', icon: 'homeOutline', status: OnboardingStepStatus.ToDo },
]

const contractorSteps = [
  { id: '1', title: 'Sign Up', icon: 'pencilOutline', status: OnboardingStepStatus.Done },
  { id: '2', title: 'Enter Company Details', icon: 'shieldCheckmarkOutline', status: OnboardingStepStatus.Next },
]

const homeownerSteps = [
  { id: '1', title: 'Sign Up', icon: 'pencilOutline', status: OnboardingStepStatus.Done },
  { id: '2', title: 'Enter Your Details', icon: 'shieldCheckmarkOutline', status: OnboardingStepStatus.Next },
]

const latestStepCompleted = (steps: StepType[], latestStepCompleted: StepType[ 'id' ]): StepType[] => {
  const completedStep = steps.find(step => step.id === latestStepCompleted)
  if (!completedStep) return steps

  const completedStepIndex = steps.indexOf(completedStep)

  return steps.map((step, index) => ({
    ...step,
    status: index <= completedStepIndex
      ? OnboardingStepStatus.Done
      : index === completedStepIndex + 1
        ? OnboardingStepStatus.Next
        : OnboardingStepStatus.ToDo,
  }))
}

export {
  architectSteps,
  architectSteps_deprecated,
  contractorSteps,
  homeownerSteps,
}
