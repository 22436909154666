import React from 'react'
import { IonButton } from '@ionic/react'
import WeaverIonContent from '../../../../common/components/WeaverIonWrappers/WeaverIonContent'
import { pageConfig_ShowProject, pageConfig_TaskActioner, useRouteTo } from '../../../../routes'
import { useParamsFromPageConfig } from '../../../../routesProvider'
import WeaverIonPage from '../../../../common/components/WeaverIonWrappers/WeaverIonPage'
import BrilliantScreen from '../../../../common/components/BrilliantScreen/BrilliantScreen'
import { useGraphQLDataSource } from '../../../../api/graphql'
import { useGetActionableTaskQuery } from '../../../../graphql/generated'
import { Duration } from 'luxon'
import { useMarkTaskCompleteMutation } from '../commonActions'

type StripeResult = 'success' | 'cancel'

const StartMatchingStripeCallbackPage = () => {

  const routeToProject = useRouteTo(pageConfig_ShowProject.path)
  const routeToTaskActioner = useRouteTo(pageConfig_TaskActioner.path)

  const { projectId, result, taskId  } = useParamsFromPageConfig<{ projectId: string, result: StripeResult, taskId: string }>()
  const datasource = useGraphQLDataSource({ api: 'core' })
  const getActionableTaskQuery = useGetActionableTaskQuery(
    datasource,
    { taskId: taskId ?? '' },
    {
      enabled: !!taskId,
      staleTime: Duration.fromObject({ minutes: 5 }).as('milliseconds'),
    },
  )
  const setTaskAsCompleted = useMarkTaskCompleteMutation(getActionableTaskQuery)

  const handleSuccessContinueClick = async () => {
    await setTaskAsCompleted()
    routeToProject({ id: projectId }, undefined, 'forward', 'replace')()
  }

  return <WeaverIonPage id="StartMatchingCallbackPage">
    <WeaverIonContent>
      <div>
        {result === 'cancel'
          ? <>
            <h3 className='ion-text-center'>Payment was cancelled</h3>
          </>
          : <>
            <BrilliantScreen
              title='Amazing!'
              subtitle='Best suited Weaver contractors will receive your project brief.'
              description='We will notify you as soon as there is a match so that you can review contractor&apos;s profile and contact them directly!'
              actionButtonProps={{
                onClick: async () => {
                  handleSuccessContinueClick()
                },
                children: <>Continue</> ,
                expand: 'block',
              }}
            />
          </>
        }
        {result === 'cancel' &&
        <IonButton size='large' expand='block'  onClick={() => routeToTaskActioner({ taskId }, undefined, 'forward', 'replace')()}>
          Go back to project
        </IonButton>
        }
      </div>
    </ WeaverIonContent>
  </WeaverIonPage>
}

export default StartMatchingStripeCallbackPage
