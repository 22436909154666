import React, { useState } from 'react'
import Styles from './ExpandedProjectDescription.module.scss'

type ExpandedProjectDescriptionProps = {
  description : string,
  characterLimit: number,

}

export const ExpandedProjectDescription: React.FC<ExpandedProjectDescriptionProps> = ({ description, characterLimit }) => {

  const [ expanded, setExpanded ] = useState<boolean>(false)

  const shouldShowViewMore = description.length > characterLimit

  const displayedText = expanded ? description : description.slice(0, characterLimit)

  const paragraphs = displayedText.split("\n")

  return (
    <div className='ion-padding-horizontal ion-padding-bottom'>
      {paragraphs.map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
      {shouldShowViewMore && (
        <div onClick={() => setExpanded(!expanded)} className={Styles.viewMoreButton}>
          {expanded ? 'View Less' : 'View More'}
        </div>
      )}
    </div>
  )
}
