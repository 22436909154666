import React from 'react'
import { useParamsFromPageConfig } from '../../../routesProvider'
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'
import BrilliantScreen from '../../../common/components/BrilliantScreen/BrilliantScreen'
import { pageConfig_ShowLead, pageConfig_ShowProject, populateUrlForPath, useRouteTo } from '../../../routes'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import { useFireAcceptLeadEvent } from './useFireAcceptLeadEvent'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'

const DisplayContractorLeadAcceptorStripeCallbackPage: React.FC = () => {
  const { leadId, result } = useParamsFromPageConfig<{ leadId: string, result: string }>()
  const weaverFlags = useWeaverFlags()
  const goToShowLead = useRouteTo(pageConfig_ShowLead.path)

  const routeToProject = populateUrlForPath(pageConfig_ShowProject.path, { id: leadId }, "referrer=acceptLead", weaverFlags['MW-2647-remove-extra-question-mark-from-url'])
  const navigateToProject = useRouteTo(routeToProject, weaverFlags['MW-2647-remove-extra-question-mark-from-url'])

  useFireAcceptLeadEvent(leadId, result === 'success')

  if (result === 'success') {
    return (
      <WeaverIonPage id='DisplayContractorLeadAcceptorStripeCallbackPage'>
        <WeaverIonContent>
          <BrilliantScreen
            title='Amazing!'
            subtitle={`You have accepted a ${weaverFlags['MW-2604-copy-change-lead-to-tender'] ? 'tender' : 'lead'}`}
            actionButtonProps={{
              onClick: navigateToProject({}),
              children: <>Continue</>,
              expand: 'block',
            }}
          />
        </WeaverIonContent>
      </WeaverIonPage>
    )
  }

  if (result === 'cancel') {
    return (
      <ErrorBlockPage name='DisplayContractorLeadAcceptorStripeCallbackPage' onRefresh={goToShowLead({ id: leadId })}>
        <p>Transaction Cancelled</p>
      </ErrorBlockPage>
    )
  }

  throw new Error(`Unknown Stripe Result state: '${result}' for Tender ID: ${leadId}`)
}

export default DisplayContractorLeadAcceptorStripeCallbackPage
