import React, { ComponentProps } from "react"
import { IonButton } from "@ionic/react"
import { pageConfig_Profile_ContractorLeadPreferences, useRouteTo } from "../../../routes"
import { useWeaverFlags } from "../../../api/thirdParty/launchDarkly/useWeaverFlags"

type IonButtonPropsWithoutOnClick = Omit<ComponentProps<typeof IonButton>, 'onClick'>
const ButtonChangeLeadPreferences: React.FC<IonButtonPropsWithoutOnClick> = (ionButtonProps) => {
  const goToContractorLeadPreferences = useRouteTo(pageConfig_Profile_ContractorLeadPreferences.path)
  const { "MW-2604-copy-change-lead-to-tender": leadToTenderFlag } = useWeaverFlags()

  return <IonButton {...ionButtonProps} onClick={goToContractorLeadPreferences({})}>
    {ionButtonProps.children ?? `Add ${leadToTenderFlag ? 'Tender' : 'Lead'} Preferences`}
  </IonButton>
}

export default ButtonChangeLeadPreferences
