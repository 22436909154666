export const createUrl = (name: string) => {
  return name
    .replace(' ', '-')
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .toLowerCase()
}

export const MINIMUM_PROJECT_VALUE_IN_PENCE = 3000000
export const MAXIMUM_PROJECT_VALUE_IN_PENCE = 300_000_000 // 3 million pounds

export const CREATE_PROJECT_CONTRACTORS = {
  contractorCount: 3,
  currentPrice: "FREE!",
  description: "Weaver recommends a maximum of 3 contractors per tender for the best results",
  guaranteeText: "We work hard to ensure you receive quality contractor quotes. Builders who don't meet our service level expectations get penalised.",
  originalPrice: "£300",
  title: "Weaver will match up to 3 Contractors to your project.",
}

export const SHOW_SIGN_UP_URL_QUERY_KEY = "signup"

