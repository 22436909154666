import React, { useEffect } from "react"
import { requireSomething } from "../../../../common/utils"
import { ScreenStartMatchingContext } from "./common"
import BasicPaymentCard from "./BasicPaymentCard"
import PremiumPaymentCard from "./PremiumPaymentCard"
import { TeamType, useGetStripePremiumStartMatchingProductMetadataQuery } from "../../../../graphql/generated"
import { useGraphQLDataSource } from "../../../../api/graphql"
import { useParamsFromPageConfig } from "../../../../routesProvider"
import { IonContent } from "@ionic/react"
import { useAnalyticsEvent } from "../../../../api/providers/SegmentProvider/hooks"
import { useMyIndividualActiveTeam } from "../../../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { InviteHomeOwnerPaymentModal } from "./InviteHomeownerPaymentModal"

type StartMatchingPaymentModalProps = {
  nudgeSentRef: React.MutableRefObject<boolean>,
}

const StartMatchingPaymentModal: React.FC<StartMatchingPaymentModalProps> = ({ nudgeSentRef }) => {
  const { project } = requireSomething(React.useContext(ScreenStartMatchingContext))
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const myTeam = useMyIndividualActiveTeam()

  const { taskId }= useParamsFromPageConfig<{taskId: string}>()
  const membersPresentOnProject = project.members?.filter(member => member.team.type)

  const productMetadataResult = useGetStripePremiumStartMatchingProductMetadataQuery(gqlDataSource)
  const firePaymentPlanPickerViewedEvent = useAnalyticsEvent('Project_PaymentPlanPicker_Viewed')

  useEffect(() => {
    if (!myTeam) return console.error('[StartMatchingPaymentModal]: teamId is undefined')

    firePaymentPlanPickerViewedEvent({
      teamId: myTeam.id,
      projectId: project.id,
    })
  }, [ taskId ])

  const nonViableTeamCombinations =  productMetadataResult.data?.getStripePremiumStartMatchingProductMetadata.NonViableTeamCombination
  const membersPresentOnProjectTeamTypes = membersPresentOnProject.map(member => member.team.type)

  const doNotShowPremiumPaymentOption = nonViableTeamCombinations?.find((nonViableTeamCombination) => {
    const sortedNonViableTeamCombination = nonViableTeamCombination.sort().join(",")
    const sortedTeamsPresent = membersPresentOnProjectTeamTypes.sort().join(",")
    return sortedNonViableTeamCombination == sortedTeamsPresent
  })

  if (productMetadataResult.isLoading) {
    return <div>loading...</div>
  }

  return <IonContent className="ion-padding">
    {myTeam?.type === TeamType.Architect && <InviteHomeOwnerPaymentModal project={project} projectId={project.id} nudgeSentRef={nudgeSentRef}/>}
    <BasicPaymentCard project={project} taskId={taskId} />
    {!doNotShowPremiumPaymentOption &&  <PremiumPaymentCard project={project} taskId={taskId} />}
  </IonContent>

}

export default StartMatchingPaymentModal
