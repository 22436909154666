
import React from 'react'
import WeaverUnverifiedBadge from '../../../common/components/WeaverUnverifiedBadge/WeaverUnverifiedBadge'
import WeaverVerifiedBadge from '../../../common/components/WeaverVerifiedBadge/WeaverVerifiedBadge'
import { ContractorProfileWorkHistoryReference, ProgressionStatus } from '../../../graphql/generated'
import { selectableTeamTypeLabels } from '../../onboarding/team/onboarding.i18n'
import Styles from './ContractorWorkHistoryReference.module.scss'

export const ContractorWorkHistoryReference: React.FC<{ reference: ContractorProfileWorkHistoryReference }> = ({ reference }) => {
  const referenceVerified = reference.status === ProgressionStatus.Completed

  return <div className={Styles.referenceContainer}>
    <span className={Styles.referenceText}>{selectableTeamTypeLabels[reference.teamType]}</span>
    {referenceVerified ? <WeaverVerifiedBadge /> : <WeaverUnverifiedBadge />}
  </div>

}
