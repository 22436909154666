import React, { useCallback } from 'react'
import { IonButton } from '@ionic/react'
import LoginCard from '../../init/Bootstrapper/LoginCard'
import { useEnvironmentContext } from '../../api/providers/EnvironmentProvider/context'
import ManageJwts from './ManageJwts'
import WorkflowSimulator from './WorkflowSimulator'
import ManageTeams from './ManageTeams'
import ManageLeads from './ManageLeads'
import GlobalHeader from '../../common/components/GlobalHeader/GlobalHeader'
import GlobalHeaderStyles from '../../common/components/GlobalHeader/GlobalHeader.module.scss'
import ButtonChangeLeadPreferences from '../profile/ContractorLeadPreferences/ButtonChangeLeadPreferences'
import ButtonResetContractorProfileWorkHistory from '../profile/ContractorLeadPreferences/ButtonResetContractorProfileWorkHistory'
import WeaverIonPage from '../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonContent from '../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonHeader from '../../common/components/WeaverIonWrappers/WeaverIonHeader'
import { useScreens } from '../../common/hooks/useScreens'
import WeaverPublicProfileModalIonButton from './WeaverPublicProfileModalIonButton'

const MenuEnvironmentStatus: React.FC = () => {
  const environment = useEnvironmentContext()
  return <p>Env: <strong>{environment.app.domain}</strong></p>
}

enum DeveloperSettingScreens {
  Main = "Main",
  ManageJwts = "ManageJwts",
  WorkflowSimulator = "WorkflowSimulator",
  ManageTeams = "ManageTeams",
  ManageLeads = "ManageLeads",
}

const DeveloperSettingsPage: React.FC = () => {
  const [ Screen, activeScreen ] = useScreens<DeveloperSettingScreens>({
    init: DeveloperSettingScreens.Main,
    screens: {
      Main: {
        render: useCallback(({ useChangeScreen }) => {
          const goToManageJWTsScreen = useChangeScreen(DeveloperSettingScreens.ManageJwts)
          const goToWorkflowSimulator = useChangeScreen(DeveloperSettingScreens.WorkflowSimulator)
          const goToManageTeams = useChangeScreen(DeveloperSettingScreens.ManageTeams)
          const goToManageLeads = useChangeScreen(DeveloperSettingScreens.ManageLeads)

          return (
            <WeaverIonPage id={`DeveloperSettingsPage.${activeScreen}`} key={activeScreen} >
              <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
                <GlobalHeader pageTitle='Developer Settings'/>
              </WeaverIonHeader>
              <WeaverIonContent>
                <MenuEnvironmentStatus />
                <IonButton onClick={goToManageJWTsScreen}>Manage JWTs</IonButton>
                <IonButton onClick={goToWorkflowSimulator}>Simulate workflow</IonButton>
                <IonButton onClick={goToManageTeams}>Manage teams</IonButton>
                <IonButton onClick={goToManageLeads}>Manage tenders</IonButton>
                <WeaverPublicProfileModalIonButton>
                  Open my public profile modal
                </WeaverPublicProfileModalIonButton>
                <ButtonChangeLeadPreferences />
                <ButtonResetContractorProfileWorkHistory />
                <IonButton onClick={() => { throw new Error("Intentional error for Sentry testing.") }}>Trigger error for sentry</IonButton>
                <LoginCard />
              </WeaverIonContent>
            </WeaverIonPage>
          )
        }, []),
      },
      ManageJwts: { render: ManageJwts },
      WorkflowSimulator: { render: WorkflowSimulator },
      ManageTeams: { render: ManageTeams },
      ManageLeads: { render: ManageLeads },
    },
  })

  return <>{Screen}</>
}

export default DeveloperSettingsPage
