import React from "react"
import { IonCard, IonItem } from "@ionic/react"
import { Duration } from "luxon"
import { useGraphQLDataSource } from "../../../api/graphql"
import { useAuthContext } from "../../../api/providers/AuthProvider"
import { useEnvironmentContext } from "../../../api/providers/EnvironmentProvider"
import { useMyIndividual, useMyIndividualActiveTeam } from "../../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { useWeaverFlags } from "../../../api/thirdParty/launchDarkly/useWeaverFlags"
import GlobalHeader from "../../../common/components/GlobalHeader"
import { NavElement } from "../../../common/components/GlobalHeader/GlobalHeader"
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import WeaverIonContent from "../../../common/components/WeaverIonWrappers/WeaverIonContent"
import WeaverIonHeader from "../../../common/components/WeaverIonWrappers/WeaverIonHeader"
import WeaverIonPage from "../../../common/components/WeaverIonWrappers/WeaverIonPage"
import { useGetReleaseInfoQuery } from "../../../graphql/generated"
import Styles from "./DebugPage.module.scss"
import { useFireAnalyticsEvent } from "../../../api/providers/SegmentProvider/hooks"

const WeaverCard: React.FC<{ title: string }> = ({ title, children }) =>
  <IonCard className={Styles.card}>
    <IonItem>
      <h2>{title}</h2>
    </IonItem>
    {children}
  </IonCard>

const WeaverRow: React.FC<{ name: string }> = ({ name, children }) =>
  <IonItem>
    <div className={Styles.headerColumn}>
      <div>{name}</div>
    </div>
    <div className={Styles.contentColumn}>
      {children}
    </div>
  </IonItem>

const DebugPage: React.FC = () => {
  const auth = useAuthContext()
  const myIndividual = useMyIndividual()
  const myIndividualActiveTeam = useMyIndividualActiveTeam()
  const env = useEnvironmentContext()
  const weaverFlags = useWeaverFlags()

  const datasource = useGraphQLDataSource({ api: 'core' })
  const releaseInfoQuery = useGetReleaseInfoQuery(datasource, {}, {
    staleTime: Duration.fromObject({ day: 1 }).as('milliseconds'),
    refetchOnWindowFocus: false,
    enabled: env.account.type === "published",
  })

  const allLoaded = !!myIndividual && !!env && !!releaseInfoQuery.data
  useFireAnalyticsEvent({
    eventName: 'Debug',
    extraData: {
      data: {
        givenName: auth.userData?.given_name,
        familyName: auth.userData?.family_name,
        idTokenSub: auth.idToken?.sub,
        myIndividualId: myIndividual.id,
        myIndividualActiveTeamName: myIndividualActiveTeam?.name,
        myIndividualActiveTeamType: myIndividualActiveTeam?.type,
        myIndividualActiveTeamId: myIndividualActiveTeam?.id,
        appFrontendBrowserVersion: env.release.version,
        appFrontendDeployedVersion: releaseInfoQuery.data?.getReleaseInfo.appFrontendVersion,
        mechaBackendDeployedVersion: releaseInfoQuery.data?.getReleaseInfo.mechaVersion,
        flags: weaverFlags,
      },
    },
    enabled: allLoaded,
  })

  return (
    <WeaverIonPage id={`DebugPage`}>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader pageTitle='App Debug' navElement={NavElement.None} />
      </WeaverIonHeader>
      <WeaverIonContent>
        <WeaverCard title='Login'>
          <WeaverRow name='Name'>{auth.userData?.given_name} {auth.userData?.family_name}</WeaverRow>
          <WeaverRow name='Email'>{auth.userData?.email}</WeaverRow>
          <WeaverRow name='Auth Mode'>{auth.idToken?.sub.startsWith('google-oauth2|') ? 'Google' : 'Auth0 User/Pass'}</WeaverRow>
        </WeaverCard>
        <WeaverCard title='Identity & Team'>
          <WeaverRow name='Individual ID'><code>{myIndividual.id}</code></WeaverRow>
          <WeaverRow name='Team Name'>{myIndividualActiveTeam?.name}</WeaverRow>
          <WeaverRow name='Team Type'>{myIndividualActiveTeam?.type}</WeaverRow>
          <WeaverRow name='Team ID'><code>{myIndividualActiveTeam?.id}</code></WeaverRow>
        </WeaverCard>
        <WeaverCard title='System Versions'>
          <WeaverRow name='App Frontend Browser'><code>{env.release.version}</code></WeaverRow>
          <WeaverRow name='App Frontend Deployed'><code>{releaseInfoQuery.data?.getReleaseInfo.appFrontendVersion}</code></WeaverRow>
          <WeaverRow name='Mecha Backend Deployed'><code>{releaseInfoQuery.data?.getReleaseInfo.mechaVersion}</code></WeaverRow>
        </WeaverCard>
        <WeaverCard title='Flag Statuses'>
          {Object.entries(weaverFlags).map(([ flagName, flagValue ]) => (
            <WeaverRow key={flagName} name={flagName}><code>{JSON.stringify(flagValue, null, 2)}</code></WeaverRow>
          ))}
        </WeaverCard>
      </WeaverIonContent>
    </WeaverIonPage>
  )
}

export default DebugPage
