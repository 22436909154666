import React, { ComponentProps, Dispatch, SetStateAction, useEffect, useState } from 'react'
import classnames from 'classnames'
import { AnyLevelTask } from "./types"
import { IonButton, IonIcon, IonItem, IonLabel, IonPopover, useIonAlert, useIonViewWillLeave } from '@ionic/react'
import Styles from "./Task.module.scss"
import IonIconTyped from '../../../common/components/IonIconTyped'
import { TaskStatus, TaskActionableType, useGetActionableTaskQuery, useArchiveProjectMutation, useListMyProjectsAndLeadsQuery } from '../../../graphql/generated'
import { getTaskIcon, useActionableTaskFilter, useMyTasksFilter } from '../../../common/utils/tasks'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import useUncompleteTaskModal from './useUncompleteTaskModal'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'
import { checkmarkCircleOutline, closeCircleOutline, ellipsisHorizontalOutline } from 'ionicons/icons'
import { pageConfig_AllProjectTasks, pageConfig_Projects, pageConfig_ShowProject, useRouteTo } from '../../../routes'
import { useGraphQLDataSource } from '../../../api/graphql'
import { Duration } from 'luxon'
import { useMarkTaskCompleteMutation, useMarkTaskSkippedMutation } from '../../tasks/TaskActionerPage/commonActions'
import { ToastState } from '../../../api/providers/AchievementToastProvider/AchievementToastProvider'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { useQueryClient } from '@tanstack/react-query'

export const getStatusData = (status: TaskStatus) => {
  switch (status) {
    case TaskStatus.Completed:
      return { color: "success", label: "Complete" }
    case TaskStatus.Overdue:
      return { color: "danger", label: "Overdue" }
    case TaskStatus.InProgress:
      return { color: "tertiary", label: "In Progress" }
    case TaskStatus.Disabled:
      return { color: 'light', label: 'Locked' }
    case TaskStatus.NotStarted:
    default:
      return { color: "light", label: "Not started" }
  }
}

/** We do not want users to be changing their budget range after they have completed the confirm budget task */
const isUndoable = (task: AnyLevelTask) => {
  return  [ TaskStatus.Completed, TaskStatus.Skipped ].includes(task.status) &&
  ![ TaskActionableType.ConfirmProjectBudget, TaskActionableType.StartMatching ].includes(task.actionableType) // Add to this list tasks that should not be undoable
}

type HandleClick = (option: { id: string, parentId: string | undefined, event: React.MouseEvent<unknown, MouseEvent>}) => unknown | void

type TaskProps = {
  onAction?: HandleClick,
  task: AnyLevelTask,
  parentId?: string | undefined,
  isNextTask?: boolean,
  refetchTasks?: any,
  ionItemProps?: ComponentProps<typeof IonItem>,
  variant?: "interactive" | "display",
  nextTaskUI?: boolean,
  hostedOnChat?: boolean,
  popoverDirection?: ComponentProps<typeof IonPopover>["side"] ,
  setToast?: Dispatch<SetStateAction<ToastState | undefined>>,
  isPartOfToast?: boolean,
  isNextTaskButton?: boolean,
}

const Task: React.FC<TaskProps> = ({ task, parentId, onAction, ionItemProps, isNextTask = false, variant = "interactive", popoverDirection = "bottom", isNextTaskButton = false,  setToast, isPartOfToast = false  }) => {
  const isInteractive = variant === "interactive"
  const myTeam = useMyIndividualActiveTeam()
  const [ toastTimer, setToastTimer ] = useState<NodeJS.Timeout | null>(null)
  const datasource = useGraphQLDataSource({ api: 'core' })
  const archiveProjectMutation = useArchiveProjectMutation(datasource)
  const queryClient = useQueryClient()
  const [ present ] = useIonAlert()
  const getActionableTaskQuery = useGetActionableTaskQuery(
    datasource,
    { taskId: task.id ?? '' },
    {
      enabled: !!task.id,
      staleTime: Duration.fromObject({ minutes: 5 }).as('milliseconds'),
    },
  )

  const setTaskAsSkipped = useMarkTaskSkippedMutation(getActionableTaskQuery, { navigateOnSuccess: false })
  const setMarkTaskAsCompleted = useMarkTaskCompleteMutation(getActionableTaskQuery, { navigateOnSuccess: false })

  const {
    ["MW-2388-tasks-qol1-part2"]: tasksQuol1Part2,
    ["MW-2588-improve-next-step-ui"]: nextStepUI,
    ["MW-2602-toast-transition-that-directs-user-to-the-next-step"]: toastTransition,
    ["MW-2625-next-step-menu-area-interaction"]: menuInteraction,
  } = useWeaverFlags()
  const taskModal = useUncompleteTaskModal({ task })
  const [ showPopover, setShowPopover ] = useState<{open: boolean, event: Event | undefined}>({
    open: false,
    event: undefined,
  })

  useEffect(() => {
    if (isPartOfToast && startToastTimer && toastTransition) {
      startToastTimer()
    }

    return () => {
      if (isPartOfToast && toastTransition) {
        stopToastTimer()
      }
    }
  }, [])

  function startToastTimer() {
    setShowPopover({ open: false, event: undefined })
    setToastTimer(setTimeout(() => setToast && setToast({ message: task.title, id: task.id , state: "exit", task: task }), 10000))
    stopToastTimer()
  }

  function stopToastTimer() {
    if (toastTimer) {
      clearTimeout(toastTimer)
      setToastTimer(null)
    }
  }

  const confirmProjectBudgetTaskActionClicked = useAnalyticsEvent('Confirm_Project_Budget_Task_Clicked')
  const trigger_Next_Step_Clicked = useAnalyticsEvent('Next_Step_Clicked')
  const trigger_Next_Menu_Opened = useAnalyticsEvent('Next_Step_Menu_Opened')
  const trigger_Next_Step_Completed = useAnalyticsEvent('Next_Step_Completed')
  const trigger_Toast_Next_Step_Closed = useAnalyticsEvent("Toast_Next_Step_Closed")
  const trigger_Toast_Next_Step_Clicked = useAnalyticsEvent("Toast_Next_Step_Clicked")
  const trigger_Archived_Project = useAnalyticsEvent("Archive_Project")

  const goToAllTasksPage = useRouteTo(pageConfig_AllProjectTasks.path)
  const goToShowProject = useRouteTo(pageConfig_ShowProject.path)
  const goToAllProjectsPage = useRouteTo(pageConfig_Projects.path)

  const { id, icon, title, status, assignedTeam } = task

  const filterIsMyTask = useMyTasksFilter()
  const filterIsActionable = useActionableTaskFilter()

  const handleClick = onAction && (async (...params: Parameters<typeof onAction>) => {

    if (nextStepUI && isNextTaskButton && !isPartOfToast) {
      params[0]?.event.preventDefault()
      params[0]?.event.stopPropagation()
      await trigger_Next_Step_Clicked({
        projectId: task.projectId,
        taskId: task.id,
        taskTitle: task.title,
        taskStatus: task.status,
        taskActionableType: task.actionableType,
        taskDueAt: task?.dueAt,
        taskActionablePayload: task?.actionablePayload,
      })
    }

    if (toastTransition && isNextTaskButton && isPartOfToast) {
      params[0]?.event.preventDefault()
      params[0]?.event.stopPropagation()
      setShowPopover({ open: false, event: undefined })
      if (isPartOfToast) {
        await closeToast(false)
      }
      await trigger_Toast_Next_Step_Clicked({
        projectId: task.projectId,
        taskId: task.id,
        taskTitle: task.title,
        taskStatus: task.status,
        taskActionableType: task.actionableType,
        taskDueAt: task?.dueAt,
        taskActionablePayload: task?.actionablePayload,
      })
    }

    if (isActionable){
      if (task.actionableType === TaskActionableType.ConfirmProjectBudget) {
        confirmProjectBudgetTaskActionClicked({
          projectId: task.projectId,
          taskId: task.id,
          taskTitle: task.title,
          taskStatus: task.status,
          taskActionableType: task.actionableType,
          taskDueAt: task?.dueAt,
          taskActionablePayload: task?.actionablePayload,
        })
      }
      return onAction(...params)
    }
  })

  const isMyTask = filterIsMyTask(task)
  const isActionable = isInteractive ? filterIsActionable(task): false
  const showDetail = isActionable
  const iconName = getTaskIcon(icon, task)

  const markTaskAsComplete = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    await setMarkTaskAsCompleted()
    await trigger_Next_Step_Completed({
      projectId: task.projectId,
      taskId: task.id,
      taskTitle: task.title,
      taskStatus: TaskStatus.Completed,
      taskActionableType: task.actionableType,
      taskDueAt: task?.dueAt,
      taskActionablePayload: task?.actionablePayload,
    })
  }

  const markTaskAsCompleteFromPopover = async (e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    setShowPopover({ open: false, event: undefined })
    e.preventDefault()
    e.stopPropagation()
    await setMarkTaskAsCompleted()
  }

  const removeProject = async (e: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    await archiveProjectMutation.mutateAsync({ projectId: task.projectId, teamId: myTeam?.id ?? '' }, {
      onSuccess: () => {
        // invalidate query
        trigger_Archived_Project({
          projectId: task.projectId,
        })
        queryClient.invalidateQueries({ queryKey: useListMyProjectsAndLeadsQuery.getKey() })
        console.debug(`[Task button]: Routing to Projects page`)
        goToAllProjectsPage({})()
      },
      onError: (e) => {
        console.debug(`[Task button]:`, e)
      },
    })
  }

  const skipTask = async (event: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    if (isPartOfToast) {
      stopToastTimer()
    }
    setShowPopover({ open: false, event: undefined })
    event.preventDefault()
    event.stopPropagation()
    await setTaskAsSkipped()
    await closeToast(false)
  }

  const openPopup = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isPartOfToast) {
      stopToastTimer()
    }
    event.preventDefault()
    event.stopPropagation()
    setShowPopover({ open: true, event: event.nativeEvent })
    await trigger_Next_Menu_Opened({
      projectId: task.projectId,
      taskId: task.id,
      taskTitle: task.title,
      taskStatus: task.status,
      taskActionableType: task.actionableType,
      taskDueAt: task?.dueAt,
      taskActionablePayload: task?.actionablePayload,
    })
  }

  const navigateToAllTasksPage = async (event: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    setShowPopover({ open: false, event: undefined })
    if (isPartOfToast && toastTransition) {
      stopToastTimer()
      await closeToast(false)
    }
    event.preventDefault()
    event.stopPropagation()
    goToAllTasksPage({ projectId: task.projectId })()
  }

  const navigateToShowProject = (event: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    if (isPartOfToast && toastTransition) {
      stopToastTimer()
    }
    event.preventDefault()
    event.stopPropagation()
    setShowPopover({ open: false, event: undefined })
    goToShowProject({ id: task.projectId })()
  }

  const closeToast = async (fireEvent: boolean) => {
    stopToastTimer()
    setToast && setToast({ message: task.title, id: task.id , state: "exit", task: task })
    setShowPopover({ open: false, event: undefined })
    if (fireEvent) {
      await trigger_Toast_Next_Step_Closed({
        projectId: task.projectId,
        taskId: task.id,
        taskTitle: task.title,
        taskStatus: task.status,
        taskActionableType: task.actionableType,
        taskDueAt: task?.dueAt,
        taskActionablePayload: task?.actionablePayload,
      })
    }
  }

  const openRemoveProjectAlert = (event: React.MouseEvent<HTMLIonItemElement, MouseEvent>) => {
    present({
      header: "Remove project",
      message: "You are about to remove this project. This action can not be undone. Are you sure you wish to proceed? ",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "OK",
          handler: () => removeProject(event),
        },
      ],
    })
  }

  useIonViewWillLeave(() => setShowPopover({ open: false, event: undefined }))

  if (isNextTaskButton && nextStepUI) {
    return (
      <>
        {!menuInteraction && <hr className={`${Styles.topHorizontalHr} ${isPartOfToast ? Styles.flipping: undefined}`} /> }
        <IonItem lines='none'
          key={id}
          onClick={(event) => handleClick && handleClick({ id, parentId, event })}
          className={`${Styles.nextTaskItem} ${menuInteraction ? Styles.menuInteraction : undefined} ${isPartOfToast ? Styles.flipping: undefined}`}
          detail={false}>
          <div className={`${Styles.outerContainer} ${menuInteraction ? Styles.menuInteraction : undefined}`}>
            <section className={Styles.container}>
              <IonIconTyped className={Styles.icon} iconName={iconName} />
            </section>
          </div>
          <div className={Styles.itemContainer}>
            <span className={Styles.nextStepLabel}>
              Next step
            </span>
            <div className={Styles.nextTaskActionRow}>
              <div onClick={markTaskAsComplete}>
                <IonIcon className={Styles.checkMarkIcon} icon={checkmarkCircleOutline} />
              </div>
              <div className={Styles.taskName}>
                {title}
              </div>
            </div>
          </div>
          <div>
          </div>
          <div className={`${Styles.alignEndContainer} ${menuInteraction ? Styles.menuInteraction : undefined}  ${isPartOfToast && menuInteraction ? Styles.flipping: undefined} ion-no-padding ion-no-margin`} slot='end' onClick={(e) => openPopup(e)}  >
            { menuInteraction &&
              <hr className={`${Styles.horizontalHr} ${isPartOfToast ? Styles.flipping: undefined} ion-no-padding`} />
            }
            <IonIcon className={Styles.ellipsisIcon} icon={ellipsisHorizontalOutline}  />
          </div>
        </IonItem>
        {!!setToast && <IonIcon  onClick={() => closeToast(true)} className={Styles.closeIcon} icon={closeCircleOutline}  /> }
        <IonPopover
          className={`${Styles.popOver} ${popoverDirection === "top" ? Styles.onChat : undefined} ${isPartOfToast ? Styles.flipping : undefined }`}
          isOpen={showPopover.open}
          side={popoverDirection === "top" ? "top" : "bottom"}
          alignment="end"
          event={showPopover.event}
          showBackdrop={false}
          onDidDismiss={() => setShowPopover({ open: false, event: undefined })}>
          <IonItem onClick={(e) => markTaskAsCompleteFromPopover(e)}>
            Mark task as complete
          </IonItem>
          <IonItem onClick={(e) => skipTask(e)}>
            Skip task
          </IonItem>
          <IonItem onClick={(e) => navigateToAllTasksPage(e)}>
            Show all tasks
          </IonItem>
          <IonItem onClick={(e) => navigateToShowProject(e)}>
            Show project
          </IonItem>
          {!isPartOfToast && <IonItem className={Styles.removeProject} onClick={(e) => openRemoveProjectAlert(e)}>
            Remove project
          </IonItem>}
        </IonPopover>
      </>
    )
  }

  return <IonItem lines='full' className={`${classnames({ [Styles.taskItem]: true,
    [Styles.actionable]: isActionable,
    [Styles.completed]: status === TaskStatus.Completed,
    [Styles.notStarted]: status === TaskStatus.NotStarted,
    [Styles.locked]: status === TaskStatus.Disabled,
    [Styles.nextStep]: tasksQuol1Part2 && isNextTask,
    [Styles.skipped]: status === TaskStatus.Skipped })}`}
  key={id}
  onClick={(event) => handleClick && handleClick({ id, parentId, event })}
  detail={showDetail} {...ionItemProps}>
    <section className={Styles.iconContainer}>
      <IonIconTyped className={`${Styles.taskIcon}`} iconName={iconName} />
    </section>
    <div className={Styles.itemContentContainer}>
      <IonLabel>
        {title}
      </IonLabel>
      <div className={Styles.subtextContainer}>
        <div>
          <span>{assignedTeam?.name}</span>
        </div>
      </div>
    </div>
    {isInteractive && (
      // The modal contains another <Task>, so this must not be rendered in variant='display' contexts, otherwise it will be an infinite recursion
      <>
        {isMyTask && isUndoable(task) ? <IonButton fill='clear' onClick={() => taskModal.open()}><IonIconTyped size='small' slot="start" iconName="arrowUndo" /></IonButton> : null }
        {taskModal.render()}
      </>
    )}
  </IonItem>
}

export default Task
