import { IonModal, IonToolbar, IonTitle, IonContent, IonGrid, IonRow, IonCol, IonNote, IonTextarea } from "@ionic/react"
import classnames from 'classnames'
import React, { ComponentProps, useEffect, useState } from "react"

import Styles from "./ProfileDataForAIModal.module.scss"
import SingleClickButton from "../../../../../common/components/SingleClickButton"
import { useMyIndividual, useMyIndividualActiveTeam } from "../../../../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { useGraphQLDataSource } from "../../../../../api/graphql"
import { useSetIndividualBioMutation, useSetTeamBioMutation } from "../../../../../graphql/generated"

const DEFAULT_MODAL_BREAKPOINT = 0.93

type ProfileDataForAIModalProps = {
  onSuccess: () => void,
} & ComponentProps<typeof IonModal>

const ProfileDataForAIModal: React.FC<ProfileDataForAIModalProps> =({ onSuccess,  ...ionModalProps }) => {
  if (!ionModalProps.initialBreakpoint) {
    ionModalProps.initialBreakpoint = DEFAULT_MODAL_BREAKPOINT
  }

  if (!ionModalProps.breakpoints) {
    ionModalProps.breakpoints = [ 0, DEFAULT_MODAL_BREAKPOINT ]
  }

  const myIndividual = useMyIndividual()
  const myTeam = useMyIndividualActiveTeam()

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const setIndividualBioMutation = useSetIndividualBioMutation(gqlDataSource)
  const setTeamBioMutation = useSetTeamBioMutation(gqlDataSource)

  const [ userBio, setUserBio ] = useState<string>()
  const [ teamBio, setTeamBio ] = useState<string>()

  useEffect(() => {
    if (!myIndividual.bio) return
    setUserBio( myIndividual.bio)
  }, [ myIndividual.bio ])

  useEffect(() => {
    if (!myTeam?.bio) return
    setTeamBio(myTeam.bio)
  }, [ myTeam?.bio ])

  const onSaveClicked = async () => {
    await setIndividualBioMutation.mutateAsync({
      individualId: myIndividual.id,
      bio: userBio,
    })

    if (myTeam?.id){
      await setTeamBioMutation.mutateAsync({
        teamId: myTeam?.id,
        bio: teamBio,
      })
    }
    onSuccess()
  }

  return <IonModal
    className={Styles.modal}
    {...ionModalProps}>
    <IonToolbar>
      <IonTitle>Weaver AI</IonTitle>
    </IonToolbar>
    <IonContent>
      <div className={`${classnames({
        [Styles.modalContainer]: true,
        [Styles.editableInputs]: true,
      })}`} >
        <p>We will use this to improve the Weaver AI suggestions</p>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonTextarea
                className={Styles.bioTextArea}
                enterkeyhint='enter'
                onIonChange={(event: CustomEvent) => {
                  setUserBio(event.detail.value)                  
                }}
                autoGrow
                rows={4}
                value={userBio}
                data-testid="user-bio-text-area"
                placeholder={"Describe yourself"}
              />

              <IonTextarea
                className={Styles.bioTextArea}
                enterkeyhint='enter'
                onIonChange={(event: CustomEvent) => {
                  setTeamBio(event.detail.value)                  
                }}
                autoGrow
                rows={4}
                value={teamBio}
                data-testid="team-bio-text-area"
                placeholder={"Describe your company"}
              />
            </IonCol>
          </IonRow>
          <SingleClickButton expand='block' disabled={false} onClick={() => onSaveClicked()}>Save</SingleClickButton>
        </IonGrid>
      </div>
    </IonContent>
  </IonModal>
}

export default ProfileDataForAIModal
