import { TeamType, CorporateStructureType, ProjectType, ProgressionStatus, LocationArea } from "../../../graphql/generated"
import { progressionStatusBadgeLabel } from "../../profile/ContractorLeadPreferences/ContractorBudgetRanges/progressionStatus.i18n"
import { OnboardingStepStatus } from './pages/OnboardingSignupSteps/config'

export const selectableTeamTypeLabels: Partial<Record<TeamType, string>> = {
  [ TeamType.Architect ]: 'Architect',
  [ TeamType.Contractor ]: 'Main Contractor',
  [ TeamType.Homeowner ]: 'Homeowner',
  // No Weaver
}

export const corporateStructureTypeLabels: Record<CorporateStructureType, string> = {
  [ CorporateStructureType.LimitedCompany ]: 'Limited Company',
  [ CorporateStructureType.SoleTrader ]: 'Sole Trader',
  [ CorporateStructureType.Partnership ]: 'Partnership',
  [ CorporateStructureType.Other ]: 'Other',
}

export const projectTypeLabels: Record<ProjectType, string> = {
  [ ProjectType.SideExtension ]: 'Side Extension',
  [ ProjectType.RearExtension ]: 'Rear Extension',
  [ ProjectType.GardenRoom ]: 'Garden Room',
  [ ProjectType.LoftConversion ]: 'Loft Conversion',
  [ ProjectType.NewBuild ]: 'New Build',
  [ ProjectType.Conservatory ]: 'Conservatory',
  [ ProjectType.ChangeOfUse ]: 'Change of Use',
  [ ProjectType.Refurbishment ]: 'Refurbishment',
  [ ProjectType.FullRenovation ]: 'Full Renovation',
  [ProjectType.FirstFloorExtension]: '1st Floor Extension',
  [ProjectType.TwoStoreyExtension]: 'Two Storey Extension',
  [ProjectType.WrapAroundExtension]: 'Wrap-around Extension',
  [ProjectType.GarageConversion]: 'Garage Conversion',
  [ProjectType.BasementConversion]: 'Basement Conversion',
}

export const onboardingStepStatusLabels: Record<OnboardingStepStatus, string> = {
  [ OnboardingStepStatus.ToDo ]: progressionStatusBadgeLabel[ProgressionStatus.NotStarted],
  [ OnboardingStepStatus.Done ]: progressionStatusBadgeLabel[ProgressionStatus.Completed],
  [ OnboardingStepStatus.Next ]: progressionStatusBadgeLabel[ProgressionStatus.WaitingOnUser],
}

export const locationAreaLabels: Record<LocationArea, string> = {
  [ LocationArea.LondonCentral ]: 'London Central',
  [ LocationArea.LondonNorth ]: 'London North',
  [ LocationArea.LondonSouth ]: 'London South',
  [ LocationArea.LondonWest ]: 'London West',
  [ LocationArea.LondonEast ]: 'London East',
}
