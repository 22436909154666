import React, { useCallback } from 'react'
import { helpBuoy, chatboxOutline, peopleOutline, homeOutline } from 'ionicons/icons'
import { ExtractRouteParams, generatePath, Redirect, Route } from 'react-router'
import { ChatRoom, MyChats } from './domains/chats'
import Help from './domains/help'
import ProjectsIndex, { CreateProject, ShowProject } from './domains/projects'
import DeveloperSettings from './domains/devSettings'
import ProjectChat from './domains/projects/ProjectChat'
import { AnimationBuilder, IonIcon, IonLabel, IonRouteProps, IonTabButton, RouteAction, RouterDirection, RouterOptions, useIonRouter } from '@ionic/react'
import ShowLead from './domains/projects/ShowLeadPage'
import { TeamType } from './graphql/generated'
import NotImplementedPage from './common/components/NotImplementedPage'
import TechDemoTab from './domains/techDemo'
import { UploadDocuments, UploadedProjectDocumentSuccess } from './domains/projects/documents'
import { MyIndividualActiveTeam, useMyIndividual } from './api/providers/MyIndividualProvider/MyIndividualProvider'
import SuccessPage from './common/components/SuccessPage/SuccessPage'
import ContractorLeadPreferencesPage from './domains/profile/ContractorLeadPreferences'
import ContractorBudgetRanges from './domains/profile/ContractorLeadPreferences/ContractorBudgetRanges'
import UnlockContractorBudgetRange from './domains/profile/ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange'
import NewWorkHistory from './domains/profile/ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/NewWorkHistory'
import WorkHistoryReferences from './domains/profile/ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/WorkHistoryReferences'
import WorkHistoryPhotos from './domains/profile/ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/WorkHistoryPhotos'
import DisplayContractorLeadAcceptorPage from './domains/projects/DisplayContractorLeadAcceptorPage/DisplayContractorLeadAcceptorPage'
import ContractorLeadLocations from './domains/profile/ContractorLeadPreferences/ContractorLeadLocations'
import TeamTypeSelector from './domains/onboarding/TeamTypeSelector'
import OnboardingArchitectStageOne from './domains/onboarding/team/flows/OnboardingArchitect/OnboardingArchitect'
import OnboardingWeaver from './domains/onboarding/team/flows/OnboardingWeaver'
import OnboardingHomeownerStageOne from './domains/onboarding/team/flows/OnboardingHomeowner/OnboardingHomeowner'
import OnboardingContractorStageOne from './domains/onboarding/team/flows/OnboardingContractor/OnboardingContractor'
import DisplayMyProfile from './domains/profile/DisplayMyProfile'
import DisplayPublicProfile from './domains/profile/DisplayPublicProfile'
import EditMyProfile from './domains/profile/EditMyProfile'
import ContractorProfileBudgetRangeAwardBadgePage from './domains/profile/ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/ContractorProfileBudgetRangeBadge/ContractorProfileBudgetRangeBadgePage'
import { withPageConfig } from './routesProvider'
import { withWeaverStructureChecker } from './common/components/WeaverIonWrappers/WeaverStructureChecker'
import ForgetMeNoteTab from './domains/techDemo/ForgetMeNotePage'
import ChatterTab from './domains/techDemo/ChatterPage'
import WorkHistoryForReferencePage from './domains/external/reference/WorkHistoryForReferencePage'
import DisplayContractorLeadAcceptorStripeCallbackPage from './domains/projects/DisplayContractorLeadAcceptorPage/DisplayContractorLeadAcceptorStripeCallbackPage'
import TabNavBar from './common/components/TabNavBar'
import ChatRoomOptionsPage from './domains/chats/chatRoom/ChatRoomOptions'
import ProjectDocuments from './domains/projects/ProjectDocumentsPage'
import { MyProjectTasksPage } from './domains/projects/checklist/MyProjectTasksPage'
import AllProjectTasks from './domains/projects/checklist/AllProjectTasksPage'
import ChatRoomMembers from './domains/chats/chatRoom/ChatRoomMembers'
import { PWAStartPage } from './common/components/PWAStartPage/PWAStartPage'
import TaskActionerPage from './domains/tasks/TaskActionerPage/TaskActionerPage'
import StartMatchingStripeCallbackPage from './domains/tasks/TaskActionerPage/ScreenStartMatching/StartMatchingStripeCallbackPage'
import { useInvisiblePageRouterHack } from './routesInvisiblePageHack'
import { UnlockContractorBudgetRangeMenuPage } from './domains/profile/ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/UnlockContractorBudgetRangeMenu'
import { Tab } from './common/components/TabNavBar/TabNavBar'
import DebugPage from './domains/chats/debug/DebugPage'
import DisplayPaymentPlanPickerPage from './domains/projects/DisplayContractorLeadAcceptorPage/DisplayPaymentPlanPickerPage'
import DisplaySingleLeadPaymentPage from './domains/projects/DisplayContractorLeadAcceptorPage/DisplaySingleLeadPaymentPage'

export enum Page {
  Debug = "Debug",
  Onboarding = "Onboarding",
  Onboarding_Contractor = "Onboarding_Contractor",
  Onboarding_Architect = "Onboarding_Architect",
  Onboarding_Homeowner = "Onboarding_Homeowner",
  Onboarding_Weaver = "Onboarding_Weaver",
  Profile = "Profile",
  Profile_Edit = "Profile_Edit",
  Profile_Public = "Profile_Public",
  Profile_ContractorLeadPreferences = "Profile_ContractorLeadPreferences",
  Profile_ContractorLeadPreferences_ContractorBudgetRanges = "Profile_ContractorLeadPreferences_ContractorBudgetRanges",
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu = "Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu",
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange = "Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange",
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory = "Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory",
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryReferences = "Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryReferences",
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryPhotos = "Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryPhotos",
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_AwardBadge = "Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_AwardBadge",
  Profile_ContractorLeadPreferences_ContractorLeadLocations = "Profile_ContractorLeadPreferences_ContractorLeadLocations",
  Projects = "Projects",
  Projects_ContractorLeadAcceptor_StripeCallback = "Projects_ContractorLeadAcceptor_StripeCallback",
  Projects_ContractorLeadAcceptor = "Projects_ContractorLeadAcceptor",
  Projects_AcceptLead_PaymentPlanPicker_StripeCallback = "Projects_AcceptLead_PaymentPlanPicker_StripeCallback",
  Projects_AcceptLead_PaymentPlanPicker = "Projects_AcceptLead_PaymentPlanPicker",
  Projects_AcceptLead_SingleLeadPayment_StripeCallback = "Projects_AcceptLead_SingleLeadPayment_StripeCallback",
  Projects_AcceptLead_SingleLeadPayment = "Projects_AcceptLead_SingleLeadPayment",
  WorkHistoryForReference = "WorkHistoryForReference",
  MyChats = "MyChats",
  ChatRoom = "ChatRoom",
  ChatRoomMembers = "ChatRoomMembers",
  ChatRoomOptions = "ChatRoomOptions",
  Help = "Help",
  DeveloperSettings = "DeveloperSettings",
  CreateProject = "CreateProject",
  ShowProject = "ShowProject",
  ProjectChat = "ProjectChat",
  MyProjectTasks = "MyProjectTasks",
  AllProjectTasks = "AllProjectTasks",
  ProjectDocuments = "ProjectDocuments",
  ShowLead = "ShowLead",
  StartMatchingStripeCallback = "StartMatchingStripeCallback",
  TaskActioner = "TaskActioner",
  TechDemo = "TechDemo",
  TechDemo_ForgetMeNote = "TechDemo_ForgetMeNote",
  TechDemo_Chatter = "TechDemo_Chatter",
  NotImplemented = "NotImplemented",
  Success = "Success",
  UploadedProjectDocumentSuccess = 'UploadedProjectDocumentSuccess',
  UploadProjectDocuments = "UploadProjectDocuments",
  LaunchPWA = "LaunchPWA",
}

export enum TabDisplay {
  /** Hide the tabs */
  None = "None",
  /** Show the tabs fixed at the bottom (as standard) */
  Fixed = "Fixed",
}

export type PageConfig = {
  page: Page,
  path?: IonRouteProps['path'],
  exact?: IonRouteProps['exact'],
  tabDisplay?: TabDisplay,
}

export const defaultTabDisplay: TabDisplay = TabDisplay.Fixed

const defaultPageConfig: Partial<PageConfig> = {
  tabDisplay: defaultTabDisplay,
}

export type PageRender = IonRouteProps['render']

export const pageConfig_Debug: {
  page: Page.Debug,
  path: "/debug",
} = {
  page: Page.Debug,
  path: "/debug",
}

export const pageConfig_Onboarding: {
  page: Page.Onboarding,
  path: "/onboarding",
} = {
  page: Page.Onboarding,
  path: "/onboarding",
}

export const pageConfig_Onboarding_Architect: {
  page: Page.Onboarding_Architect,
  path: "/onboarding/architect",
} = {
  page: Page.Onboarding_Architect,
  path: "/onboarding/architect",
}

export const pageConfig_Onboarding_Contractor: {
  page: Page.Onboarding_Contractor,
  path: "/onboarding/contractor",
} = {
  page: Page.Onboarding_Contractor,
  path: "/onboarding/contractor",
}

export const pageConfig_Onboarding_Homeowner: {
  page: Page.Onboarding_Homeowner,
  path: "/onboarding/homeowner",
} = {
  page: Page.Onboarding_Homeowner,
  path: "/onboarding/homeowner",
}

export const pageConfig_Onboarding_Weaver: {
  page: Page.Onboarding_Weaver,
  path: "/onboarding/weaver",
} = {
  page: Page.Onboarding_Weaver,
  path: "/onboarding/weaver",
}

export const pageConfig_Profile: {
  page: Page.Profile,
  path: "/profile",
} = {
  page: Page.Profile,
  path: "/profile",
}

export const pageConfig_Profile_Edit: {
  page: Page.Profile_Edit,
  path: "/profile/edit",
} = {
  page: Page.Profile_Edit,
  path: "/profile/edit",
}

export const pageConfig_Profile_Public: {
  page: Page.Profile_Public,
  path: "/profile/:teamType/:teamId",
} = {
  page: Page.Profile_Public,
  path: "/profile/:teamType/:teamId",
}

export const pageConfig_Profile_ContractorLeadPreferences: {
  page: Page.Profile_ContractorLeadPreferences,
  path: "/profile/edit/contractor/leadPreferences",
} = {
  page: Page.Profile_ContractorLeadPreferences,
  path: "/profile/edit/contractor/leadPreferences",
}

export const pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges: {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges,
  path: "/profile/edit/contractor/leadPreferences/contractorBudgetRanges",
  tabDisplay: TabDisplay.None,
} = {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges,
  path: "/profile/edit/contractor/leadPreferences/contractorBudgetRanges",
  tabDisplay: TabDisplay.None,
}

export const pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange: {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange,
  path: "/profile/edit/contractor/leadPreferences/budgetRanges/unlockContractorBudgetRange/:budgetRange",
  tabDisplay: TabDisplay.None,
} = {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange,
  path: "/profile/edit/contractor/leadPreferences/budgetRanges/unlockContractorBudgetRange/:budgetRange",
  tabDisplay: TabDisplay.None,
}

export const pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory: {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory,
  path: "/profile/edit/contractor/leadPreferences/budgetRanges/unlockContractorBudgetRange/:budgetRange/workHistory/new",
  tabDisplay: TabDisplay.None,
} = {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory,
  path: "/profile/edit/contractor/leadPreferences/budgetRanges/unlockContractorBudgetRange/:budgetRange/workHistory/new",
  tabDisplay: TabDisplay.None,
}

export const pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu: {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu,
  path: "/profile/edit/contractor/leadPreferences/budgetRanges/unlockContractorBudgetRange/:budgetRange/workHistory/:workHistoryId",
  tabDisplay: TabDisplay.None,
} = {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu,
  path: "/profile/edit/contractor/leadPreferences/budgetRanges/unlockContractorBudgetRange/:budgetRange/workHistory/:workHistoryId",
  tabDisplay: TabDisplay.None,
}

export const pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryReferences: {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryReferences,
  path: "/profile/edit/contractor/leadPreferences/budgetRanges/unlockContractorBudgetRange/:budgetRange/workHistory/:workHistoryId/references",
  tabDisplay: TabDisplay.None,
} = {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryReferences,
  path: "/profile/edit/contractor/leadPreferences/budgetRanges/unlockContractorBudgetRange/:budgetRange/workHistory/:workHistoryId/references",
  tabDisplay: TabDisplay.None,
}

export const pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryPhotos: {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryPhotos,
  path: "/profile/edit/contractor/leadPreferences/budgetRanges/unlockContractorBudgetRange/:budgetRange/workHistory/:workHistoryId/photos",
  tabDisplay: TabDisplay.None,
} = {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryPhotos,
  path: "/profile/edit/contractor/leadPreferences/budgetRanges/unlockContractorBudgetRange/:budgetRange/workHistory/:workHistoryId/photos",
  tabDisplay: TabDisplay.None,
}

export const pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_AwardBadge: {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_AwardBadge,
  path: "/profile/edit/contractor/leadPreferences/budgetRanges/unlockContractorBudgetRange/:budgetRange/workHistory/:workHistoryId/badge",
  tabDisplay: TabDisplay.None,
} = {
  page: Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_AwardBadge,
  path: "/profile/edit/contractor/leadPreferences/budgetRanges/unlockContractorBudgetRange/:budgetRange/workHistory/:workHistoryId/badge",
  tabDisplay: TabDisplay.None,
}

export const pageConfig_Profile_ContractorLeadPreferences_ContractorLeadLocations: {
  page: Page.Profile_ContractorLeadPreferences_ContractorLeadLocations,
  path: "/profile/edit/contractor/leadPreferences/locations",
  tabDisplay: TabDisplay.None,
} = {
  page: Page.Profile_ContractorLeadPreferences_ContractorLeadLocations,
  path: "/profile/edit/contractor/leadPreferences/locations",
  tabDisplay: TabDisplay.None,
}

export const pageConfig_Projects: {
  page: Page.Projects,
  path: "/projects",
} = {
  page: Page.Projects,
  path: "/projects",
}

export const pageConfig_WorkHistoryForReference: {
  page: Page.WorkHistoryForReference,
  path: "/external/reference/:referenceId",
} = {
  page: Page.WorkHistoryForReference,
  path: "/external/reference/:referenceId",
}

export const pageConfig_Projects_ContractorLeadAcceptor_StripeCallback: {
  page: Page.Projects_ContractorLeadAcceptor_StripeCallback,
  path: "/projects2/lead/:leadId/stripe/:result",
  tabDisplay: TabDisplay.None,
} = {
  page: Page.Projects_ContractorLeadAcceptor_StripeCallback,
  path: "/projects2/lead/:leadId/stripe/:result",
  tabDisplay: TabDisplay.None,
}

export const pageConfig_Projects_ContractorLeadAcceptor: {
  page: Page.Projects_ContractorLeadAcceptor,
  path: "/projects2/lead/:leadId",
  tabDisplay: TabDisplay.None,
} = {
  page: Page.Projects_ContractorLeadAcceptor,
  path: "/projects2/lead/:leadId",
  tabDisplay: TabDisplay.None,
}

export const pageConfig_Projects_AcceptLead_PaymentPlanPicker_StripeCallback: {
  page: Page.Projects_AcceptLead_PaymentPlanPicker_StripeCallback,
  path: "/projects2/lead2/:leadId/stripe/:result",
  tabDisplay: TabDisplay.None,
} = {
  page: Page.Projects_AcceptLead_PaymentPlanPicker_StripeCallback,
  path: "/projects2/lead2/:leadId/stripe/:result",
  tabDisplay: TabDisplay.None,
}

export const pageConfig_Projects_AcceptLead_PaymentPlanPicker: {
  page: Page.Projects_AcceptLead_PaymentPlanPicker,
  path: "/projects2/lead2/:leadId",
} = {
  page: Page.Projects_AcceptLead_PaymentPlanPicker,
  path: "/projects2/lead2/:leadId",
}

export const pageConfig_Projects_AcceptLead_SingleLeadPayment_StripeCallback: {
  page: Page.Projects_AcceptLead_SingleLeadPayment_StripeCallback,
  path: "/projects2/lead2/:leadId/single/stripe/:result",
  tabDisplay: TabDisplay.None,
} = {
  page: Page.Projects_AcceptLead_SingleLeadPayment_StripeCallback,
  path: "/projects2/lead2/:leadId/single/stripe/:result",
  tabDisplay: TabDisplay.None,
}

export const pageConfig_Projects_AcceptLead_SingleLeadPayment: {
  page: Page.Projects_AcceptLead_SingleLeadPayment,
  path: "/projects2/lead2/:leadId/single",
} = {
  page: Page.Projects_AcceptLead_SingleLeadPayment,
  path: "/projects2/lead2/:leadId/single",
}

export const pageConfig_CreateProject: {
  page: Page.CreateProject,
  path: "/new-project",
  tabDisplay: TabDisplay.None,
} = {
  page: Page.CreateProject,
  path: "/new-project",
  tabDisplay: TabDisplay.None,
}

export const pageConfig_ShowProject: {
  page: Page.ShowProject,
  path: "/projects/:id",
} = {
  page: Page.ShowProject,
  path: "/projects/:id",
}

export const pageConfig_Project_Documents: {
  page: Page.ProjectDocuments,
  path: "/projects/:id/documents",
} = {
  page: Page.ProjectDocuments,
  path: "/projects/:id/documents",
}

export const pageConfig_ProjectChat: {
  page: Page.ProjectChat,
  path: "/projects/:projectId/chatd",
} = {
  page: Page.ProjectChat,
  path: "/projects/:projectId/chatd",
}

export const pageConfig_MyProjectTasks: {
  page: Page.MyProjectTasks,
  path: "/projects/:projectId/tasks/my",
} = {
  page: Page.MyProjectTasks,
  path: "/projects/:projectId/tasks/my",
}

export const pageConfig_AllProjectTasks: {
  page: Page.AllProjectTasks,
  path: "/projects/:projectId/tasks",
} = {
  page: Page.AllProjectTasks,
  path: "/projects/:projectId/tasks",
}

export const pageConfig_UploadProjectDocuments: {
  page: Page.UploadProjectDocuments,
  path: "/projects/:projectId/upload-documents",
  exact: false,
} = {
  page: Page.UploadProjectDocuments,
  path: "/projects/:projectId/upload-documents",
  exact: false,
}

export const pageConfig_ShowLead: {
  page: Page.ShowLead,
  path: "/leads/:id",
  tabDisplay: TabDisplay.None,
} = {
  page: Page.ShowLead,
  path: "/leads/:id",
  tabDisplay: TabDisplay.None,
}

export const pageConfig_TaskActioner: {
  page: Page.TaskActioner,
  path: "/tasks/:taskId",
} = {
  page: Page.TaskActioner,
  path: "/tasks/:taskId",
}

export const pageConfig_StartMatchingStripeCallback: {
  page: Page.StartMatchingStripeCallback,
  path: "/tasks/:taskId/project/:projectId/startMatching/stripe/:result",
} = {
  page: Page.StartMatchingStripeCallback,
  path: "/tasks/:taskId/project/:projectId/startMatching/stripe/:result",
}

export const pageConfig_MyChats: {
  page: Page.MyChats,
  path: "/chats",
} = {
  page: Page.MyChats,
  path: "/chats",
}

export const pageConfig_ChatRoom: {
  page: Page.ChatRoom,
  path: "/chats/:chatRoomId",
  exact: false,
} = {
  page: Page.ChatRoom,
  path: "/chats/:chatRoomId",
  exact: false,
}

export const pageConfig_ChatRoomMembers: {
  page: Page.ChatRoomMembers,
  path: "/chatMembers/:chatRoomId",
  exact: false,
} = {
  page: Page.ChatRoomMembers,
  path: "/chatMembers/:chatRoomId",
  exact: false,
}

export const pageConfig_ChatRoomOptions: {
  page: Page.ChatRoomOptions,
  path: "/projects/:projectId/chat-options/:teamId",
  exact: false,
} = {
  page: Page.ChatRoomOptions,
  path: "/projects/:projectId/chat-options/:teamId",
  exact: false,
}

export const pageConfig_Help: {
  page: Page.Help,
  path: "/help",
} = {
  page: Page.Help,
  path: "/help",
}

export const pageConfig_DeveloperSettings: {
  page: Page.DeveloperSettings,
  path: "/dev-settings",
  exact: false,
} = {
  page: Page.DeveloperSettings,
  path: "/dev-settings",
  exact: false,
}

export const pageConfig_TechDemo: {
  page: Page.TechDemo,
  path: "/tech-demo",
} = {
  page: Page.TechDemo,
  path: "/tech-demo",
}

export const pageConfig_TechDemo_Chatter: {
  page: Page.TechDemo_Chatter,
  path: "/tech-demo/chatter",
} = {
  page: Page.TechDemo_Chatter,
  path: "/tech-demo/chatter",
}

export const pageConfig_TechDemo_ForgetMeNote: {
  page: Page.TechDemo_ForgetMeNote,
  path: "/tech-demo/forget-me-note",
} = {
  page: Page.TechDemo_ForgetMeNote,
  path: "/tech-demo/forget-me-note",
}

export const pageConfig_Success: {
  page: Page.Success,
  path: "/success",
} = {
  page: Page.Success,
  path: "/success",
}

export const pageConfig_UploadedProjectDocumentSuccess: {
  page: Page.UploadedProjectDocumentSuccess,
  path: "/uploadProjectDocumentSuccess/:projectId",
} = {
  page: Page.UploadedProjectDocumentSuccess,
  path: "/uploadProjectDocumentSuccess/:projectId",
}

export const pageConfig_LauchPWA: {
  page: Page.LaunchPWA,
  path: "/launch-pwa",

} = {
  page: Page.LaunchPWA,
  path: "/launch-pwa",
}

export const pageConfig_NotImplemented: {
  page: Page.NotImplemented,
} = {
  page: Page.NotImplemented,
}

export const allPages: Record<Page, PageConfig> = {
  Debug: pageConfig_Debug,
  Onboarding: pageConfig_Onboarding,
  Onboarding_Architect: pageConfig_Onboarding_Architect,
  Onboarding_Contractor: pageConfig_Onboarding_Contractor,
  Onboarding_Homeowner: pageConfig_Onboarding_Homeowner,
  Onboarding_Weaver: pageConfig_Onboarding_Weaver,
  Profile: pageConfig_Profile,
  Profile_Edit: pageConfig_Profile_Edit,
  Profile_Public: pageConfig_Profile_Public,
  Profile_ContractorLeadPreferences: pageConfig_Profile_ContractorLeadPreferences,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges: pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu: pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange: pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory: pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryReferences: pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryReferences,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryPhotos: pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryPhotos,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_AwardBadge: pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_AwardBadge,
  Profile_ContractorLeadPreferences_ContractorLeadLocations: pageConfig_Profile_ContractorLeadPreferences_ContractorLeadLocations,
  Projects: pageConfig_Projects,
  Projects_ContractorLeadAcceptor_StripeCallback: pageConfig_Projects_ContractorLeadAcceptor_StripeCallback,
  Projects_ContractorLeadAcceptor: pageConfig_Projects_ContractorLeadAcceptor,
  Projects_AcceptLead_PaymentPlanPicker_StripeCallback: pageConfig_Projects_AcceptLead_PaymentPlanPicker_StripeCallback,
  Projects_AcceptLead_PaymentPlanPicker: pageConfig_Projects_AcceptLead_PaymentPlanPicker,
  Projects_AcceptLead_SingleLeadPayment_StripeCallback: pageConfig_Projects_AcceptLead_SingleLeadPayment_StripeCallback,
  Projects_AcceptLead_SingleLeadPayment: pageConfig_Projects_AcceptLead_SingleLeadPayment,
  WorkHistoryForReference: pageConfig_WorkHistoryForReference,
  CreateProject: pageConfig_CreateProject,
  ShowProject: pageConfig_ShowProject,
  StartMatchingStripeCallback: pageConfig_StartMatchingStripeCallback,
  ProjectChat: pageConfig_ProjectChat,
  MyProjectTasks: pageConfig_MyProjectTasks,
  AllProjectTasks: pageConfig_AllProjectTasks,
  UploadProjectDocuments: pageConfig_UploadProjectDocuments,
  ProjectDocuments: pageConfig_Project_Documents,
  ShowLead: pageConfig_ShowLead,
  TaskActioner: pageConfig_TaskActioner,
  MyChats: pageConfig_MyChats,
  ChatRoom: pageConfig_ChatRoom,
  ChatRoomMembers: pageConfig_ChatRoomMembers,
  ChatRoomOptions: pageConfig_ChatRoomOptions,
  Help: pageConfig_Help,
  DeveloperSettings: pageConfig_DeveloperSettings,
  TechDemo: pageConfig_TechDemo,
  TechDemo_Chatter: pageConfig_TechDemo_Chatter,
  TechDemo_ForgetMeNote: pageConfig_TechDemo_ForgetMeNote,
  Success: pageConfig_Success,
  UploadedProjectDocumentSuccess: pageConfig_UploadedProjectDocumentSuccess,
  LaunchPWA: pageConfig_LauchPWA,
  NotImplemented: pageConfig_NotImplemented,
}

export const allPageRenders: Record<Page, PageRender> = {
  Debug: () => <DebugPage />,
  Onboarding: () => <TeamTypeSelector />,
  Onboarding_Architect: () => <OnboardingArchitectStageOne />,
  Onboarding_Contractor: () => <OnboardingContractorStageOne />,
  Onboarding_Homeowner: () => <OnboardingHomeownerStageOne />,
  Onboarding_Weaver: () => <OnboardingWeaver />,
  Profile: () => <DisplayMyProfile />,
  Profile_Edit: () => <EditMyProfile />,
  Profile_Public: () => <DisplayPublicProfile />,
  Profile_ContractorLeadPreferences: () => <ContractorLeadPreferencesPage />,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges: () => <ContractorBudgetRanges />,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange: () => <UnlockContractorBudgetRange />,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu: () => <UnlockContractorBudgetRangeMenuPage />,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory: () => <NewWorkHistory />,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryReferences: () => <WorkHistoryReferences />,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryPhotos: () => <WorkHistoryPhotos />,
  Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_AwardBadge: () => <ContractorProfileBudgetRangeAwardBadgePage />,
  Profile_ContractorLeadPreferences_ContractorLeadLocations: () => <ContractorLeadLocations />,
  Projects: () => <ProjectsIndex />,
  Projects_ContractorLeadAcceptor_StripeCallback: () => <DisplayContractorLeadAcceptorStripeCallbackPage />,
  Projects_ContractorLeadAcceptor: () => <DisplayContractorLeadAcceptorPage />,
  Projects_AcceptLead_PaymentPlanPicker_StripeCallback: () => <DisplayContractorLeadAcceptorStripeCallbackPage />,
  Projects_AcceptLead_PaymentPlanPicker: () => <DisplayPaymentPlanPickerPage />,
  Projects_AcceptLead_SingleLeadPayment_StripeCallback: () => <DisplayContractorLeadAcceptorStripeCallbackPage />,
  Projects_AcceptLead_SingleLeadPayment: () => <DisplaySingleLeadPaymentPage />,
  WorkHistoryForReference: () => <WorkHistoryForReferencePage/>,
  CreateProject: () => <CreateProject />,
  ShowProject: () => <ShowProject />,
  AllProjectTasks: () => <AllProjectTasks />,
  ProjectChat: () => <ProjectChat />,
  MyProjectTasks: () => <MyProjectTasksPage />,
  UploadProjectDocuments: () => <UploadDocuments />,
  ProjectDocuments: () => <ProjectDocuments />,
  ShowLead: () => <ShowLead />,
  TaskActioner: () => <TaskActionerPage />,
  StartMatchingStripeCallback: () => <StartMatchingStripeCallbackPage />,
  MyChats: () => <MyChats />,
  ChatRoom: () => <ChatRoom />,
  ChatRoomMembers: () => <ChatRoomMembers />,
  ChatRoomOptions: () => <ChatRoomOptionsPage />,
  Help: () => <Help />,
  DeveloperSettings: () => <DeveloperSettings />,
  TechDemo: () => <TechDemoTab />,
  TechDemo_Chatter: props => <ChatterTab {...props} />,
  TechDemo_ForgetMeNote: () => <ForgetMeNoteTab />,
  Success: () => <SuccessPage />,
  UploadedProjectDocumentSuccess: () => <UploadedProjectDocumentSuccess />,
  LaunchPWA: () => <PWAStartPage />,
  NotImplemented: () => <NotImplementedPage />,
}

/**
 * Takes a React Router parameterised path, populates the variables, adds an optional search string and returns the URL.
 *
 * @param path React Router parameterised path. e.g. `/users/:userId`
 * @param params Map of params matching the parameters in the path. e.g. `{ userId: string }` for `/user/:userId` or `{}` for `/users`
 * @param search An optional search string to add to the end of the URL
 * @returns The composed URL, which may be an empty string if the path is undefined
 */
export const populateUrlForPath = <Path extends string>(path: Path, params: ExtractRouteParams<Path>, search?: string, removeQuestionMark?: boolean) => {
  const populatedPath = generatePath<Path>(path, params)
  if (!removeQuestionMark) return `${populatedPath}${search ? `?${search}` : '?'}`
  /**
   * the reason we need the extra functionality in the following line is because in case of double rendering of the function an extra ? has been added to the end
   *  */
  return removeQuestionMarkFromEnd(`${populatedPath}${search ? `?${search}` : '?'}`)
}

/**
 *
 * @param url Router url e.g /leads/:id?referrer="acceptLead"?
 * @returns the input url without the extra ? at the end
 */

export const removeQuestionMarkFromEnd = (url: string) => {
  if (url.endsWith('?')) {
    return url.slice(0, -1)
  }
  return url
}

/**
 * Takes a React Router parameterised path a function parameterised which builds a handler, which when called will navigate to the path provided.
 *
 * *
 * e.g.
 * ```typescript
 * const goToProjects = useRouteTo(pageConfig_Projects.path)
 * return <IonButton onClick={goToProjects({})} />
 * ```
 * or
 * ```typescript
 * const goToContractorWorkHistory = useRouteTo(pageConfig_Profile_ContractorWorkHistory.path)
 * return <IonButton onClick={goToContractorWorkHistory({ contractorProfileId, workHistoryId })} />
 * ```
 *
 * @param path
 * @returns
 */
export const useRouteTo = <Path extends string>(path: Path, removeQuestionMark?: boolean) => {
  const ionRouter = useIonRouter()

  return useCallback((params: ExtractRouteParams<Path>, search?: string, routerDirection?: RouterDirection, routeAction?: RouteAction, routerOptions?: RouterOptions, animationBuilder?: AnimationBuilder) => () => {
    const url = populateUrlForPath(path, params, search, removeQuestionMark)
    if (!url) return

    const defaultedRouterOptions: RouterOptions = {
      unmount: true,
      ...(routerOptions ?? {}),
    }

    ionRouter.push(url, routerDirection, routeAction, defaultedRouterOptions, animationBuilder)
  }, [ ionRouter ])
}

export const useRouteToOnboardingForTeam = (teamType?: TeamType) => {
  const routeToOnboarding = useRouteTo(pageConfig_Onboarding.path)
  const routeToOnboardingArchitect = useRouteTo(pageConfig_Onboarding_Architect.path)
  const routeToOnboardingContractor = useRouteTo(pageConfig_Onboarding_Contractor.path)
  const routeToOnboardingHomeowner = useRouteTo(pageConfig_Onboarding_Homeowner.path)
  const routeToOnboardingWeaver = useRouteTo(pageConfig_Onboarding_Weaver.path)

  switch (teamType) {
    case TeamType.Architect: return routeToOnboardingArchitect({}, undefined, 'root', 'replace')
    case TeamType.Contractor: return routeToOnboardingContractor({}, undefined, 'root', 'replace')
    case TeamType.Homeowner: return routeToOnboardingHomeowner({}, undefined, 'root', 'replace')
    case TeamType.Weaver: return routeToOnboardingWeaver({}, undefined, 'root', 'replace')
    default: return routeToOnboarding({}, undefined, 'root', 'replace')
  }
}

const redirectRootPathTo = (page: Page): JSX.Element =>
  <Route key="root" path="/" exact>
    <Redirect to={getRoutePathForPage(page)} />
  </Route>

const redirectEverythingTo = (page: Page): JSX.Element =>
  <Route key="everything">
    <Redirect to={getRoutePathForPage(page)} />
  </Route>

export const getRoutePathForPage = (page: Page): string | undefined => allPages[page].path

export const getKnownRoutePathForPage = (page: Page): string => {
  const path = getRoutePathForPage(page)
  if (path === undefined) {
    throw new Error(`[Routes] Config Error: Unable to find a path for ${page}`)
  }
  return path
}

export const getKnownRoutePathForPages = (...pages: Page[]): string[] => pages.map(getKnownRoutePathForPage)

type RoutingWithTabs = {
  pages: (Page | JSX.Element)[],
  tabs: Tab[],
}

const defaultRoutingWithTabs: RoutingWithTabs = {
  pages: [
    Page.Debug,
    Page.Profile,
    Page.Profile_Edit,
    Page.Profile_Public,
    Page.Profile_ContractorLeadPreferences,
    Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges,
    Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange,
    Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu,
    Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_NewWorkHistory,
    Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryReferences,
    Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_WorkHistoryPhotos,
    Page.Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_AwardBadge,
    Page.Profile_ContractorLeadPreferences_ContractorLeadLocations,
    Page.Projects,
    Page.Projects_ContractorLeadAcceptor_StripeCallback,
    Page.Projects_ContractorLeadAcceptor,
    Page.Projects_AcceptLead_PaymentPlanPicker_StripeCallback,
    Page.Projects_AcceptLead_PaymentPlanPicker,
    Page.Projects_AcceptLead_SingleLeadPayment_StripeCallback,
    Page.Projects_AcceptLead_SingleLeadPayment,
    Page.WorkHistoryForReference,
    Page.ShowProject,
    Page.ShowLead,
    Page.TaskActioner,
    Page.StartMatchingStripeCallback,
    Page.ProjectChat,
    Page.MyChats,
    Page.ChatRoom,
    Page.ChatRoomOptions,
    Page.ChatRoomMembers,
    Page.Help,
    Page.DeveloperSettings,
    Page.TechDemo,
    Page.TechDemo_Chatter,
    Page.TechDemo_ForgetMeNote,
    Page.Success,
    Page.LaunchPWA,
    redirectRootPathTo(Page.Projects),
    Page.NotImplemented,
    Page.UploadProjectDocuments,
    Page.MyProjectTasks,
    Page.UploadedProjectDocumentSuccess,
    Page.AllProjectTasks,
    Page.ProjectDocuments,
  ],
  tabs: [
    {
      name: Page.Projects,
      title: "Projects",
      icon: homeOutline,
      href: getRoutePathForPage(Page.Projects),
    },
    {
      name: Page.MyChats,
      title: "Chats",
      icon: chatboxOutline,
      href: getRoutePathForPage(Page.MyChats),
      useShouldShowIndicator: () => {
        const myIndividual = useMyIndividual()

        return !!myIndividual._hasUnreadChatRoomMessages
      },
    },
    {
      name: Page.Profile,
      title: "Profile",
      icon: peopleOutline,
      href: getRoutePathForPage(Page.Profile),
    },
    {
      name: Page.Help,
      title: "Help",
      icon: helpBuoy,
      href: getRoutePathForPage(Page.Help),
    },
  ],
}

export const teamRoutingWithTabs: Record<TeamType | 'no-team', RoutingWithTabs> = {
  "no-team": {
    pages: [
      Page.Debug,
      Page.Onboarding,
      Page.Onboarding_Architect,
      Page.Onboarding_Contractor,
      Page.Onboarding_Homeowner,
      Page.Onboarding_Weaver,
      Page.WorkHistoryForReference,
      redirectEverythingTo(Page.Onboarding),
    ],
    tabs: [
    ],
  },
  ARCHITECT: {
    ...defaultRoutingWithTabs,
    pages: [
      ...defaultRoutingWithTabs.pages,
      Page.CreateProject,
    ],
  },
  CONTRACTOR: {
    ...defaultRoutingWithTabs,
    pages: [
      ...defaultRoutingWithTabs.pages,
      redirectEverythingTo(Page.Profile),
    ],
  },
  HOMEOWNER: {
    ...defaultRoutingWithTabs,
    pages: [
      ...defaultRoutingWithTabs.pages,
      Page.CreateProject,
      redirectEverythingTo(Page.Profile),
    ],
  },
  WEAVER: {
    ...defaultRoutingWithTabs,
    pages: [
      ...defaultRoutingWithTabs.pages,
      Page.CreateProject,
      redirectEverythingTo(Page.Profile),
    ],
  },
  // HACK: Partners are not supported yet
  PARTNER: {
    tabs: [],
    pages: [

    ],
  },
}

const getRoutingWithTabsKey = (myTeam?: MyIndividualActiveTeam): TeamType | 'no-team' =>
  myTeam === undefined || myTeam.isOnboardingComplete !== true
    ? 'no-team'
    : myTeam.type

export const useRouterRoutes = (myTeam?: MyIndividualActiveTeam) => {
  useInvisiblePageRouterHack()

  return teamRoutingWithTabs[getRoutingWithTabsKey(myTeam)].pages
    .map(pageOrElement => {
      const isPage = typeof pageOrElement === 'string'
      if (isPage) {
        const pageConfig = {
          ...defaultPageConfig,
          ...allPages[pageOrElement],
        }
        const pageRender = allPageRenders[pageOrElement]
        const pageRenderWithPageConfig = withPageConfig(pageConfig, pageRender)
        const pageRenderWithPageConfigAndWeaverStructureChecker = withWeaverStructureChecker('routes', pageRenderWithPageConfig)
        const render = pageRenderWithPageConfigAndWeaverStructureChecker

        return <Route key={pageConfig.page} path={pageConfig.path} exact={pageConfig.exact ?? true} render={render} />
      } else {
        return pageOrElement
      }
    })
}

export const useRouterTabNavBar = (myTeam?: MyIndividualActiveTeam) => <TabNavBar tabs={teamRoutingWithTabs[getRoutingWithTabsKey(myTeam)].tabs}/>

export const useRouterTabButtons = (myTeam?: MyIndividualActiveTeam) =>
  teamRoutingWithTabs[getRoutingWithTabsKey(myTeam)].tabs
    .map(tab => (
      <IonTabButton key={tab.name} tab={tab.name} href={tab.href}>
        <IonIcon icon={tab.icon} />
        <IonLabel>{tab.title}</IonLabel>
      </IonTabButton>
    ))

export const useNavigateToPage = () => {
  const ionRouter = useIonRouter()
  return (page: Page) => {
    const path = getRoutePathForPage(page)

    if (!path) {
      console.error(`[useNavigateToPage] Page '${page}' has no path!`)
      return
    }

    ionRouter.push(path)
  }
}
